import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c3227b7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "wrapper-div",
  id: "resultsEsg-wrapper-div"
}
const _hoisted_2 = {
  id: "section-one-container",
  class: "section-one-container"
}
const _hoisted_3 = { id: "section-one-main-header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "main-description-text" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  id: "main-description-text",
  class: "main-description-text"
}
const _hoisted_8 = {
  key: 1,
  id: "esg-results-table1-emission",
  class: "table results-table w-percent-100 mb-3",
  "aria-describedby": "esg-result-table1-emission-header1"
}
const _hoisted_9 = { class: "bg-primary text-color-white" }
const _hoisted_10 = {
  id: "esg-result-table1-emission-header1",
  colspan: "3",
  scope: "colgroup"
}
const _hoisted_11 = {
  style: {"font-weight":"600"},
  class: "w-percent-100"
}
const _hoisted_12 = {
  headers: "esg-result-table1-emission-header1",
  style: {"width":"55%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-one"
}
const _hoisted_13 = {
  headers: "esg-result-table1-emission-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-two"
}
const _hoisted_14 = {
  headers: "esg-result-table1-emission-header1",
  style: {"width":"30%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-three"
}
const _hoisted_15 = { id: "esg-result-scope-one" }
const _hoisted_16 = { class: "align-text-left" }
const _hoisted_17 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_18 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_19 = { id: "esg-result-scope-two" }
const _hoisted_20 = { class: "align-text-left" }
const _hoisted_21 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_22 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_23 = { id: "esg-result-scope-three" }
const _hoisted_24 = { class: "align-text-left" }
const _hoisted_25 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_26 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_27 = { id: "esg-result-scopes-total" }
const _hoisted_28 = { class: "align-text-left" }
const _hoisted_29 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_30 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_31 = { id: "esg-result-co2intensity" }
const _hoisted_32 = { class: "align-text-left" }
const _hoisted_33 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_34 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_35 = { key: 2 }
const _hoisted_36 = {
  key: 3,
  id: "esg-results-table2-energy",
  class: "table results-table w-percent-100 mb-3",
  "aria-describedby": "esg-result-table2-energy-header1"
}
const _hoisted_37 = { class: "bg-primary text-color-white" }
const _hoisted_38 = {
  id: "esg-result-table2-energy-header1",
  colspan: "4",
  scope: "colgroup"
}
const _hoisted_39 = {
  style: {"font-weight":"600"},
  class: "w-percent-100"
}
const _hoisted_40 = {
  headers: "esg-result-table2-energy-header1",
  style: {"width":"55%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-one"
}
const _hoisted_41 = {
  headers: "esg-result-table2-energy-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-two"
}
const _hoisted_42 = {
  headers: "esg-result-table2-energy-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-three"
}
const _hoisted_43 = {
  headers: "esg-result-table2-energy-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-four"
}
const _hoisted_44 = { id: "esg-result-fossil-energy" }
const _hoisted_45 = { class: "align-text-left pl-6" }
const _hoisted_46 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_47 = { key: 0 }
const _hoisted_48 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_49 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_50 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_51 = {
  key: 2,
  class: "italic-text-right"
}
const _hoisted_52 = {
  key: 3,
  class: "align-text-right"
}
const _hoisted_53 = { key: 0 }
const _hoisted_54 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_55 = {
  key: 4,
  class: "italic-text"
}
const _hoisted_56 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_57 = {
  key: 5,
  class: "italic-text-right"
}
const _hoisted_58 = { id: "esg-result-nuclear-energy" }
const _hoisted_59 = { class: "align-text-left pl-6" }
const _hoisted_60 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_61 = { key: 0 }
const _hoisted_62 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_63 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_64 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_65 = {
  key: 2,
  class: "italic-text-right"
}
const _hoisted_66 = {
  key: 3,
  class: "align-text-right"
}
const _hoisted_67 = { key: 0 }
const _hoisted_68 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_69 = {
  key: 4,
  class: "italic-text"
}
const _hoisted_70 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_71 = {
  key: 5,
  class: "italic-text-right"
}
const _hoisted_72 = { id: "esg-result-renewable-energy" }
const _hoisted_73 = { class: "align-text-left pl-6" }
const _hoisted_74 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_75 = { key: 0 }
const _hoisted_76 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_77 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_78 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_79 = {
  key: 2,
  class: "italic-text-right"
}
const _hoisted_80 = {
  key: 3,
  class: "align-text-right"
}
const _hoisted_81 = { key: 0 }
const _hoisted_82 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_83 = {
  key: 4,
  class: "italic-text"
}
const _hoisted_84 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_85 = {
  key: 5,
  class: "italic-text-right"
}
const _hoisted_86 = { id: "esg-result-energytotal" }
const _hoisted_87 = { class: "align-text-left" }
const _hoisted_88 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_89 = { key: 0 }
const _hoisted_90 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_91 = {
  key: 1,
  class: "italic-text"
}
const _hoisted_92 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_93 = {
  key: 2,
  class: "italic-text-right"
}
const _hoisted_94 = {
  key: 3,
  class: "align-text-right"
}
const _hoisted_95 = {
  key: 4,
  class: "italic-text"
}
const _hoisted_96 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_97 = {
  key: 5,
  class: "italic-text-right"
}
const _hoisted_98 = {
  key: 4,
  id: "esg-results-table3-water-and-waste",
  class: "table results-table w-percent-100 mb-3",
  "aria-describedby": "esg-result-table3-water-and-waste-header1"
}
const _hoisted_99 = { class: "bg-primary text-color-white" }
const _hoisted_100 = {
  id: "esg-result-table3-water-and-waste-header1",
  colspan: "3",
  scope: "colgroup"
}
const _hoisted_101 = {
  style: {"font-weight":"600"},
  class: "w-percent-100"
}
const _hoisted_102 = {
  headers: "esg-result-table3-water-and-waste-header1",
  style: {"width":"55%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-one"
}
const _hoisted_103 = {
  headers: "esg-result-table3-water-and-waste-header1",
  style: {"width":"15%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-two"
}
const _hoisted_104 = {
  headers: "esg-result-table3-water-and-waste-header1",
  style: {"width":"30%"},
  class: "py-405 align-text-left bg-alternative color-text-black",
  id: "results.esg.result-table-subheader-three"
}
const _hoisted_105 = { id: "esg-result-waterconsumption" }
const _hoisted_106 = { class: "align-text-left" }
const _hoisted_107 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_108 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_109 = { id: "esg-result-wastetotal" }
const _hoisted_110 = { class: "align-text-left" }
const _hoisted_111 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_112 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_113 = { id: "esg-result-wastehazardous" }
const _hoisted_114 = { class: "align-text-left pl-6" }
const _hoisted_115 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_116 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_117 = { id: "esg-result-wasterecycled" }
const _hoisted_118 = { class: "align-text-left pl-6" }
const _hoisted_119 = {
  key: 0,
  class: "align-text-right"
}
const _hoisted_120 = {
  key: 1,
  class: "italic-text-right"
}
const _hoisted_121 = { key: 5 }
const _hoisted_122 = { key: 6 }
const _hoisted_123 = {
  key: 7,
  id: "esg-results-table4-electricity",
  class: "table results-table w-percent-100 mb-3",
  "aria-describedby": "results.esg.result-table4-header-one"
}
const _hoisted_124 = {
  style: {"font-weight":"600"},
  class: "w-percent-100"
}
const _hoisted_125 = {
  style: {"width":"40%"},
  class: "py-405 align-text-left",
  id: "results.esg.result-table4-header-one"
}
const _hoisted_126 = {
  style: {"width":"15%"},
  class: "py-405 align-text-left",
  id: "results.esg.result-table4-header-two"
}
const _hoisted_127 = {
  style: {"width":"15%"},
  class: "py-405 align-text-left",
  id: "results.esg.result-table4-header-three"
}
const _hoisted_128 = {
  style: {"width":"15%"},
  class: "py-405 align-text-left",
  id: "results.esg.result-table4-header-four"
}
const _hoisted_129 = {
  style: {"width":"15%"},
  class: "py-405 align-text-left",
  id: "results.esg.result-table4-header-five"
}
const _hoisted_130 = { id: "esg-result-table4-row-electricity" }
const _hoisted_131 = { class: "align-text-left" }
const _hoisted_132 = { key: 0 }
const _hoisted_133 = { key: 1 }
const _hoisted_134 = { key: 2 }
const _hoisted_135 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_136 = { key: 0 }
const _hoisted_137 = { key: 1 }
const _hoisted_138 = { key: 2 }
const _hoisted_139 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_140 = { key: 0 }
const _hoisted_141 = { key: 1 }
const _hoisted_142 = { key: 2 }
const _hoisted_143 = {
  href: "/klimakompasset/overview",
  style: {"text-decoration":"none"}
}
const _hoisted_144 = { class: "align-text-left" }
const _hoisted_145 = { key: 0 }
const _hoisted_146 = { key: 0 }
const _hoisted_147 = { key: 0 }
const _hoisted_148 = { class: "align-text-left" }
const _hoisted_149 = { key: 0 }
const _hoisted_150 = { key: 0 }
const _hoisted_151 = { key: 0 }
const _hoisted_152 = { class: "align-text-left" }
const _hoisted_153 = { key: 0 }
const _hoisted_154 = { key: 0 }
const _hoisted_155 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_RichText = _resolveComponent("RichText")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, [
        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-main-header" })
      ]),
      (_ctx.results?.subgroupWarnings && _ctx.results?.subgroupWarnings?.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, [
              _createVNode(_component_SimpleText, { "text-key": "warning.illegal.units" })
            ]),
            _createElementVNode("ul", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results?.subgroupWarnings, (sg) => {
                return (_openBlock(), _createElementBlock("li", { key: sg }, [
                  _createElementVNode("button", {
                    class: "link-button",
                    onClick: ($event: any) => (_ctx.gotoUrl(sg))
                  }, _toDisplayString(_ctx.hrefText(sg)), 9, _hoisted_6)
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_7, [
        _createVNode(_component_RichText, { "text-key": "results.esg.result-main-description-text" })
      ]),
      (_ctx.results?.groupingResults)
        ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_9, [
                _createElementVNode("th", _hoisted_10, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table1-emission-header-one" })
                ])
              ])
            ]),
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_11, [
                _createElementVNode("th", _hoisted_12, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-one" })
                ]),
                _createElementVNode("th", _hoisted_13, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
                ]),
                _createElementVNode("th", _hoisted_14, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-three" })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_15, [
                _createElementVNode("td", _hoisted_16, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-one" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope1Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_17, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope1Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_18, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_19, [
                _createElementVNode("td", _hoisted_20, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-two" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope2Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_21, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope2Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_22, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_23, [
                _createElementVNode("td", _hoisted_24, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scope-three" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.esgResult?.scope3Total)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_25, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.scope3Total)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_26, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_27, [
                _createElementVNode("td", _hoisted_28, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-scopes-total" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-one" })
                ]),
                (_ctx.results.ghgCalculatedResult?.totalResult)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_29, _toDisplayString(_ctx.doPrettify(_ctx.results.ghgCalculatedResult.totalResult)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_30, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_31, [
                _createElementVNode("td", _hoisted_32, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-co2intensity" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-two" })
                ]),
                (_ctx.results.esgResult?.co2Intensity)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_33, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.co2Intensity)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_34, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_RichText, { "text-key": "results.esg.pre-table2-energy-description" }),
      (_ctx.results.esgResult?.isMissingESGDataEnergy)
        ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
            _createVNode(_component_RichText, { "text-key": "results.esg.pre-table2-esg-missing-share-pct-warning" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.results?.groupingResults)
        ? (_openBlock(), _createElementBlock("table", _hoisted_36, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_37, [
                _createElementVNode("th", _hoisted_38, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table2-energy-header-one" })
                ])
              ])
            ]),
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_39, [
                _createElementVNode("th", _hoisted_40, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-one" })
                ]),
                _createElementVNode("th", _hoisted_41, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
                ]),
                _createElementVNode("th", _hoisted_42, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-three" })
                ]),
                _createElementVNode("th", _hoisted_43, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-four" })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_44, [
                _createElementVNode("td", _hoisted_45, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-fossil-energy" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ]),
                (_ctx.results.esgResult?.energyConsumptionFossilSourcesResult)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_46, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionFossilSourcesResult)) + " ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_48, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_49, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_50, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_51, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                (_ctx.results.esgResult?.energyConsumptionFossilSourcesPct)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_52, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionFossilSourcesPct)) + "% ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_54, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_55, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_56, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_57, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ]))
              ]),
              _createElementVNode("tr", _hoisted_58, [
                _createElementVNode("td", _hoisted_59, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-nuclear-energy" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ]),
                (_ctx.results.esgResult?.energyConsumptionNuclearSourcesResult)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_60, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionNuclearSourcesResult)) + " ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_62, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_63, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_64, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_65, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                (_ctx.results.esgResult?.energyConsumptionNuclearSourcesPct)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_66, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionNuclearSourcesPct)) + "% ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_67, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_68, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_69, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_70, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_71, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ]))
              ]),
              _createElementVNode("tr", _hoisted_72, [
                _createElementVNode("td", _hoisted_73, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-renewable-energy" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ]),
                (_ctx.results.esgResult?.energyConsumptionRenewableSourcesResult)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_74, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionRenewableSourcesResult)) + " ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_76, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_77, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_78, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_79, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                (_ctx.results.esgResult?.energyConsumptionRenewableSourcesPct)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_80, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyConsumptionRenewableSourcesPct)) + "% ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_82, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_83, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_84, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_85, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ]))
              ]),
              _createElementVNode("tr", _hoisted_86, [
                _createElementVNode("td", _hoisted_87, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-energytotal" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ]),
                (_ctx.results.esgResult?.energyTotal)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_88, [
                      _createTextVNode(_toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.energyTotal)) + " ", 1),
                      (_ctx.results.esgResult.mustUpdateDueToESGEnergy)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_89, [
                            _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.number-may-not-be-correct" }),
                            _createElementVNode("a", _hoisted_90, [
                              _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_91, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_92, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_93, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                (_ctx.results.esgResult?.energyTotal)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_94, _toDisplayString(_ctx.doPrettify(100)) + "%", 1))
                  : (_ctx.results.esgResult?.mustUpdateDueToESGEnergy)
                    ? (_openBlock(), _createElementBlock("td", _hoisted_95, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.must-update" }),
                        _createElementVNode("a", _hoisted_96, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("td", _hoisted_97, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_RichText, { "text-key": "results.esg.pre-table3-water-and-waste-description" }),
      (_ctx.results?.groupingResults)
        ? (_openBlock(), _createElementBlock("table", _hoisted_98, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_99, [
                _createElementVNode("th", _hoisted_100, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table3-water-and-waste-header-one" })
                ])
              ])
            ]),
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_101, [
                _createElementVNode("th", _hoisted_102, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-one" })
                ]),
                _createElementVNode("th", _hoisted_103, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
                ]),
                _createElementVNode("th", _hoisted_104, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-three" })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_105, [
                _createElementVNode("td", _hoisted_106, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-waterconsumption" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.fuels.m3" })
                ]),
                (_ctx.results.esgResult?.waterConsumption)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_107, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.waterConsumption)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_108, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_109, [
                _createElementVNode("td", _hoisted_110, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-wastetotal" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-eight" })
                ]),
                (_ctx.results.esgResult?.wasteTotal)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_111, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteTotal)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_112, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_113, [
                _createElementVNode("td", _hoisted_114, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-wastehazardous" }),
                  _createVNode(_component_Tooltip, {
                    "aria-label": "result-result-waste-hazardous-percentage-tooltip",
                    textKey: "results.esg.result-waste-hazardous-percentage-tooltip",
                    style: {"align-content":"flex-end"},
                    size: "md-gr"
                  })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-five" })
                ]),
                (_ctx.results.esgResult?.wasteHazardousPercentage)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_115, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteHazardousPercentage)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_116, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ]),
              _createElementVNode("tr", _hoisted_117, [
                _createElementVNode("td", _hoisted_118, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-row-wasterecycled" })
                ]),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-measurement-unit-five" })
                ]),
                (_ctx.results.esgResult?.wasteRecycledPercentage)
                  ? (_openBlock(), _createElementBlock("td", _hoisted_119, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.wasteRecycledPercentage)), 1))
                  : (_openBlock(), _createElementBlock("td", _hoisted_120, [
                      _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                    ]))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_RichText, { "text-key": "results.esg.pre-table4-electricity-description" }),
      (_ctx.results.esgResult?.isMissingESGDataElectricity)
        ? (_openBlock(), _createElementBlock("div", _hoisted_121, [
            _createVNode(_component_RichText, { "text-key": "results.esg.pre-table4-esg-missing-share-pct-warning" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.results.esgResult?.isIllegalESGUnitElectricity)
        ? (_openBlock(), _createElementBlock("div", _hoisted_122, [
            _createVNode(_component_RichText, { "text-key": "results.esg.pre-table4-esg-illegal-unit-warning" })
          ]))
        : _createCommentVNode("", true),
      (_ctx.results?.groupingResults)
        ? (_openBlock(), _createElementBlock("table", _hoisted_123, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_124, [
                _createElementVNode("th", _hoisted_125, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table4-header-one" })
                ]),
                _createElementVNode("th", _hoisted_126, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table4-header-two" })
                ]),
                _createElementVNode("th", _hoisted_127, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table4-header-three" })
                ]),
                _createElementVNode("th", _hoisted_128, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table4-header-four" })
                ]),
                _createElementVNode("th", _hoisted_129, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-subheader-two" })
                ])
              ])
            ]),
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_130, [
                _createElementVNode("td", _hoisted_131, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-header-electricity" })
                ]),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.electricityRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.electricityRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_132, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.electricityRenewableTotal)), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_133, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                  (_ctx.results.esgResult?.mustUpdateDueToESGElectricity)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_134, [
                        _createVNode(_component_SimpleText, {
                          "text-key": _ctx.results.esgResult?.electricityRenewableTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'
                        }, null, 8, ["text-key"]),
                        _createElementVNode("a", _hoisted_135, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.electricityNonRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.electricityNonRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_136, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.electricityNonRenewableTotal)), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_137, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                  (_ctx.results.esgResult?.mustUpdateDueToESGElectricity)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_138, [
                        _createVNode(_component_SimpleText, {
                          "text-key": _ctx.results.esgResult?.electricityNonRenewableTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'
                        }, null, 8, ["text-key"]),
                        _createElementVNode("a", _hoisted_139, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.electricityTotal}])
                }, [
                  (_ctx.results.esgResult?.electricityTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_140, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.electricityTotal)), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_141, [
                        _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-no-assigned-value" })
                      ])),
                  (_ctx.results.esgResult?.mustUpdateDueToESGElectricity)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_142, [
                        _createVNode(_component_SimpleText, {
                          "text-key": _ctx.results.esgResult?.electricityTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'
                        }, null, 8, ["text-key"]),
                        _createElementVNode("a", _hoisted_143, [
                          _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table.update-link" })
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_144, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-header-fuel" })
                ]),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.fuelRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.fuelRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_145, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.fuelRenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.fuelNonRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.fuelNonRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_146, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.fuelNonRenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.fuelTotal}])
                }, [
                  (_ctx.results.esgResult?.fuelTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_147, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.fuelTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_148, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table-header-district-heating" })
                ]),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.districtHeatingRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.districtHeatingRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_149, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.districtHeatingRenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.districtHeatingNonRenewableTotal}])
                }, [
                  (_ctx.results.esgResult?.districtHeatingNonRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_150, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.districtHeatingNonRenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.results.esgResult?.districtHeatingTotal}])
                }, [
                  (_ctx.results.esgResult?.districtHeatingTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_151, _toDisplayString(_ctx.doPrettify(_ctx.results.esgResult.districtHeatingTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ])
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_152, [
                  _createVNode(_component_SimpleText, { "text-key": "results.esg.result-table4-header-four" })
                ]),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.table4RenewableTotal}])
                }, [
                  (_ctx.table4RenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_153, _toDisplayString(_ctx.doPrettify(_ctx.table4RenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.table4NonRenewableTotal}])
                }, [
                  (_ctx.table4NonRenewableTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_154, _toDisplayString(_ctx.doPrettify(_ctx.table4NonRenewableTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", {
                  class: _normalizeClass(["align-text-right", { 'italic-text': !_ctx.table4TotalTotal}])
                }, [
                  (_ctx.table4TotalTotal)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_155, _toDisplayString(_ctx.doPrettify(_ctx.table4TotalTotal)), 1))
                    : (_openBlock(), _createBlock(_component_SimpleText, {
                        key: 1,
                        "text-key": "results.esg.result-table-no-assigned-value"
                      }))
                ], 2),
                _createElementVNode("td", null, [
                  _createVNode(_component_SimpleText, { "text-key": "calculation.dropdown.possiblevalue.unit.mwh" })
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}