<template>
  <h4><SimpleText text-key="forecast.resultview.chartheader" /></h4>
  <div class="container px-0" v-if="forecast.calculations.length > 0">
    <table class="table results-table" aria-label="forecast-result-table-view">
      <thead>
      <tr class="bg-primary text-color-white py-9">
        <th id="empty-cosmetic-header"></th>
        <th id="tonnage-co2e-table-header" scope="colgroup" colspan="2" style="text-align: center">
          <SimpleText text-key="forecast.resultview.table.section1"></SimpleText>
        </th>
        <th id="percentage-distribution-table-header" scope="colgroup" colspan="2" style="text-align: center">
          <SimpleText text-key="forecast.resultview.table.section2"></SimpleText>
        </th>
      </tr>
      <tr class="bg-primary text-color-white py-9">
        <th id="scopes-category-title-header"><SimpleText text-key="forecast.resultview.table.header1"></SimpleText></th>
        <th id="tonnage-co2e-first-year-header" class="align-text-right">{{ firstYear }}</th>
        <th id="tonnage-co2e-last-year-header" class="align-text-right">{{ lastYear }}</th>
        <th id="percentage-distribution-first-year-header" class="align-text-right">{{ firstYear }}</th>
        <th id="percentage-distribution-last-year-header" class="align-text-right">{{ lastYear }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(scopeValue) in getScopeValues" :key="scopeValue.name" :class="{'bold': scopeValue.name.includes(getContentfulString('forecast.resultview.table.total'))}">
        <td headers="scopes-category-title-header">{{ scopeValue.name }}</td>
        <td headers="tonnage-co2e-table-header tonnage-co2e-first-year-header" class="align-text-right">
          {{ doPrettify(scopeValue.valueStart, 2) }}
        </td>
        <td headers="tonnage-co2e-table-header tonnage-co2e-last-year-header" class="align-text-right">
          {{ doForecastPrettify(scopeValue.valueEnd, 2) }}
        </td>
        <td headers="percentage-distribution-table-header percentage-distribution-first-year-header" class="align-text-right">
          <span v-if="scopeValue.valuePercentStart">{{ doPrettify(scopeValue.valuePercentStart, 2) }}%</span>
        </td>
        <td headers="percentage-distribution-table-header percentage-distribution-last-year-header" class="align-text-right">
          <span v-if="scopeValue.valuePercentEnd">{{ doPrettify(scopeValue.valuePercentEnd, 2) }}%</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div style="display: flex; justify-content: center; height: 350px;" v-else>
    <div style="align-self: center;">
      <div aria-label="Indlæser" class="spinner"/>
      <div class="spinner-status" role="status">
        <SimpleText :text-key="'forecast.result.scope123.pendingload.table'"></SimpleText>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {Prop} from "vue-property-decorator";
import {ForecastResults} from "../../../openapi/generated-clients/climatecompass";
import RichText from "@/components/RichText.vue";
import SimpleText from "@/components/SimpleText.vue";
import Tooltip from "@/components/Tooltip.vue";
import Navigation from "@/components/thirdparty/Navigation.vue";
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue";
import Multiselect from "@vueform/multiselect";
import ForecastColumnChart from "@/components/forecast/ForecastColumnChart.vue";
import {Options} from "vue-class-component";

@Options({
  components: {
    SimpleText
  }
})
export default class ForecastTableView extends BaseComponent{
  @Prop()
  forecast?: ForecastResults

  scopeValues: {name: string, valueStart: number, valueEnd: number, valuePercentStart?: number, valuePercentEnd?: number}[] = []

  get firstYear() {
    return this.forecast?.calculations ? this.forecast?.calculations[0].year : undefined
  }
  get lastYear() {
    return this.forecast?.calculations ? this.forecast.calculations[this.forecast.calculations.length - 1].year : undefined
  }

  get getScopeValues() {

    if(!this.forecast?.calculations) return []
    const totalScope1Start = this.forecast?.calculations[0].ghgCalculatedResult?.scope1Result ?? 0
    const totalScope2Start = this.forecast?.calculations[0].ghgCalculatedResult?.scope2Result ?? 0
    const totalScope3Start = this.forecast?.calculations[0].ghgCalculatedResult?.scope3Result ?? 0
    const totalOutsideScopeStart = this.forecast?.calculations[0].ghgCalculatedResult?.outsideScopeResult ?? 0
    const totalScope1End = this.forecast?.calculations[this.forecast.calculations.length-1].ghgCalculatedResult?.scope1Result ?? 0
    const totalScope2End = this.forecast?.calculations[this.forecast.calculations.length-1].ghgCalculatedResult?.scope2Result ?? 0
    const totalScope3End = this.forecast?.calculations[this.forecast.calculations.length-1].ghgCalculatedResult?.scope3Result ?? 0
    const totalOutsideScopeEnd = this.forecast?.calculations[this.forecast.calculations.length-1].ghgCalculatedResult?.outsideScopeResult ?? 0
    const totalEmissionStart = this.forecast.calculations[0].scoresTotalResult ?? 0
    const totalEmissionEnd = this.forecast.calculations[this.forecast.calculations.length-1].scoresTotalResult ?? 0

    const startScope1Percentage = totalScope1Start*100/totalEmissionStart
    const startScope2Percentage = totalScope2Start*100/totalEmissionStart
    const startScope3Percentage = totalScope3Start*100/totalEmissionStart

    const endScope1Percentage = totalScope1End*100/totalEmissionEnd
    const endScope2Percentage = totalScope2End*100/totalEmissionEnd
    const endScope3Percentage = totalScope3End*100/totalEmissionEnd


    if(this.forecast) {
      this.scopeValues = []
      this.scopeValues.push({
        name: this.getContentfulString('forecast.resultview.scope1.text'),
        valueStart: totalScope1Start,
        valueEnd: totalScope1End,
        valuePercentStart: startScope1Percentage,
        valuePercentEnd: endScope1Percentage

      })
      this.scopeValues.push({
        name: this.getContentfulString('forecast.resultview.scope2.text'),
        valueStart: totalScope2Start,
        valueEnd: totalScope2End,
        valuePercentStart: startScope2Percentage,
        valuePercentEnd: endScope2Percentage

      })
      this.scopeValues.push({
        name: this.getContentfulString('forecast.resultview.scope3.text'),
        valueStart: totalScope3Start,
        valueEnd: totalScope3End,
        valuePercentStart: startScope3Percentage,
        valuePercentEnd: endScope3Percentage

      })
      this.scopeValues.push({
        name: this.getContentfulString('forecast.resultview.table.total'),
        valueStart: totalEmissionStart ?? 0,
        valueEnd: totalEmissionEnd ?? 0,
        valuePercentStart: 100,
        valuePercentEnd: totalEmissionEnd <= 0 ? 0 : 100
      })
      this.scopeValues.push({
        name: this.getContentfulString('forecast.resultview.table.outsidescopes'),
        valueStart: totalOutsideScopeStart,
        valueEnd: totalOutsideScopeEnd,
        valuePercentStart: undefined,
        valuePercentEnd: undefined

      })

      return this.scopeValues
    }
    return []
  }


}
</script>

<style scoped lang="scss">

.table.results-table tr td:first-child {
  text-align: left;
}
.table.results-table tr.bold td {
  font-weight:bold;
}

@media print {
  table.table.results-table thead tr th, table.table.results-table thead tr th:not(th:last-of-type),  table.table.results-table thead tr{
    border-left: 1px solid $color-primary !important;
    border-right: 1px solid $color-primary !important;
    border-top: 1px solid $color-primary !important;
    border-bottom: 1px solid $color-primary !important;
  }

}

</style>
