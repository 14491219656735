<template>
  <div class='container px-0 py-9' v-if="pageAccess">
    <h1>Misc - tools for developers</h1>
    <form @submit.prevent="getDummy">
      <fieldset>
        <legend>Værktøjer for udviklere</legend>
        <div class='row'>
          <div class='col-md-2 form-button'>
            <!--button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="recalculateOwn()">Genberegn egne kladde beregninger (kan tage tid)
            </button-->
            <button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    @click="exportEMF()">Exporter EMF stamdata
            </button>
            <button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    @click="exportEMCToEMFMapping()">Exporter EMC stamdata
            </button>
            <!--button id='submit-button-forecast' type='submit' class='button button-tertiary'
                    role='button' >Exporter alle beregninger til Excel</button-->
            <!--button id='submit-button-forecast' type='submit' class='button button-secondary adjust-button-size'
                    role='button' @click="aiString()">AI
            </button-->
          </div>
        </div>
        <div v-if="recalculateErrors.length > 0">
          <p></p>
          {{ recalculateErrors }}
        </div>
        <!--div>
          <label for="form-input-2">Tabel:</label>
          <input type="text" id="form-input-2" class="form-control" v-model="tableName" placeholder="notification">&nbsp;
          <button class="button button-secondary adjust-button-size" @click="loadTabledata">Hent tabeldata</button>
        </div>

        <div id="rowdata" class="py-9">
          <div class="table--responsive-scroll" style="max-height: 550px; min-width: 680px; overflow: auto;">
            <table class="table" id="row-data-table">
              <caption>Tabel data</caption>
              <thead>
              <tr>
                <th scope="col">Row data #{{ rows.length }}</th>
              </tr>
              </thead>
              <tbody v-if="rows">
              <tr v-for="(item, index) in rows" :key="`item_${item}_${index}`">
                <td>{{ item.value }}</td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr aria-colspan="5">
                <td colspan="5">Ingen rækker at vise</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div-->
      </fieldset>
    </form>
  </div>

</template>

<script lang='ts'>
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import * as DKFDS from 'dkfds'
import {
  calculationClient,
  emissionsfactorImportClient,
  statisticsClient,
  aiClient,
  configurationClient, forecastClient
} from '@/shared/BackendService'
import {
  EmissionCoefficientOverview,
  FileMessage, ImportValidationError, PendingImportFile,
  TableRow,
  ValidationError
} from "../../../openapi/generated-clients/climatecompass";
import {downloadFile} from "@/shared/FileHelper";

export default class Misc extends BaseComponent {
  recalculateErrors = ''
  pageAccess = false
  validateWithScopeValues = true
  rows: TableRow[] = []
  tableName = 'notification'
  pendingImportFiles: PendingImportFile[] = []
  importValidationErrors: ImportValidationError[] = []
  status = ''


  async beforeMount() {
    DKFDS.init()
    this.loadTabledata()
    await configurationClient
        .getImportAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
}

  updated() {
    DKFDS.init()
  }

  async getDummy() {
    await this.dummy().then(async (cname) => {
      console.log("dummy")
    })
  }

  async dummy() {
    return {}
  }

  recalculateAll() {
    this.recalculateErrors = 'Startet genberegning alle...'
    try {
      calculationClient.recalculateAll().then(((response) => {
            if (response.data) {
              this.recalculateErrors = response.data
            } else {
              this.recalculateErrors = 'Genberegning alle done'
            }
          })
      )
    } catch (error) {
      console.error("Error making API request:", error);
    }
  }

  recalculateOwn() {
    this.recalculateErrors = 'Startet genberegning egne...'
    try {
      calculationClient.recalculateOwn().then(((response) => {
            if (response.data) {
              this.recalculateErrors = response.data
            } else {
              this.recalculateErrors = 'Genberegning egne done'
            }
          })
      )
    } catch (error) {
      console.error("Error making API request:", error);
    }
  }

  async loadTabledata() {
    let result: TableRow[] = []
    this.rows = result
    await configurationClient.getTableRows(this.tableName).then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    return this.rows = result
  }

  async exportEMF() {
    let result = {}

    await emissionsfactorImportClient.exportEMFMasterdata().then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'EMF sql to export', 'height=600,width=800')
    if (win) {
      win.document.write('<pre>' + result.toString() + '</pre>')
      win.alert('SQL til EMF stamdata, skal typisk puttes i liquibase fil og bygges ind i release')
    }
  }

  async exportEMCToEMFMapping() {
    let result = {}

    await emissionsfactorImportClient.exportEMCToEMFMappingUpdates().then((response) => {
      if (response.data) {
        result = response.data
      }
    })
    const win = window.open('', 'EMC-EMF mapping sql to export', 'height=600,width=800')
    if (win) {
      win.document.write('<pre>' + result.toString() + '</pre>')
      win.alert('SQL til EMC til EMF mapping stamdata, skal typisk puttes i liquibase fil og bygges ind i release')
    }
  }

  async aiString() {
    let result = {}

    const question = prompt("Spørgsmål", "Lastbiler Diesel reduktion af CO2 forslag");
    if (question) {
      await aiClient.getAiAnswer(question).then((response) => {
        if (response.data) {
          result = response.data
        }
      })
      const win = window.open('', 'AI svar', 'height=600,width=800')
      if (win) {
        result.toString().replaceAll('\n', '<br>')
        win.document.write('<p>Spørgsmål:<br>' + question + '<br><br>Svar:<br>' + result.toString().replaceAll('\n', '<br>') + '</p>')
      }
    }
  }

}
</script>

<style scoped>
form {
  margin-top: 24px;
}

.calc-summary li:first-of-type {
  margin-top: 0;
}
</style>