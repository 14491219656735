import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessCalculator = _resolveComponent("ProcessCalculator")!
  const _component_ProcessOwnCalculator = _resolveComponent("ProcessOwnCalculator")!
  const _component_CalculatorTabComponent = _resolveComponent("CalculatorTabComponent")!

  return (_openBlock(), _createBlock(_component_CalculatorTabComponent, null, {
    "calculator-primary-1": _withCtx((slotProps) => [
      _createVNode(_component_ProcessCalculator, {
        title: slotProps.title,
        tooltip: slotProps.tooltip,
        expanded: true
      }, null, 8, ["title", "tooltip"])
    ]),
    "calculator-secondary-1": _withCtx((slotProps) => [
      _createVNode(_component_ProcessOwnCalculator, {
        subgroupingId: _ctx.constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER,
        title: slotProps.title
      }, null, 8, ["subgroupingId", "title"])
    ]),
    _: 1
  }))
}