<template>
  <BaseCalculator
    :expanded="expanded"
    :subgroupingId="subgroupingId"
    :title="title"
    :tooltip="tooltip">
    <template v-slot:basecalculator-row-1-col-2="scopeProps">
      <!-- Brændselstype -->
      <GenericInput
        v-if="scopeProps.fields[1]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[1].id}`"
        :cy-id="`calculator-${subgroupingId}-field-${scopeProps.fields[1].id}`"
        v-model="scopeProps.fields[1].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :fieldId="scopeProps.fields[1].id"
        :lable="scopeProps.fields[1].lable"
        :max="scopeProps.fields[1].maximumValue"
        :min="scopeProps.fields[1].minimumValue"
        :possibleValues="scopeProps.fields[1].possibleValues"
        :regex="findRegexPattern(scopeProps.fields[1].validationRegex)"
        :required="scopeProps.fields[1].required"
        :step="scopeProps.fields[1].step"
        :tooltip="scopeProps.fields[1].tooltip"
        :type="scopeProps.fields[1].valueType"
        :validationError="scopeProps.fields[1].error"
        @selectedDependents="(arg1, arg2) => scopeProps.setSelectedDependent(arg1, arg2)"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
        @input="scopeProps.setCalculationChanged"
        @focusout="scopeProps.setCalculationChanged"
      />
    </template>
    <template v-slot:basecalculator-row-1-col-3="scopeProps">
      <!-- Andel af biogas -->
      <GenericInput
          v-if="scopeProps.fields[2]"
          :id="`calculator-${subgroupingId}-field-${scopeProps.fields[2].id}`"
          v-model="scopeProps.fields[2].value"
          :disabled="bioGasPartDisabled(scopeProps, scopeProps.fields[1])"
          :doubleLineHeight="true"
          :lable="scopeProps.fields[2].lable"
          :max="scopeProps.fields[2].maximumValue"
          :min="scopeProps.fields[2].minimumValue"
          :possibleValues="scopeProps.fields[2].possibleValues"
          :regex="findRegexPattern(scopeProps.fields[2].validationRegex)"
          :required="scopeProps.fields[2].required"
          :step="scopeProps.fields[2].step"
          :tooltip="scopeProps.fields[2].tooltip"
          :type="scopeProps.fields[2].valueType"
          :validationError="scopeProps.fields[2].error"
          :blankSetsDefaultValue="true"
          @input="scopeProps.setCalculationChanged"
          @focusout="scopeProps.setCalculationChanged"
          @update:modelValue="scopeProps.applyConditions"
          @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
          @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
      />
    </template>
    <template v-slot:basecalculator-row-1-col-4="scopeProps">
      <!-- Mængde -->
      <GenericInput
        v-if="scopeProps.fields[3]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[3].id}`"
        v-model="scopeProps.fields[3].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :lable="scopeProps.fields[3].lable"
        :max="scopeProps.fields[3].maximumValue"
        :min="scopeProps.fields[3].minimumValue"
        :possibleValues="scopeProps.fields[3].possibleValues"
        :regex="findRegexPattern(scopeProps.fields[3].validationRegex)"
        :required="scopeProps.fields[3].required"
        :step="scopeProps.fields[3].step"
        :tooltip="scopeProps.fields[3].tooltip"
        :type="scopeProps.fields[3].valueType"
        :validationError="scopeProps.fields[3].error"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
        @input="scopeProps.setCalculationChanged"
        @focusout="scopeProps.setCalculationChanged"
      />
    </template>
    <template v-slot:basecalculator-row-1-col-5="scopeProps">

      <!--:possibleValues="
            scopeProps.fields[1].value === 'Fyringsolie' || scopeProps.fields[1].value === 'Benzin' || scopeProps.fields[1].value === 'Diesel' ? [{ id: 5, name: 'L', disabled: false }] : [{ id: 4, name: 'Nm3', disabled: false }]
          "-->

      <!-- Special conditional for E03.US02 AK 2.1.2.1.5 BEGIN -->
      <GenericInput
        v-if="scopeProps.fields[4]"
        :id="`calculator-${subgroupingId}-field-${scopeProps.fields[4].id}`"
        v-model="scopeProps.fields[4].value"
        :disabled="scopeProps.isReadOnly ?? false"
        :doubleLineHeight="true"
        :lable="scopeProps.fields[4].lable"
        :max="scopeProps.fields[4].maximumValue"
        :min="scopeProps.fields[4].minimumValue"
        :possibleValues="filter(scopeProps.fields[4].possibleValues, scopeProps.fields[1])"
        :regex="findRegexPattern(scopeProps.fields[4].validationRegex)"
        :required="true"
        :step="scopeProps.fields[4].step"
        :tooltip="this.getContentfulString('calculator.unit.tooltip.nm3')"
        :type="scopeProps.fields[4].valueType"
        :validationError="scopeProps.fields[4].error"
        @update:modelValue="scopeProps.applyConditions"
        @value-invalid="(arg1, arg2) => scopeProps.applyValidationError(arg1, arg2)"
        @value-valid="(arg1) => scopeProps.applyValidationOk(arg1)"
      />
      <!-- Special conditional for E03.US02 AK 2.1.2.1.5 END -->
    </template>
  </BaseCalculator>
</template>

<script lang="ts">
import BaseCalculatorComponent from "@/components/calculator/base-components/BaseCalculatorComponent"
import { CombinedField } from "@/types/CombinedField"
import {Constants} from "@/shared/Constants";
import {FieldEnum} from "../../../../../openapi/generated-clients/climatecompass";
export default class FuelCalculator extends BaseCalculatorComponent {

  filter(units : Array<FieldEnum>, items : CombinedField) : Array<FieldEnum>  {
    if (items && items.possibleValues) {
      for (const item of items.possibleValues) {
        //console.log('item name && value ', Constants.FIELD_ENUM_ID_PREFIX+item.id, items.value)
        if (Constants.FIELD_ENUM_ID_PREFIX+item.id === items.value) {
          console.log(JSON.stringify(item, null, 2))
          return item.dependent || []
        }
      }
    }
    return units
  }

  bioGasPartDisabled(scopeProps: any, items : CombinedField) : boolean  {
    if (this.calculation.companyInformation?.country === 'US') {
      return true //Special condition, no bio pct for US, see KK2-2553
    }
    //Special conditional for E03.US02 AK 2.1.2.1.3
    return (scopeProps.fields[1].id === Constants.INPUT_BRAENDSELSTYPE && scopeProps.fields[1].value !== this.constants.FE_ID_INPUT_ANDEL_BIOGAS) || scopeProps.isReadOnly
  }

}
</script>

<style scoped>

</style>