<!-- NOSONAR -->
<!-- SQ wants to use <output> wrapper, rather than div. However it is wrong in this case, so flagged to skip -->
<template>
  <div class="toast-container d-print-none" :id='identifier'>

    <template v-for="toast in toastList" :key="toast">
      <div :class="'toast-' + toast.type + ' toast ' + showStatus(toast.id)" :id="'toast-notification-' + toast.id" role="status" aria-live="polite" aria-atomic="true">
        <div class="toast-icon"></div>
        <div class="toast-message">
          <p class="bold">{{ toast.header }}</p>
          <button v-if="toast.showCloseButton" class="toast-close" @click="onClose(toast.id)" aria-label="Close toast notification">Close toast notification</button>
          <p v-if="toast.renderAsHtml" v-dompurify-html="toast.message"></p>
          <p v-else>{{ toast.message }}</p>
          <slot name="footer" />
        </div>
      </div>
    </template>

  </div>
</template>

<script lang="ts">
import {Vue} from "vue-class-component";
import ToastMessage from "@/types/ToastMessage";
import { Prop } from 'vue-property-decorator'
import VueDOMPurifyHTML from 'vue-dompurify-html';

export default class MultipleToast extends Vue {
  @Prop({required:false})
  identifier = 'multi-toast'

  toastList: ToastMessage[] = []

  addToast(toast: ToastMessage) {
    //console.log('Multipletoast.addToast start id='+toast.id, toast, this.toastList)
    toast.show = false
    if (toast.onlyOneToastPerId) {
      const deleteDuplicateIndex = this.toastList.findIndex(item => item.id === toast.id)
      if (deleteDuplicateIndex >= 0) {
        this.toastList.splice(deleteDuplicateIndex, 1)
      }
    }
    this.toastList.push(toast)

    if (toast.removeOnTimeout && toast.timeout > 0) {
      setTimeout(() => {
        toast.show = false
        this.removeToast(toast.id)
      }, toast.timeout)
    }
    this.showToast(toast.id)
  }

  showToast(id: string) {
    this.$nextTick(() => {
      this.toastList.filter((toast) => toast.id === id).forEach(toast => toast.show = true)
    })
  }

  showStatus(id: string): string {
    const toast = this.toastList.find((toast) => toast.id === id)
    if (toast) {
      if (toast.show) {
        return "show"
      }
    }
    return "hide"
  }

  removeToast(id: string) {
    const removeIndex = this.toastList.findIndex((toast) => toast.id === id)
    if (removeIndex > -1) {
      this.toastList.splice(removeIndex, 1)
    }
  }

  onClose(id: string) {
    this.removeToast(id)
    this.$emit("multipleToastClose", id)
  }
}
</script>

<style scoped>
.toast-message {
  width: 100% !important;
}
</style>