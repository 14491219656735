<template>
  <SharedHeader :tabMenu="menu">
    <template #firstColumn>
      <h1><SimpleText text-key="import-export.import.headerTitle"/></h1>
      <p><SimpleText text-key="import-export.import.headerText"/></p>
    </template>
  </SharedHeader>

  <div class="container px-0 py-9" id="wrapper-div">
    <div class='row'>

      <div class="form-group">
        <input ref="fileUpload" type="file" @change="addFile" hidden>
        <button class="button button-secondary adjust-button-size" @click="selectFile">
          <SimpleText text-key="import-export.import.select-file"/>
        </button>
      </div>
    </div>
    <div class='row'>
      <form
          id="purpose"
          v-if="calculation.calculationPurpose"
          @change="
      formValidation.clearValidField($event);
    "
          @submit.prevent="importFile()"
          @invalid.capture.prevent="
      formValidation.handleFormValidity($event, true);
    "
      >
        <div
            v-if="formValidation.formErrors.size"
            aria-atomic="true"
            class="alert alert-error"
            role="alert"
        >
          <div class="alert-body">
            <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
            <ul class="alert-text">
              <li
                  v-for="[key, value] of formValidation.formErrors"
                  :key="key"
                  :data-cy="'error_' + key"
              >
                {{ value }}
              </li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="calculation-export-name">
            <SimpleText text-key="import-export.export.calculation-name-in-export"/>
          </label>
          <input id="calculation-export-name" class="form-input" type="text" :disabled="!isCalculationSelected()"
                 v-model="calculationName"
                 :data-error="nameOfCalculationError"
                 maxlength="40"
                 :pattern="findRegexPattern('regex.validation.calculation-name')"
                 required
          />
        </div>
        <div class="form-group">
          <button class="button button-primary adjust-button-size" :disabled="!isReadyForImport()" @click="importFile()">
            <SimpleText text-key="import-export.import.import-file"/>
          </button>
        </div>
      </form>

    </div>

    <div class="container px-0 py-9" id="wrapper-div">
      <div class='row' v-if="isShowAccordion()">
        <ul class="accordion" >
          <li>
            <h2>
              <button class="accordion-button" aria-expanded="false" aria-controls="a1">
                <span :class="['accordion-title', { 'error': isSStatusError() }]">{{ getStatusText() }}</span>
              </button>
            </h2>
            <div id="a1" aria-hidden="false" class="accordion-content">
              <div v-if="validationErrors.length > 0">
                <h2 v-if="jsonFileName.fileName">{{ getErrorTableTitle() }}</h2>
                <div class="table&#45;&#45;responsive-scroll" style="height: 550px; overflow: auto">
                  <table class="table w-percent-100">
                    <caption></caption>
                    <thead>
                    <tr>
                      <th id="excel-file-name" colspan="5" v-if="jsonFileName.fileName" style="text-align: center;">
                        {{ jsonFileName.fileName }}
                      </th>
                    </tr>
                    </thead>
                    <thead>
                    <tr>
                      <th style="width: 15%" id="validate_error_type">{{ getContentfulString('import-export.import.error-type.title') }}</th>
                      <th style="width: 40%" id="validate_error_description">{{ getContentfulString('import-export.import.error-type.error-import') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in validationErrors" :key="item.name">
                      <td style="width: 15%">{{ getErrorTypeMessage(item.name) }}</td>
                      <td style="width: 40%">{{ getContentfulStringAndReplacePlaceholders(item.textKey ?? "NA", item.parameters) }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else>
                {{ getStatusText() }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { Menu } from "@/shared/MenuStructure"
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { Options } from 'vue-class-component'
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import {calculationImportExportClient} from "@/shared/BackendService";
import {
  FileMessage,
  ImportValidationError, LocalizedValidationError,
  PendingImportFile, TableRow
} from "../../../openapi/generated-clients/climatecompass";
import DKFDS from "dkfds";
import {FormValidation} from "@/shared/FormValidation";
import {nextTick} from "vue";

enum ImportStatus {
  NoFileSelected,
  FileSelected,
  ImportInProgress,
  ImportOk,
  ImportError
}

@Options({ components: {SimpleText, SharedHeader } })
export default class Import extends BaseComponent {
  menu = {} as Menu

  calculationName = ''

  formValidation = new FormValidation()

  $refs!: {
    fileUpload : HTMLInputElement
  }

  validateWithScopeValues = false
  jsonFile = {} as File
  jsonFileName = {} as Partial<FileMessage>
  validationErrors: LocalizedValidationError[] = []
  pendingImportFiles: PendingImportFile[] = []
  importValidationErrors: ImportValidationError[] = []
  status: ImportStatus = ImportStatus.NoFileSelected

  rows: TableRow[] = []
  tableName = 'notification'


  get documentToHtmlString() {
    return documentToHtmlString
  }

  async beforeMount() {
    DKFDS.init()

    this.menu = this.store.getters.getMenuStructure.importExportTabs
  }

  async mounted() {
    DKFDS.init()
  }

  async importFile() {
    console.log('Json file : ' + this.jsonFileName.fileName)
    this.status = ImportStatus.ImportInProgress
    if (this.jsonFileName.fileName === undefined) {
      window.alert("Der skal vælges en Json fil")
    } else {
      this.validationErrors = (await calculationImportExportClient.importJson(this.calculationName, this.jsonFile, !this.validateWithScopeValues)).data || []
    }
    if (this.validationErrors.length === 0) {
      this.status = ImportStatus.ImportOk
    } else {
      this.status = ImportStatus.ImportError
    }
  }

  selectFile() {
    console.log("selectFile() called")
    let fileInputElement = this.$refs.fileUpload;
    fileInputElement.click();
  }

  async addFile(e: Event) {
    const element = e.target as HTMLInputElement
    if (!this.jsonFileName) {
      this.jsonFileName = {}
    }
    if (!element.files) return
    this.jsonFile = element.files[0]
    this.jsonFileName.fileName = element.files[0].name
    this.jsonFileName.fileType = element.files[0].type
    element.form?.reset()

    const fileContent = await this.jsonFile.text();
    let data: any = {}
    try {
      data = JSON.parse(fileContent);
      //console.log("addFile", fileContent, data, data.calculation_import?.calculation_purpose?.name)
      if (data && data.calculation_import?.calculation_purpose?.name) {
        this.calculationName = data.calculation_import?.calculation_purpose?.name;
      } else {
        console.error("Invalid JSON format: 'name' field not found.");
      }
    } catch (e) {
      this.calculationName = this.jsonFileName.fileName;
      console.error("Invalid JSON format: 'name' field not found.", e);
    }
    this.status = ImportStatus.FileSelected
    nextTick(() => DKFDS.init())
  }

  getStatusText() {
    DKFDS.init()
    return this.store.getters.getContentfulContent.findSimpleText('import-export.import.status-message.' + ImportStatus[this.status])
        .replace("_file-name_", this.jsonFileName.fileName)
        .replace("_import-calculation-name_", this.calculationName)
  }

  getErrorTableTitle() {
    return this.getContentfulString("import-export.import.error-table.title")
        .replaceAll("_message_count_", this.validationErrors.length)
  }

  getErrorTypeMessage(type: string): string {
    return this.getContentfulString("import-export.import.error-type." + type)
  }

  isCalculationSelected(): boolean {
    console.log("isCalculationSelected()", this.jsonFileName.fileName, (this.jsonFileName.fileName ? true : false))
    return (this.jsonFileName.fileName ? true : false)
  }

  isReadyForImport() {
    console.log("isReadyForImport()", this.status, ImportStatus[this.status], this.status !== ImportStatus.NoFileSelected && this.status !== ImportStatus.ImportInProgress)
    return this.status !== ImportStatus.NoFileSelected && this.status !== ImportStatus.ImportInProgress
  }

  isShowAccordion() {
    console.log("isShowAccordion()", this.status, ImportStatus[this.status], this.status !== ImportStatus.NoFileSelected)
    return this.status !== ImportStatus.NoFileSelected
  }

  isSStatusError() {
    return this.status === ImportStatus.ImportError
  }

  get nameOfCalculationError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.nameOfCalculationError')
  }
}
</script>

<style scoped>

.error {
  color: red;
}
</style>