import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vModelText as _vModelText, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  class: "container px-0 py-9",
  id: "wrapper-div"
}
const _hoisted_3 = {
  key: 0,
  "aria-atomic": "true",
  class: "alert alert-error",
  role: "alert"
}
const _hoisted_4 = { class: "alert-body" }
const _hoisted_5 = { class: "alert-heading" }
const _hoisted_6 = { class: "alert-text" }
const _hoisted_7 = ["data-cy"]
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  class: "form-label",
  for: "input-choose-calculation"
}
const _hoisted_10 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_11 = {
  disabled: "",
  value: ""
}
const _hoisted_12 = ["value", "id"]
const _hoisted_13 = {
  disabled: "",
  value: ""
}
const _hoisted_14 = ["value", "id"]
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  class: "form-label",
  for: "calculation-export-name"
}
const _hoisted_17 = ["disabled", "data-error", "pattern"]
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_SharedHeader = _resolveComponent("SharedHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, { tabMenu: _ctx.menu }, {
      firstColumn: _withCtx(() => [
        _createElementVNode("h1", null, [
          _createVNode(_component_SimpleText, { "text-key": "import-export.export.headerTitle" })
        ]),
        _createElementVNode("p", null, [
          _createVNode(_component_SimpleText, { "text-key": "import-export.export.headerText" })
        ])
      ]),
      _: 1
    }, 8, ["tabMenu"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.calculation.calculationPurpose)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              id: "purpose",
              onChange: _cache[4] || (_cache[4] = ($event: any) => {
      _ctx.formValidation.clearValidField($event);
    }),
              onSubmit: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.exportCalculationAsJson()), ["prevent"])),
              onInvalidCapture: _cache[6] || (_cache[6] = _withModifiers(($event: any) => {
      _ctx.formValidation.handleFormValidity($event, true);
    }, ["prevent"]))
            }, [
              (_ctx.formValidation.formErrors.size)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("p", _hoisted_5, [
                        _createVNode(_component_SimpleText, { "text-key": "sharedContent.formErrorTitle" })
                      ]),
                      _createElementVNode("ul", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValidation.formErrors, ([key, value]) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: key,
                            "data-cy": 'error_' + key
                          }, _toDisplayString(value), 9, _hoisted_7))
                        }), 128))
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, [
                  _createVNode(_component_SimpleText, { "text-key": "generic.choose-calculation.label" })
                ]),
                _withDirectives(_createElementVNode("select", {
                  "aria-labelledby": "choose-calculation-div",
                  class: "form-select",
                  name: "options",
                  id: "input-choose-calculation",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCalculationId) = $event)),
                  onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.watchCalculationId())),
                  onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.watchCalculationId()))
                }, [
                  _createElementVNode("option", _hoisted_10, [
                    _createVNode(_component_SimpleText, { "text-key": "generic.input.dropdown.option-select.name" })
                  ]),
                  _createElementVNode("option", _hoisted_11, [
                    _createVNode(_component_SimpleText, { "text-key": "generic.draft" })
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([..._ctx.possibleDraftCalculations], (calculation) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: calculation.calculationPurpose.name,
                      value: calculation.id,
                      id: calculation.calculationPurpose.name
                    }, _toDisplayString(_ctx.calculationSelectDisplayFormat(calculation)), 9, _hoisted_12))
                  }), 128)),
                  _createElementVNode("option", _hoisted_13, [
                    _createVNode(_component_SimpleText, { "text-key": "generic.completed" })
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([..._ctx.possibleFinishedCalculations], (calculation) => {
                    return (_openBlock(), _createElementBlock("option", {
                      key: calculation.calculationPurpose.name,
                      value: calculation.id,
                      id: calculation.calculationPurpose.name
                    }, _toDisplayString(_ctx.calculationSelectDisplayFormat(calculation)), 9, _hoisted_14))
                  }), 128))
                ], 544), [
                  [_vModelSelect, _ctx.selectedCalculationId]
                ])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("label", _hoisted_16, [
                  _createVNode(_component_SimpleText, { "text-key": "import-export.export.calculation-name-in-export" })
                ]),
                _withDirectives(_createElementVNode("input", {
                  id: "calculation-export-name",
                  class: "form-input",
                  type: "text",
                  disabled: !_ctx.isCalculationSelected(),
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.calculationName) = $event)),
                  "data-error": _ctx.nameOfCalculationError,
                  maxlength: "40",
                  pattern: _ctx.findRegexPattern('regex.validation.calculation-name'),
                  required: ""
                }, null, 8, _hoisted_17), [
                  [_vModelText, _ctx.calculationName]
                ])
              ]),
              _createElementVNode("button", {
                id: "button-import-calculation",
                type: "submit",
                class: "button button-primary mt-705",
                disabled: !_ctx.isCalculationSelected()
              }, [
                _createVNode(_component_SimpleText, { "text-key": "import-export.export.button.label" })
              ], 8, _hoisted_18)
            ], 32))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}