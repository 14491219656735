<template>
  <div>
    <ApexChart
        type="bar"
        :options="chartOptions"
        :series="chartOptions.series"
        width="100%"
        height="400"
        ref="vueApexChartRef"
    ></ApexChart>
  </div>
</template>

<script lang="ts">
import VueApexCharts, {VueApexChartsComponent} from 'vue3-apexcharts';
import { Constants } from '@/shared/Constants';
import {Options} from "vue-class-component";
import CypressTestElement from "@/components/testhelpers/CypressTestElement.vue";
import SimpleText from "@/components/SimpleText.vue";
import {
  CalculationFull,
  ComparisonObject
} from "../../../openapi/generated-clients/climatecompass";
import ComparisonBase from "@/components/comparison/ComparisonBase";
import {graphPrettify} from "@/shared/PrettifyNumbers";

@Options({
  components: {
    CypressTestElement,
    ApexChart: VueApexCharts,
    SimpleText
  }
})
export default class ComparisonChartMainCategory extends ComparisonBase {
  selectedComparisonCriteria: string | undefined
  selectedComparisonScopeCriteria: string[] | undefined
  comparison = {} as ComparisonObject

  chartOptions: any

  $refs!: {
    vueApexChartRef: VueApexChartsComponent
  }

  beforeMount() {
    console.log("Maincat chart beforeMount", this.selectedComparisonCriteria)
    this.initChart()
    if (this.selectedComparisonCriteria === 'CATEGORIES') {
      this.getIndividualGroupChart()
    }
    //console.log("ComparisonChart beforeMount end", this.selectedComparisonCriteria)
  }

  mounted() {
      this.$nextTick(() => {
        console.log("Maincat chart emit mounted")
        this.$emit('mounted');
      })
  }

  updateChart(newSelectedComparisonCriteria: string, newSelectedComparisonScopeCriteria: string[], newComparison: ComparisonObject) {
    console.log("Maincat chart updateChart: update graph", newSelectedComparisonCriteria, newSelectedComparisonScopeCriteria, newComparison)
    if (newSelectedComparisonCriteria && newSelectedComparisonScopeCriteria && newComparison) {

      this.selectedComparisonCriteria = newSelectedComparisonCriteria
      this.selectedComparisonScopeCriteria = this.getOrderedScopes(newSelectedComparisonScopeCriteria)
      this.comparison = newComparison
      this.getIndividualGroupChart()
    }
  }

  getOrderedScopes(newSelectedComparisonScopeCriteria: string[]): string[] {
    const ordereredScopes = [] as string[]
    if (newSelectedComparisonScopeCriteria.includes("SCOPE1")) { ordereredScopes.push("SCOPE1") }
    if (newSelectedComparisonScopeCriteria.includes("SCOPE2")) { ordereredScopes.push("SCOPE2") }
    if (newSelectedComparisonScopeCriteria.includes("SCOPE3")) { ordereredScopes.push("SCOPE3") }
    if (newSelectedComparisonScopeCriteria.includes("UDENFOR SCOPES")) { ordereredScopes.push("UDENFOR SCOPES") }
    return ordereredScopes
  }

  getIndividualGroupChart() {
    //console.log("getIndividualGroupChart")

    const subgroupByCstringMap = new Map<string, string[]>()
    for (let i=1; i<53; i++) {
      const subgroup = "SG_"+i;
      const cstring = this.getMergedGroupContentfulKeyBySubgroup(subgroup)
      const part = subgroupByCstringMap.get(cstring)
      if (part) {
        part.push(subgroup)
      } else {
        subgroupByCstringMap.set(cstring, [subgroup])
      }
    }
    console.log("subgroupByCstringMap", subgroupByCstringMap)

    if (this.comparison && this.comparison.calculations) {
      console.log("getIndividualGroupChart calculations", this.comparison)
      const calculationList = this.comparison.calculations.map(c => (c.calculation?.calculationPurpose.name ? c.calculation?.calculationPurpose.name : ''))
      const newSeries = [] as any[]


      if (this.selectedComparisonScopeCriteria && this.selectedComparisonCriteria) {

        const subgroupsForGroup = this.subgroupByGroupMap.get(this.selectedComparisonCriteria) ?? [] as string[];
        const  mergedGroupKeys = [] as string[]
        for (const subgroup of subgroupsForGroup) {
          const mergedGroupKey = this.getMergedGroupContentfulKeyBySubgroup(subgroup)
          //console.log("subgroup->merged", subgroup, mergedGroupKey)
          if (!mergedGroupKeys.includes(mergedGroupKey)) {
            mergedGroupKeys.push(mergedGroupKey)
          }
        }
        console.log("mergedGroupKeys", mergedGroupKeys)


        //console.log("getscopes newSeries init", newSeries)
        for (const scope of this.selectedComparisonScopeCriteria) {
          if (this.comparison.calculations) {

            for (const calculation of this.comparison.calculations) {

              let calculationName = calculation.calculation?.calculationPurpose.name;

              let name = "NA"
              let data = [] as number[]
              for (const mergedGroup of mergedGroupKeys) {
                const subgroups = this.subgroupByMergedGroup.get(mergedGroup) ?? [] as string[]
                if (scope === 'SCOPE1') {
                  name = this.mainCategoryNameFormatter('comparison.resultview.scope1.text', calculationName)

                  data.push(Math.round((this. sumSubgroups(calculation, subgroups, ['SCOPE1']) ?? 0) * 100) / 100)
                }
                if (scope === 'SCOPE2') {
                  name = this.mainCategoryNameFormatter('comparison.resultview.scope2.text', calculationName)
                  data.push(Math.round((this. sumSubgroups(calculation, subgroups, ['SCOPE2']) ?? 0) * 100) / 100)
                }
                if (scope === 'SCOPE3') {
                  name = this.mainCategoryNameFormatter('comparison.resultview.scope3.text', calculationName)
                  data.push(Math.round((this.sumSubgroups(calculation, subgroups, ['SCOPE3']) ?? 0) * 100) / 100)
                }
                if (scope === 'UDENFOR SCOPES') {
                  name = this.mainCategoryNameFormatter('comparison.resultview.outside-scope.text', calculationName)
                  data.push(Math.round((this.sumSubgroups(calculation, subgroups, ['UDENFOR SCOPES']) ?? 0) * 100) / 100)
                }
              }
              let item = {name: name, group: calculationName, data: data};
              newSeries.push(item)
              //console.log("getscopes add item", calculationName, scope, item, newSeries)
            }
          }
        }
        if (newSeries.length === 0) {
          newSeries.push({name: "NA", data: []})
        }

        const newCategories = [] as any[]
        for (const mergedGroupName of mergedGroupKeys) {
          for (const calculation of calculationList){
            newCategories.push(this.getContentfulString(mergedGroupName) + "-" + calculation)
          }
        }

        const optionsUpdate = {
          xaxis: {
            categories: mergedGroupKeys
                .map(mgKey => this.getContentfulString(mgKey))
                .map(mgName => this.categoryStringFormatter(mgName))
          },
          series: newSeries,
        }
        let optionsForChart = Object.assign(optionsUpdate, this.mainCategoriesDefaultChartOptions);
        console.log("getScopesChart newSeries", newSeries, optionsForChart);
        this.$refs.vueApexChartRef.updateOptions(optionsForChart, true, true, true)
      }
    }
  }

  mainCategoryNameFormatter(contentfulKey: string, calculationName: string | undefined): string {
    //return (calculationName ?? "") + " - " + this.getContentfulString(contentfulKey)
    return this.getContentfulString(contentfulKey) + " - " + (calculationName ?? "")
  }

  sumSubgroups(calculation: CalculationFull, subgroups: string[], scopes: string[]): number {
    let total = 0
    for (const subgroup of subgroups) {
      total += this.getEmissionValueSubGroup(calculation, subgroup, scopes)
    }
    return total
  }

  initChart() {
    this.chartOptions = {
      chart: {
        type: 'bar',
        stacked: false,
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '40%',
          endingShape: 'rounded',
        },
      },
      xaxis: {
        categories: ['x'],
      },
      yaxis: {
        title: {
          text: this.getContentfulString('shared.ton-co2e.no-format'),
        },
        labels: {
          formatter: (value: number) => {
            return graphPrettify(value)
          }
        }
      },
      legend: {
        position: 'bottom',
      },
      fill: {
        opacity: 1,
      },
      colors: [
        Constants.SCOPE1_COLOR_HEX,
        Constants.SCOPE2_COLOR_HEX,
        Constants.SCOPE3_COLOR_HEX,
        Constants.OUTSIDE_SCOPES_COLOR_HEX,
      ],
      series: [
        {
          name: this.getContentfulString('comparison.resultview.scope1.text'),
          data: [0],
        }
        ]
    }
  }


  mainCategoriesDefaultChartOptions = {

    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333'],
        fontSize: '10px',
        fontFamily: 'Helvetica, Arial, sans-serif',
      },
      formatter: function (value: string, {seriesIndex, dataPointIndex, w}: any) {
        //console.log("dl formatter", value, seriesIndex, dataPointIndex, w.config.series)

        w.config.series.filter((x: any) => x.name)
        const uniquePrefixes = Array.from(
            new Set(w.config.series.map((item: any) => item.name.substring(0, 7)))
        );
        const numberOfScopes = uniquePrefixes.length
        const numberOfCalculations = w.config.series.length / numberOfScopes
        const calculationIndex = seriesIndex % numberOfCalculations
        const lastScopeForCalculation = (seriesIndex + numberOfCalculations >= w.config.series.length)

        if (lastScopeForCalculation) {
          let seriesTotalCalcIndex = calculationIndex
          let totalValue = 0
          while (seriesTotalCalcIndex < w.config.series.length) {
            totalValue += Number(w.config.series[seriesTotalCalcIndex].data[dataPointIndex])
            seriesTotalCalcIndex += numberOfCalculations
          }
          //console.log("dl formatter return", calculationIndex, totalValue, value,  seriesIndex, dataPointIndex, w.config.series, w.config.series[calculationIndex].data[dataPointIndex] )
          if (totalValue === 0) {
            return ''
          }
          return graphPrettify(totalValue)
        }
        //console.log("dl formatter return value: ", 'n: ' + seriesIndex + ' ' + value)
        return ''
       },
      offsetY: -17,
      hideOverflowingLabels: false
    },
    chart: {
      type: 'bar',
      stacked: true,
      height: 350,
    },
    stroke: {
      width: 2,
      colors: ['#fff']
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top', // top, center, bottom
          hideOverflowingLabels: false
        },
      },
    },
    yaxis: {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      labels: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      opacity: 1,
    },
    colors: [
      function({seriesIndex, dataPointIndex, w}: any) {
        //console.log("colors start: series, datapoint", seriesIndex, dataPointIndex);
        const colorcodesByCalculationAndScope: string[][] = [
          ["#083B1C", "#277F41", "#9AD394", "#C1E5BB"],
          ["#012B5E", "#1967A9", "#5FA5CE", "#BED6EB"],
          ["#340A6F", "#5D4996", "#9290BE", "#D4D5E7"],
          ["#73230E", "#D23E15", "#FC813E", "#FDC99B"],
          ["#D3993E", "#FEB13C", "#FFC76C", "#FFE4B1"]
        ]

        const seriesName = w.config.series[seriesIndex].name
        w.config.series.filter((x: any) => x.name)
        const uniquePrefixes = Array.from(
            new Set(w.config.series.map((item: any) => item.name.substring(0, 7)))
        );
        const numberOfScopes = uniquePrefixes.length
        const numberOfCalculations = w.config.series.length / numberOfScopes
        const calculationIndex = seriesIndex % numberOfCalculations

        let scopeIndex
        //let scopeName = seriesName.substring(seriesName.length-7,seriesName.length);
        let scopeName = seriesName.substring(0,7);
        switch (scopeName) {
          case "Scope 1":
            scopeIndex = 0
            break
          case "Scope 2":
            scopeIndex = 1
            break
          case "Scope 3":
            scopeIndex = 2
            break
          default:
            scopeIndex = 3
            break
        }

        //console.log("colors ["+ scopeName + "]", calculationIndex, scopeIndex)
        //console.log("colors indexes calc: calculation, scope", seriesIndex, dataPointIndex, calculationIndex, scopeIndex, seriesName);
        return colorcodesByCalculationAndScope[calculationIndex][scopeIndex]
      }
    ]
  }

}

</script>

<style scoped>
</style>