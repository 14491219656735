import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d82ada58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-0 py-9" }
const _hoisted_2 = {
  key: 0,
  id: "success-box",
  class: "alert alert-success"
}
const _hoisted_3 = { class: "alert-body" }
const _hoisted_4 = { class: "alert-heading" }
const _hoisted_5 = { class: "alert-text" }
const _hoisted_6 = {
  key: 1,
  "aria-atomic": "true",
  class: "alert alert-error",
  role: "alert",
  id: "third-party-error"
}
const _hoisted_7 = { class: "alert-body" }
const _hoisted_8 = { class: "alert-heading" }
const _hoisted_9 = { class: "alert-text" }
const _hoisted_10 = ["data-cy"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "py-1" }
const _hoisted_13 = { class: "full-width" }
const _hoisted_14 = { class: "py-3" }
const _hoisted_15 = { class: "form-group" }
const _hoisted_16 = {
  id: "vatNo-input-wrapper-div",
  class: "form-group"
}
const _hoisted_17 = {
  class: "form-label",
  for: "input-type-vatNo"
}
const _hoisted_18 = { class: "ml-2" }
const _hoisted_19 = ["data-error", "disabled", "pattern"]
const _hoisted_20 = { class: "form-group" }
const _hoisted_21 = {
  class: "form-label",
  for: "input-type-company-name"
}
const _hoisted_22 = ["data-error"]
const _hoisted_23 = { class: "form-group" }
const _hoisted_24 = {
  class: "form-label",
  for: "input-type-contactperson-name"
}
const _hoisted_25 = ["data-error", "pattern"]
const _hoisted_26 = { class: "form-group" }
const _hoisted_27 = {
  class: "form-label",
  for: "input-type-contactperson-email"
}
const _hoisted_28 = ["data-error", "pattern"]
const _hoisted_29 = { class: "form-group" }
const _hoisted_30 = {
  id: "create-access-multiselect-dropdown-label",
  class: "form-label",
  for: "multi-select-calculations"
}
const _hoisted_31 = { class: "ml-2" }
const _hoisted_32 = { class: "multiselect-wrapper" }
const _hoisted_33 = { class: "form-group" }
const _hoisted_34 = ["checked", "disabled"]
const _hoisted_35 = {
  class: "form-label",
  for: "checkbox-consent"
}
const _hoisted_36 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_SharedHeader = _resolveComponent("SharedHeader")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_DateInputComponent = _resolveComponent("DateInputComponent")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SharedHeader, { tabMenu: _ctx.menu }, {
      firstColumn: _withCtx(() => [
        _createElementVNode("h1", null, [
          _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.headerTitle" })
        ]),
        _createElementVNode("p", null, [
          _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.headerText" })
        ])
      ]),
      _: 1
    }, 8, ["tabMenu"]),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.showSuccess)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", _hoisted_4, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.successTitle" })
              ]),
              _createElementVNode("p", _hoisted_5, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.successText" })
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.formValidation.formErrors.size)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", _hoisted_8, [
                (!_ctx.specialTitle)
                  ? (_openBlock(), _createBlock(_component_SimpleText, {
                      key: 0,
                      "text-key": "sharedContent.formErrorTitle"
                    }))
                  : _createCommentVNode("", true),
                (_ctx.specialTitle)
                  ? (_openBlock(), _createBlock(_component_SimpleText, {
                      key: 1,
                      "text-key": "sharedContent.formInvalidTitle"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("ul", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValidation.formErrors, ([key, value]) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: key,
                    "data-cy": 'error_' + key
                  }, [
                    _createElementVNode("a", {
                      href: "javascript:void(0)",
                      onClick: _withModifiers(($event: any) => (_ctx.focusInput(key)), ["prevent"]),
                      style: {"text-decoration":"none"}
                    }, _toDisplayString(value), 9, _hoisted_11)
                  ], 8, _hoisted_10))
                }), 128))
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h1", null, _toDisplayString(_ctx.isEdit() ? this.titleEdit : this.titleAdd), 1),
      _createElementVNode("div", _hoisted_12, [
        _withDirectives(_createElementVNode("div", _hoisted_13, null, 512), [
          [_directive_dompurify_html, _ctx.documentToHtmlString(this.text)]
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("form", {
          onChange: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.formValidation.clearValidField && _ctx.formValidation.clearValidField(...args))),
          onSubmit: _cache[12] || (_cache[12] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
          onInvalidCapture: _cache[13] || (_cache[13] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formValidation.handleFormValidity && _ctx.formValidation.handleFormValidity(...args)), ["prevent"])),
          ref: "formGroup"
        }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.labelCvr" })
              ]),
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_Tooltip, {
                  text: this.labelCvrHint
                }, null, 8, ["text"])
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-vatNo",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentAccess.vatId) = $event)),
                "data-error": this.cvrError,
                class: "form-input",
                disabled: _ctx.disableCvrInput,
                name: "input-type-vatNo",
                pattern: _ctx.findRegexPattern('regex.validation.vat-no'),
                required: "",
                onKeyup: _cache[1] || (_cache[1] = ($event: any) => {_ctx.loadCompanyName();}),
                onChange: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.validateConsentAfterValueUpdate && _ctx.validateConsentAfterValueUpdate(...args)))
              }, null, 40, _hoisted_19), [
                [_vModelText, _ctx.currentAccess.vatId]
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("label", _hoisted_21, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.labelCompanyname" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-company-name",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentAccess.companyName) = $event)),
                "data-error": this.noCompanyname,
                class: "form-input",
                name: "input-type-company-name",
                disabled: "",
                type: "text",
                required: ""
              }, null, 8, _hoisted_22), [
                [_vModelText, _ctx.currentAccess.companyName]
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("label", _hoisted_24, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.labelContactpersonName" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-contactperson-name",
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.currentAccess.contantPersonName) = $event)),
                "data-error": this.contactpersonError,
                class: "form-input",
                name: "input-type-contactperson-name",
                pattern: _ctx.findRegexPattern('regex.validation.person-name'),
                maxlength: "100",
                required: "",
                type: "text",
                onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.validateConsentAfterValueUpdate && _ctx.validateConsentAfterValueUpdate(...args)))
              }, null, 40, _hoisted_25), [
                [_vModelText, _ctx.currentAccess.contantPersonName]
              ])
            ]),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("label", _hoisted_27, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.labelContactpersonEmail" })
              ]),
              _withDirectives(_createElementVNode("input", {
                id: "input-type-contactperson-email",
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.currentAccess.contactPersonEmail) = $event)),
                "data-error": this.emailError,
                class: "form-input",
                name: "input-type-contactperson-email",
                pattern: _ctx.findRegexPattern('regex.validation.e-mail'),
                maxlength: "100",
                minlength: "5",
                required: "",
                type: "email",
                onInput: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.validateConsentAfterValueUpdate && _ctx.validateConsentAfterValueUpdate(...args)))
              }, null, 40, _hoisted_28), [
                [_vModelText, _ctx.currentAccess.contactPersonEmail]
              ])
            ]),
            _createVNode(_component_DateInputComponent, {
              modelValue: _ctx.currentAccess.expireDate,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.currentAccess.expireDate) = $event)),
              "is-optional": true,
              ref: "dateInputComponent",
              onChange: _ctx.validateConsentAfterValueUpdate
            }, null, 8, ["modelValue", "onChange"]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("label", _hoisted_30, [
                _createVNode(_component_SimpleText, { "text-key": "thirdparty.has-access-to.selectCalculations" })
              ]),
              _createElementVNode("span", _hoisted_31, [
                _createVNode(_component_Tooltip, {
                  text: this.selectCalculationsHint
                }, null, 8, ["text"])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_Multiselect, {
                  id: "multi-select-calculations",
                  modelValue: _ctx.selectedCalculations,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedCalculations) = $event)),
                  mode: "tags",
                  "close-on-select": false,
                  hideSelected: false,
                  canClear: false,
                  groups: true,
                  options: _ctx.calsForSelectWithLabels,
                  valueProp: "calculationId",
                  label: "nameAndYear",
                  ref: "calculationsmultiselect",
                  class: "multiselect-calculation form-group",
                  aria: {'aria-labelledby': 'create-access-multiselect-dropdown-label', 'aria-placeholder': null, 'aria-multiselectable': null},
                  onChange: _ctx.validateConsentAfterValueUpdate
                }, null, 8, ["modelValue", "options", "onChange"])
              ])
            ]),
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("input", {
                id: "checkbox-consent",
                type: "checkbox",
                class: "form-checkbox",
                checked: _ctx.consentCheckBoxStatus,
                disabled: _ctx.isConsentDisabled(),
                onInput: _cache[10] || (_cache[10] = ($event: any) => (_ctx.onConsentCheckChanged($event.target.checked)))
              }, null, 40, _hoisted_34),
              _withDirectives(_createElementVNode("label", _hoisted_35, null, 512), [
                [_directive_dompurify_html, _ctx.contentMessage]
              ])
            ])
          ]),
          _createElementVNode("button", {
            id: "submit-button",
            class: "button button-primary px-8 my-4",
            type: "submit",
            disabled: _ctx.submitButtonDisabled
          }, _toDisplayString(_ctx.isEdit() ? this.saveButtonEditText : this.saveButtonCreateText), 9, _hoisted_36)
        ], 544)
      ])
    ])
  ], 64))
}