<template>
  <SharedHeader>
    <template #firstColumn>
      <h1>
        <SimpleText text-key='forecast.reduction-target.title' />
      </h1>
      <span><RichText text-key='forecast.reduction-target.header-text' /></span>
    </template>
    <template #secondColumn>
      <div class='flex flex-lg-row flex-column result-buttons column-row-gap-10'>
        <div class="result-buttons__button-group flex">
          <button
              id="result-export-excel-link"
              class="button button-secondary"
              @click.prevent="exportToExcel"
              :data-cy-id="'klimakompasset-resultat-' + selectedForecastId"
          >
            <svg aria-hidden="true" class="icon-svg" focusable="false">
              <use xlink:href="#file-download"></use>
            </svg>
            <SimpleText text-key='forecast.reduction-target.button.download'/>
          </button>
          <Tooltip :style="`vertical-align: middle;`" :textKey="'forecast.download-excel-tooltip'" size="lg"/>
        </div>

        <div class='result-buttons__button-group'>
          <button
            class='button button-secondary flex-grow-equally'
            @click.prevent="handleDownloadPdf"
          >
            <svg aria-hidden='true' class='icon-svg' focusable='false'>
              <use xlink:href='#file-download'></use>
            </svg>
            <SimpleText text-key='forecast.reduction-target.button.pdf' />
          </button>
        </div>
      </div>
    </template>
  </SharedHeader>

  <!-- select fremskrivning -->

  <div class='container px-0 py-6'>
    <section>
      <AccordionComponent :accordion-list='getAccordions("forecast.reduction-target")'></AccordionComponent>
    </section>
    <section data-cy="select-forecast-main-header">
      <h2>
        <SimpleText text-key='forecast.reduction-target.select-forecast.sub-title' />
      </h2>
      <RichText text-key='forecast.reduction-target.select-forecast.sub-header-text' />

    </section>
    <section>
      <div v-if='formValidation.formErrors.size' aria-atomic='true' class='alert alert-error' ref='formErrorsAlert'
           role='alert'>
        <div class='alert-body'>
          <p class='alert-heading'>
            <SimpleText text-key='generic.input.error.title' />
          </p>
          <ul class='alert-text'>
            <li v-for='[key, value] of formValidation.formErrors' :key='key' :data-cy="'error_' + key">
              <a href='javascript:void(0)' @click.prevent='focusInput(key)' style='text-decoration: none'>
                {{ value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <form @change='formValidation.clearValidField'
          @submit.prevent='submitFormForecast'
          @invalid.capture.prevent='formValidation.handleFormValidity($event, false)'>
      <fieldset>
        <legend aria-describedby="input-choose-forecastname"></legend>
        <div class='row' data-cy="load-forecast-wrapper-div">
          <div class='col-md-3' id='choose-forecastname-div'>
            <div class='form-group'>
              <label id="forecast-select-label" class='form-label' for='input-choose-forecastname'>
                <SimpleText text-key='forecast.calculate-forecast.field.choose-forecast.name' />
              </label>
                <span class='ml-2'><Tooltip
                    :text-key="'forecast.calculate-forecast.field.choose-forecast.tooltip'" /></span>
              <select
                required
                class='form-select'
                name='options'
                id='input-choose-forecastname'
                :data-error='chooseForecastError'
                v-model='selectedForecastId'
                v-if='!editExistingForecast'
              >
                <option disabled selected value=''>
                  <SimpleText text-key='generic.input.dropdown.option-select.name' />
                </option>
                <option v-for='forecast in sortedForecasts' :key='forecast.name' :value='forecast.forecastId'>
                  {{ forecast.name }}
                </option>
              </select>
              <input class='form-input' v-else type='text' :value='forecastName' readonly aria-readonly='true' aria-labelledby="forecast-select-label" />
            </div>
          </div>
          <div class='col-md-2 form-button' >
            <button id='submit-button-forecast' type='submit' class='button button-primary adjust-button-size'>
              {{ getContentfulString('forecast.reduction-target.button-forecast.submit.name') }}
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    <section>
      <div v-if='formValidationScope12.formErrors.size' aria-atomic='true' class='alert alert-error'
           ref='formErrorsAlert' role='alert'>
        <div class='alert-body'>
          <p class='alert-heading'>
            <SimpleText text-key='generic.input.error.title' />
          </p>
          <ul class='alert-text'>
            <li v-for='[key, value] of formValidationScope12.formErrors' :key='key' :data-cy="'error_' + key">
              <a href='javascript:void(0)' @click.prevent='focusInput(key)' style='text-decoration: none'>
                {{ value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <form @change='formValidationScope12.clearValidField'
          @submit.prevent='submitFormScope12'
          @invalid.capture.prevent='formValidationScope12.handleFormValidity($event, false)'>
      <fieldset>
        <legend>
          <h2 class='d-print-pagebreak'>
            <SimpleText text-key='forecast.reduction-target.scope12.sub-title' />
          </h2>
        </legend>
          <span>
            <RichText text-key='forecast.reduction-target.scope12.sub-header-text' />
          </span>
        <div class='row' data-cy="scope-1-2-input-wrapper">
          <div class='col-md-5'>
            <div class=' form-group'>
              <label class='form-label' for='input-type-method-scope12'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-target-method.name' />
              </label
              ><span class='ml-2'><Tooltip
              :text-key="'forecast.calculate-forecast.field.reduction-target-method.tooltip.scope12'" /></span>
              <select :disabled='scope12InputDisabled' required class='form-select' name='options'
                      id='input-type-method-scope12' v-model='typeMethodScope12'>
                <option :value='enumManual' selected>
                  <SimpleText text-key='forecast.reduction-target.field.method.option.manual.name' />
                </option>
                <option :value='enumSbti'>
                  <SimpleText text-key='forecast.reduction-target.field.method.option.sbti.name' />
                </option>
              </select>
            </div>
          </div>

          <div class='col-md-2' id='choose-scope12-year-div'>
            <div class='form-group'>
              <label class='form-label' for='input-choose-scope12-year'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-target-year.name' />
              </label>
              <select :disabled='scope12InputDisabled || method12IsSBTi' required class='form-select'
                      name='options' id='input-choose-scope12-year' v-model='selectedScope12Year'>
                <option v-if="scope12InputDisabled"> {{ getContentfulString('forecast.reduction-target.choose-year.text') }}</option>
                <option v-else v-for='year in [...possibleScope12Years.sort((a, b) => a - b)]' :key='year' :value='year'>
                  {{ year }}
                </option>
              </select>
            </div>

          </div>
          <div class='col-md-2' id='scope12-target-pct-div'>
            <div class='form-group'>
              <label class='form-label' for='input-scope12-target-pct'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-taget-pct.name' />
              </label>
              <div class='form-input-wrapper form-input-wrapper--suffix'>
                <input
                  :disabled='possibleScope12Years.length === 0 || method12IsSBTi'
                  @keydown="validateLocalizedNumberInput($event, 'DECIMAL')"
                  @focus='$event.target.select()'
                  @focusout="validateScopeTargetPctInputs"
                  id='input-scope12-target-pct'
                  v-model='scope12TargetPctWrap'
                  :data-error='scope12TargetPctError'
                  class='form-input'
                  name='input-scope12-target-pct'
                  type='tel'
                  :pattern="findRegexPattern('regex.validation.nummeric.range.0-100.max-1-decimal')"
                  size='9'
                  required
                />
                <div class='form-input-suffix' aria-hidden='true'>%</div>
              </div>
            </div>
          </div>

          <div class='col-md-2 form-button'>
            <button
              :disabled='possibleScope12Years.length === 0'
              id='submit-button-forecast12'
              class='button button-primary adjust-button-size'
              type='submit'
              :name="getContentfulString('forecast.reduction-target.button-scope12.submit.name')"
            >
              <SimpleText :text-key="'forecast.reduction-target.button-scope12.submit.name'" />
            </button>
          </div>
        </div>
      </fieldset>
    </form>

    <!-- scope 12 -->
    <section v-if='scope12Completed' id="reduction-target-scope-12-section">
      <div class='row'>
        <div class='col-md-12'>
          <div class='bg-alternative-green p-405 margin-bot-30'>
            <span data-cy-id='forecast.reduction-target.scope12.infotext.improve' v-if='isScope12Improve' v-dompurify-html='scope12InfoTextImprove' />
            <span data-cy-id='forecast.reduction-target.scope12.infotext.success' v-else v-dompurify-html='scope12InfoTextSuccess' />
          </div>
          <ForecastColumnChart
            ref='scope12chart'
            v-if='scope12ChartCalculations && scope12ChartTarget'
            :raw-data='scope12ChartCalculations'
            :raw-data-reduction='scope12ChartTarget'
            :settings='scope12ChartSettings'
          ></ForecastColumnChart>
          <div data-cy-id='forecast.reduction-target.scope12.infotext.background' v-dompurify-html='scope12InfoTextBackground' />
        </div>
      </div>
    </section>

    <!-- scope 3 -->

    <div class='row d-print-pagebreak'>
      <div class='col-12'>
        <h2>
          <SimpleText text-key='forecast.reduction-target.scope3.sub-title' />
        </h2>
        <div class='py-1'>
          <RichText text-key='forecast.reduction-target.scope3.sub-header-text' />
        </div>
      </div>
    </div>
    <section>
      <div v-if='formValidationScope3.formErrors.size' aria-atomic='true' class='alert alert-error'
           ref='formErrorsAlert' role='alert'>
        <div class='alert-body'>
          <p class='alert-heading'>
            <SimpleText text-key='generic.input.error.title' />
          </p>
          <ul class='alert-text'>
            <li v-for='[key, value] of formValidationScope3.formErrors' :key='key' :data-cy="'error_' + key">
              <a href='javascript:void(0)' @click.prevent='focusInput(key)' style='text-decoration: none'>
                {{ value }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <form
      @change='formValidationScope3.clearValidField'
      @invalid.capture.prevent='formValidationScope3.handleFormValidity($event, false)'
      @submit.prevent='submitFormScope3'>
      <fieldset>
        <legend aria-describedby="forecast.reduction-target.scope3.sub-title" class="sr-only">
          scope-3
          <SimpleText text-key="forecast.reduction-target.fieldset.legend.scope3"/>
        </legend>
        <div class='row' data-cy="scope-3-reduction-input-wrapper">
          <div class='col-md-5'>
            <div class=' form-group'>
              <label class='form-label ' for='input-type-method-scope3'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-target-method.name' />
              </label
              ><span class='ml-2'><Tooltip
              :text-key="'forecast.calculate-forecast.field.reduction-target-method.tooltip.scope3'" /></span>
              <select required class='form-select' name='options' id='input-type-method-scope3'
                      v-model='typeMethodScope3'
                      :disabled='scope3InputDisabled'>
                <option :value='enumManual' selected>
                  <SimpleText text-key='forecast.reduction-target.field.method.option.manual.name' />
                </option>
                <option :value='enumSbti'>
                  <SimpleText text-key='forecast.reduction-target.field.method.option.sbti.name' />
                </option>
              </select>
            </div>
          </div>
          <div class='col-md-2' id='choose-scope3-div'>
            <div class='form-group'>
              <label class='form-label' for='input-choose-scope3-year'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-target-year.name' />
              </label>
              <select :disabled='scope3InputDisabled || method3IsSBTi' required class='form-select'
                      name='options' id='input-choose-scope3-year' v-model='selectedScope3Year'>
                <option v-if="scope3InputDisabled"> {{ getContentfulString('forecast.reduction-target.choose-year.text') }}</option>
                <option v-else v-for='year in [...possibleScope3Years.sort((a, b) => a - b)]' :key='year' :value='year'>
                  {{ year }}
                </option>
              </select>
            </div>
          </div>
          <div class='col-md-2' id='scope3-target-pct-div'>
            <div class='form-group'>
              <label class='form-label ' for='input-scope3-target-pct'>
                <SimpleText text-key='forecast.reduction-target.field.reduction-taget-pct.name' />
              </label>
              <div class='form-input-wrapper form-input-wrapper--suffix'>
                <input
                  :disabled='scope3InputDisabled || method3IsSBTi'
                  @keydown="validateLocalizedNumberInput($event, 'DECIMAL')"
                  @focus='$event.target.select()'
                  @focusout="validateScopeTargetPctInputs"
                  id='input-scope3-target-pct'
                  v-model='scope3TargetPctWrap'
                  :data-error='scope3TargetPctError'
                  class='form-input'
                  name='input-scope3-target-pct'
                  :pattern="findRegexPattern('regex.validation.nummeric.range.0-100.max-1-decimal')"
                  size='9'
                  required
                />
                <div class='form-input-suffix' aria-hidden='true'>%</div>
              </div>
            </div>
          </div>

          <div class='col-md-2 form-button' >
            <button
              :disabled='scope3InputDisabled'
              id='submit-button-reduction'
              class='button button-primary adjust-button-size'
              type='submit'
              :name="getContentfulString('forecast.reduction-target.button-scope3.submit.name')"
            >
              <SimpleText :text-key="'forecast.reduction-target.button-scope3.submit.name'" />
            </button>
          </div>
        </div>
      </fieldset>
    </form>
    <section v-if='scope3Completed' id="reduction-target-scope3-section">
      <div class='row' data-cy="scope3-reduction-column-chart">
        <div class='col-md-12'>
          <div class='bg-alternative-green p-405 margin-bot-30' data-cy-id='forecast.reduction-target.scope3.infotext.improve' v-if='isScope3Improve'>
            <span v-dompurify-html='scope3InfoTextImprove'></span>
          </div>
          <div class='bg-alternative-green p-405 margin-bot-30' data-cy-id='forecast.reduction-target.scope3.infotext.success' v-else>
            <span v-dompurify-html='scope3InfoTextSuccess'></span>
          </div>
          <div>
            <form v-if="showScope3Categories">
              <fieldset>
                <div class='row'>
                  <div class='col-md-12'>
                    <div class='form-group'>
                      <label for="multiselect-scope3-categories" class='form-label' id="multiselect-scope3-categories-label">Kategorier</label>
                      <div class="row">
                        <div class="col-md-7">
                          <Multiselect
                              id="multiselect-scope3-categories"
                              v-model="selectedCategories"
                              mode="tags"
                              :close-on-select="false"
                              :hideSelected="false"
                              :canClear="false"
                              :groups="true"
                              :options="multiselectOptions"
                              track-by="categoryId"
                              valueProp="categoryId"
                              label="categoryPresentationName"
                              ref="forecast-category-select"
                              class="multiselect-calculation"
                              :aria="{'aria-labelledby': 'multiselect-scope3-categories-label', 'aria-placeholder': null, 'aria-multiselectable': null}"
                              :placeholder="getContentfulString('forecast.filter.placeholder.all-Categories-selected')"
                              style="width: 100%"
                          />
                        </div>
                        <div class='col-md-4 form-button'>
                          <button id='submit-button-show-forecast' class='button button-primary'
                                  @click.prevent="showAndSaveForecast()">Vis og gem fremskrivning</button>
                          <span class='ml-2' style="padding-left: 2px"><Tooltip text-key="xxxx"/></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <div v-if="scope3Completed && showScope3Categories" class='bg-alternative-green p-405 margin-bot-30' data-cy-id='forecast.reduction-target.scope3.cat3'>
            <span> {{ scope3CategoryInfoTextOne }} </span>
          </div>

          <ForecastColumnChart
            ref='scope3chart'
            v-if='scope3ChartCalculations && scope3ChartTarget'
            :raw-data='scope3ChartCalculations'
            :raw-data-reduction='scope3ChartTarget'
            :settings='scope3ChartSettings'
            :is-maec='method3IsSBTi'
          ></ForecastColumnChart>
        </div>
      </div>
      <div class='row'>
        <div class='col-md-8 col-sm-12'>
          <div data-cy-id='forecast.reduction-target.scope3.infotext.background' v-dompurify-html='scope3InfoTextBackground' />
        </div>
        <div class='col-md-4 col-sm-12' >
          <p>
            <b>
              <SimpleText text-key='forecast.reduction-target.scope3.piechart.title' />
            </b>
          </p>
          <apexchart ref='scope3Pie' type='pie' width='380' :options='scope3PieOptions' :series='scope3PieSeries'></apexchart>
        </div>
      </div>
    </section>

    <section v-if="showScope3Categories" id="main-header-scope3-section">
      <h3 id="main-header-scope3-categories" class="d-print-none">
        <SimpleText text-key='forecast.reduction-target.scope3-categories.sub-title' />
      </h3>
      <div class='row d-print-pagebreak'>
        <div class='col-md-12'>
          <h3 class="d-print-only">
            <SimpleText text-key='forecast.reduction-target.scope3-categories.sub-title' />
          </h3>
          <p><SimpleText text-key="forecast.reduction-target.scope3-categories.main-description"></SimpleText></p>

          <div>
            <form>
              <fieldset>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="multiselect-scope3-categories2" class="form-label" id="multiselect-scope3-categories-label2">Kategorier</label>
                      <div class="row">
                        <div class="col-md-7">
                          <Multiselect
                              id="multiselect-scope3-categories2"
                              v-model="selectedCategories"
                              mode="tags"
                              :close-on-select="false"
                              :hideSelected="false"
                              :canClear="false"
                              :groups="true"
                              :group-hide-empty="true"
                              :options="multiselectOptions"
                              track-by="categoryId"
                              valueProp="categoryId"
                              label="categoryPresentationName"
                              ref="forecast-category-select"
                              class="multiselect-calculation"
                              :aria="{'aria-labelledby': 'multiselect-scope3-categories-label2'}"
                              :placeholder="getContentfulString('forecast.filter.placeholder.all-Categories-selected')"
                              style="width: 100%;"
                          />
                        </div>
                        <div class="col-md-4 form-button">
                          <button id="submit-button-show-forecast" class="button button-primary"
                                  @click.prevent="showAndSaveForecast()">Vis og gem fremskrivning</button>
                          <span class="ml-2" style="padding-left: 2px"><Tooltip text-key="xxxx" /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>

          <div v-if="scope3Completed" class='bg-alternative-green p-405 margin-bot-30' data-cy-id='forecast.reduction-target.scope3.cat3-2'>
            <span> {{ scope3CategoryInfoTextTwo }} </span>
          </div>
          <Scope3CategoryReductionChart v-if="scope3Completed" :mode="mode" :possible-downstream-categories="possibleDownstreamCategories" :possible-upstream-categories="possibleUpstreamCategories" ref="scope3CategoryChartRef" ></Scope3CategoryReductionChart>
        </div>
      </div>
    </section>

    <section id="reduction-target-net-zero-section">
      <h2 id="main-header-net-zero" class='d-print-none'>
        <SimpleText text-key='forecast.reduction-target.netzero.sub-title' />
      </h2>
      <!-- netzero -->
      <div class='row d-print-pagebreak'>
        <div class='col-md-8'>
          <h2 class='d-print-only'>
            <SimpleText text-key='forecast.reduction-target.netzero.sub-title' />
          </h2>
          <p><SimpleText text-key="forecast.reduction-target.netzero.main-description"></SimpleText></p>
          <div v-if='formValidationNetzero.formErrors.size' aria-atomic='true' class='alert alert-error'
               ref='formErrorsAlert' role='alert'>
            <div class='alert-body'>
              <p class='alert-heading'>
                <SimpleText text-key='generic.input.error.title' />
              </p>
              <ul class='alert-text'>
                <li v-for='[key, value] of formValidationNetzero.formErrors' :key='key' :data-cy="'error_' + key">
                  <a href='javascript:void(0)' @click.prevent='focusInput(key)' style='text-decoration: none'>
                    {{ value }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <form ref='formGroup'
                @change='formValidationNetzero.clearValidField'
                @invalid.capture.prevent='formValidationNetzero.handleFormValidity($event, false)'
                @submit.prevent='submitFormNetzero'
          >
            <fieldset>
              <legend aria-describedby="main-header-net-zero" class="sr-only">
                net-zero
                <SimpleText text-key="forecast.reduction-target.fieldset.legend"/>
              </legend>
              <div class='row' data-cy="net-zero-reduction-input-wrapper">
                <div class='col-md-4 col-sm-6'>
                  <div class=' form-group'>
                    <label class='form-label ' for='input-type-method-netzero'>
                      <SimpleText text-key='forecast.reduction-target.field.reduction-target-method.name' />
                    </label
                    ><span class='ml-2'><Tooltip
                    :text-key="'forecast.calculate-forecast.field.reduction-target-method.tooltip.netzero'" /></span>
                    <select disabled required class=' form-select' style="margin-top: 7px" name='options'
                            id='input-type-method-netzero' v-model='typeMethodNetzero'>
                      <option :value='enumManual' selected>
                        <SimpleText text-key='forecast.reduction-target.field.method.option.manual.name' />
                      </option>
                    </select>
                  </div>
                </div>
                <div class='col-md-2 col-sm-6' id='choose-net-zero-div'>
                  <div class='form-group'>
                    <label class='form-label' for='input-choose-net-zero-year'>
                      <SimpleText text-key='forecast.reduction-target.field.reduction-target-year.name' />
                    </label>
                    <select
                      required
                      :disabled='netzeroInputDisabled'
                      :data-error='netzeroYearError'
                      class='form-select'
                      name='options'
                      id='input-choose-net-zero-year'
                      v-model='selectedNetzeroYear'
                    >
                      <option v-if="netzeroInputDisabled"> {{ getContentfulString('forecast.reduction-target.choose-year.text') }}</option>
                      <option v-else v-for='year in [...possibleNetzeroYears.sort((a, b) => a - b)]' :key='year' :value='year'>
                        {{ year }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class='col-md-3 col-sm-6' id='netzero-target-pct-div'>
                  <div class='form-group'>
                    <label class='form-label ' for='input-net-zero-target-pct'>
                      <SimpleText text-key='forecast.reduction-target.field.reduction-taget-pct.name' />
                    </label>
                    <div class='form-input-wrapper form-input-wrapper--suffix'>
                      <input
                        :disabled='netzeroInputDisabled'
                        @keydown="validateLocalizedNumberInput($event, 'DECIMAL')"
                        @focus='$event.target.select()'
                        @focusout="validateScopeTargetPctInputs"
                        id='input-net-zero-target-pct'
                        v-model='netzeroTargetPct'
                        :data-error='netzeroTargetPctError'
                        class='form-input'
                        name='input-scope3-target-pct'
                        :pattern="findRegexPattern('regex.validation.nummeric.range.0-100.max-1-decimal')"
                        size='9'
                        required
                      />
                      <div class='form-input-suffix' aria-hidden='true'>%</div>
                    </div>
                  </div>
                </div>
                <div class='col-md-3 col-sm-6 form-button'>
                  <button
                    :disabled='netzeroInputDisabled'
                    id='submit-button-net-zero'
                    class='button button-primary adjust-button-size'
                    :name="getContentfulString('forecast.reduction-target.button-netzero.submit.name')"
                    type='submit'

                  >
                    <SimpleText :text-key="'forecast.reduction-target.button-netzero.submit.name'" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
          <section v-if='scope3Categories'>
          </section>
          <section v-if='netZeroCompleted'>
            <div class='row d-print-none'>
              <div class='col-md-6 d-print-block'>
                <div class='netzero-label' style='width: 50%; margin: auto;'>
                  <p>{{ netzeroPiechartBasisTitle }}</p>
                  <h4>{{ netzeroPiechartBasisValue }}</h4>
                </div>
              </div>
              <div class='col-md-2 '>

              </div>
              <div class='col-md-4 d-print-block'>
                <div class='netzero-label'>
                  <p>{{ netzeroPiechartTargetTitle }}</p>
                  <h4>{{ netzeroPiechartTargetValue }}</h4>
                </div>
              </div>
            </div>
            <!-- For pdf print START -->
            <div class='row d-print-only '>
              <div class='col-md-12'>
              <h4>{{ netzeroPiechartBasisTitle }}: {{ netzeroPiechartBasisValue }}</h4>
              <h4>{{ netzeroPiechartTargetTitle }}: {{ netzeroPiechartTargetValue }}</h4>
              </div>
            </div>
            <!-- For pdf print END -->
            <div class='row d-print-none' id='reduction-target-net-zero-result-section' style="display: flex; justify-content: center; align-items: center;">
              <div class='col-md-6 '>
                <apexchart ref='netzeroPie' type='pie' :options='netZeroPieOptions' :series='netzeroPieSeriesBasis'></apexchart>

              </div>
              <div class='col-md-2 netzero'>
                <div class='line-container'>
                  <div class='text'>
                    <span class='netzero-percentage'>
                      {{ prettyNetzeroTargetPct }}%
                    </span>
                    <p class="font-weight-bold-no-margin-top">
                     {{ netzeroLineTitle }}
                    </p>
                  </div>
                  <div class='line'></div>
                </div>
              </div>
              <div class='col-md-4 netzerocompare' style="display: flex; flex-direction: column; align-items: center;" >
                <NetZeroPieChart :adjustedWidth='netzeroPieTargetSize'></NetZeroPieChart>
              </div>
            </div>
            <!-- For pdf print START -->
            <div class='row d-print-only'>
                <apexchart class='d-print-inline-block' ref='netzeroPie' width='300px' type='pie' :options='netzeroPieOptionsBasis' :series='netzeroPieSeriesBasis'></apexchart>
                <apexchart class='d-print-inline-block' type='pie' :width='netzeroPieTargetSize/1.5' :options='netzeroPieOptionsTarget'
                           :series='netzeroPieSeriesTarget'></apexchart>
            </div>
            <div class='row d-print-only'>
              <div class='line-container d-print-block'>
                <div class='text'>
                    <span class='netzero-percentage'>
                      {{ prettyNetzeroTargetPct }}%
                    </span>
                  <p class="font-weight-bold-no-margin-top">
                    {{ netzeroLineTitle }}
                  </p>
                </div>
                <div class='line'></div>
              </div>
            </div>
            <!-- For pdf print END -->
          </section>
        </div>
        <div class='col-md-4' id="net-zero-reduction-good-to-know">
          <RichText text-key='forecast.reduction-target.netzero.good-to-know' />
        </div>
      </div>
    </section>
    <section>
      <a v-if='selectedForecastId' @click='backToForecast()' class='button button-primary' >
        <svg class='icon-svg' focusable='false' aria-hidden='true'>
          <use xlink:href='#arrow-left'></use>
        </svg>
        <SimpleText text-key='forecast.reduction-target.back' />
      </a>
      <router-link v-else class='button button-primary' to='/forecast/forecast-overview'>
        <svg class='icon-svg' focusable='false' aria-hidden='true'>
          <use xlink:href='#arrow-left'></use>
        </svg>
        <SimpleText text-key='forecast.reduction-target.back' />
      </router-link>
    </section>
  </div>
</template>

<script lang='ts'>
import { Options } from 'vue-class-component'
import Navigation from '@/components/thirdparty/Navigation.vue'
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { FormValidation } from '@/shared/FormValidation'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import {
  CategoryInfo, CategoryResult,
  ForecastInfo,
  ForecastResults,
  GroupingCalculatedResults,
  ReductionByCategory,
  ReductionInfo,
  ReductionInfoMethodEnum,
  ReductionInfoScopeTypeEnum,
  ReductionResult,
  ReductionResultByCategory,
  ReductionResults,
  SelectedCategory,
  SelectedCategoryCodeEnum
} from '../../../openapi/generated-clients/climatecompass'
import { Prop } from 'vue-property-decorator'
import DKFDS from 'dkfds'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import SimpleText from '@/components/SimpleText.vue'
import RichText from '@/components/RichText.vue'
import Tooltip from '@/components/Tooltip.vue'
import ContentfulContent from '@/shared/ContentfulContent'
import { forecastClient, reductionTargetClient, configurationClient } from '@/shared/BackendService'
import { graphPrettify, prettify, unprettify } from '@/shared/PrettifyNumbers'
import { AxiosResponse } from 'axios'
import CypressTestElement from '@/components/testhelpers/CypressTestElement.vue'
import { downloadFile } from '@/shared/FileHelper'
import ForecastColumnChart from '@/components/forecast/ForecastColumnChart.vue'
import { Constants } from '@/shared/Constants'
import { useRouter } from 'vue-router'
import VueApexCharts, { VueApexChartsComponent } from 'vue3-apexcharts'
import AccordionComponent from '@/components/AccordionComponent.vue'
import NetZeroPieChart from '@/components/forecast/NetZeroComparePieChart.vue'
import Multiselect from '@vueform/multiselect'
import Scope3CategoryReductionChart from '@/components/forecast/Scope3CategoryReductionChart.vue'
import ComparisonChartScope from '@/components/comparison/ComparisonChartScope.vue'

class CategoriesForSelect {
  categoryId: string
  categoryPresentationName: string

  constructor(categoryId: string, categoryPresentationName: string) {
    this.categoryId = categoryId
    this.categoryPresentationName = categoryPresentationName
  }
}

class CategoriesForSelectWithLabels {
  label: string
  options: CategoriesForSelect[]

  constructor(_label: string, _options: CategoriesForSelect[]) {
    this.label = _label
    this.options = _options
  }
}

@Options({
  computed: {
    ReductionInfoMethodEnum() {
      return ReductionInfoMethodEnum
    },
    ReductionInfoScopeTypeEnum() {
      return ReductionInfoScopeTypeEnum
    },
    multiselectOptions() {
      const upstreamOptions = this.possibleUpstreamCategories.length > 0
          ? [{ label: 'Alle opstrømskatagorier', options: this.uniqueOptions(this.possibleUpstreamCategories) }]
          : [];
      const downstreamOptions = this.possibleDownstreamCategories.length > 0
          ? [{ label: 'Alle nedstrømskatagorier', options: this.uniqueOptions(this.possibleDownstreamCategories) }]
          : [];
      //console.log('Upstream Options:', upstreamOptions)
      //console.log('Downstream Options:', downstreamOptions)
      return [...upstreamOptions, ...downstreamOptions]
    },

    watch: {
      possibleUpstreamCategories(newVal: any) {
        console.log('Updated Upstream Categories:', newVal);
      },
      possibleDownstreamCategories(newVal: any) {
        console.log('Updated Downstream Categories:', newVal);
      }
    }

  },
  components: {
    ComparisonChartScope,
    NetZeroPieChart,
    AccordionComponent,
    ForecastColumnChart,
    CypressTestElement,
    RichText,
    SimpleText,
    Tooltip,
    Navigation,
    Multiselect,
    SharedHeader,
    Scope3CategoryReductionChart,
    apexchart: VueApexCharts
  }
})
export default class ReductionTarget extends BaseComponent {
  /** mode can be 'edit' or 'new' or 'print' */
  @Prop()
  mode = 'new'
  @Prop()
  forecastId = 0

  router = useRouter()

  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent
  endYear = '2030'

  selectedForecastId = ''
  selectedScope12ReductionTargetId = ''
  selectedScope3ReductionTargetId = ''
  selectedNetzeroReductionTargetId = ''

  possibleScope12Years: number[] = []
  possibleScope3Years: number[] = []
  possibleNetzeroYears: number[] = []
  forecastName = ''
  outsideScope3 = this.getContentfulString('forecast.reduction.scope3.name-outside-scope')
  scope12TargetPct = '0'
  scope3TargetPct = '0'
  scope3BasisYearPct = '0'
  netzeroTargetPct = '90'
  savedNetzeroTargetPct = '90'
  typeMethodScope12 = ReductionInfoMethodEnum.Manual
  savedTypeMethodScope12 = this.typeMethodScope12
  typeMethodScope3 = ReductionInfoMethodEnum.Manual
  savedTypeMethodScope3 = this.typeMethodScope3
  typeMethodNetzero = ReductionInfoMethodEnum.Manual
  selectedScope12Year = ''
  selectedScope3Year = ''
  selectedNetzeroYear = '2050'

  enumManual = ReductionInfoMethodEnum.Manual
  enumSbti = ReductionInfoMethodEnum.Sbti

  chooseForecastError = ''
  scope12TargetPctError = ''
  scope3TargetPctError = ''
  netzeroTargetPctError = ''
  netzeroYearError = ''
  forecastNameError = ''
  netzeroScope1: number | undefined = 0
  netzeroScope2: number | undefined = 0
  netzeroScope3: number | undefined = 0
  scopesPieChartRadius: number | null = null;

  replaceWithCustomTag = false
  customTagTitleUpstreams = 'Alle Opstrømsudledninger'
  customTagTitleDownstreams = 'Alle Nedstrømsudledninger'

  formValidation = new FormValidation()
  formValidationScope12 = new FormValidation()
  formValidationScope3 = new FormValidation()
  formValidationNetzero = new FormValidation()

  editExistingForecast = false
  editScope12 = false
  editScope3 = false
  editNetzero = false
  reductionResults: ReductionResults = {}


  scope12Completed = false
  scope3Completed = false
  netZeroCompleted = false
  scope3Categories = true
  showScope3Categories = false

  $refs!: {
    formGroup: HTMLFormElement
    scope12chart: ForecastColumnChart
    scope3chart: ForecastColumnChart
    scope3Pie: VueApexChartsComponent
    netzeroPie: VueApexChartsComponent
    netzeroComparePie: NetZeroPieChart
    scope3CategoryChartRef: Scope3CategoryReductionChart
  }

  scope12ChartCalculations?: GroupingCalculatedResults[]
  scope12ChartTarget?: GroupingCalculatedResults
  scope3ChartCalculations?: GroupingCalculatedResults[]
  scope3ChartTarget?: GroupingCalculatedResults

  possibleCategories: CategoriesForSelect[] = []
  possibleUpstreamCategories: CategoriesForSelect[] = []
  possibleDownstreamCategories: CategoriesForSelect[] = []
  selectedCategories: string[] = []
  scope3ChartCategory: ReductionResultByCategory = {}

  async beforeMount() {
    await configurationClient
      .showScope3Categories()
      .then((response) => {
        this.showScope3Categories = response.data as unknown as boolean
      })
      .catch((error) => {
        console.error(error)
      })
    this.eventHub.emit('start-loading', 'ReductionTarget')
    await this.store.dispatch('loadForecasts').finally(() => {
      this.eventHub.emit('stop-loading', 'ReductionTarget')
    })
    this.chooseForecastError = this.contentfulContent.findSimpleText('forecast.reduction-target.field.choose-calculation.error')
    this.scope12TargetPctError = this.contentfulContent.findSimpleText('forecast.reduction-target.field.target-pct.error')
    this.scope3TargetPctError = this.contentfulContent.findSimpleText('forecast.reduction-target.field.target-pct.error')
    this.netzeroTargetPctError = this.contentfulContent.findSimpleText('forecast.reduction-target.field.target-pct.error')
    this.netzeroYearError = this.contentfulContent.findSimpleText('forecast.reduction-target.field.target-year.error')

    if (this.forecastId > 0) {
      this.selectedForecastId = ''+this.forecastId
    }

    if (this.route.query.forecastId) {
      const forecastId = Number(this.route.query.forecastId)
      this.selectedForecastId = String(forecastId)
      await forecastClient.getForecast(forecastId,
        this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null').then(async (result: AxiosResponse<ForecastResults>) => {
        if (result.data && result.data.forcastHeader) {
          await this.store.dispatch('setCurrentForecastInfo', result.data)
        }
      })
      this.editExistingForecast = true
      await this.setupReductionInfoResult(forecastId)
    } else {
      this.editExistingForecast = this.mode === 'edit'
      await this.setupReductionInfoResult(this.forecastId)
    }
    this.scrollToTargetWithOffset('reduction-target-scope-12-section', 210)
  }

  handleDownloadPdf() {
    window.print()
  }

  mounted() {
    DKFDS.init()
    if(this.$refs.netzeroComparePie)
    this.$refs.netzeroComparePie.getChart()
  }

  updated() {
    DKFDS.init()
  }

  showAndSaveForecast() {
    const selCatArray = this.populateSelectedCategories(this.selectedCategories)
    const possibleCatArray = this.populatePossibleCategories(this.possibleCategories)
    let thirdpartyVatInfo = this.store.getters.getThirdpartyInfo
    let thirdpartyVatNo = 'null'
    if (thirdpartyVatInfo) {
      thirdpartyVatNo = thirdpartyVatInfo.behalfOfVatNr
    }

    const inputdata : ReductionByCategory = {
      id: Number(this.selectedScope3ReductionTargetId),
      thirdPartyVatNo: thirdpartyVatNo,
      selectedCategories: selCatArray,
      possibleCategories: possibleCatArray
    }
    //console.log("reductionTargetClient.updateReductionTargetByCategory", inputdata)
    reductionTargetClient.updateReductionTargetByCategory(inputdata)
      .then((result: AxiosResponse<ReductionResultByCategory>) => {
        //console.log("updateReductionTargetByCategory", result.data, "Exist", this.scope3ChartTarget);
        this.scope3ChartCategory = result.data
        const targetLastYear = this.method3IsSBTi ? this.scope3ChartTarget?.ghgCalculatedResult?.scope3ResultReduction?? 0
          : this.scope3ChartTarget?.ghgCalculatedResult?.scope3Result?? 0
        const allCatSelected = selCatArray.length === 0 || selCatArray.length === possibleCatArray.length
        this.$refs.scope3CategoryChartRef.updateChart(this.scope3ChartCategory, selCatArray, targetLastYear,
          allCatSelected, this.method3IsSBTi)
        this.$refs.scope3chart.updateReductionTargetByCategoryChart(selCatArray, allCatSelected, this.method3IsSBTi)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  populateSelectedCategories(categories: string[]): SelectedCategory[] {
    const catArray = [] as SelectedCategory[];
    const seen = new Set();

    categories.forEach((result: string) => {
      let cd = this.getCatEnum(result);
      // Use code or another unique identifier to prevent duplicates
      if (!seen.has(cd)) {
        seen.add(cd);
        const selCat: SelectedCategory = {
          code: cd
        };
        catArray.push(selCat);
      }
    });

    return catArray;
  }

  populatePossibleCategories(categories: CategoriesForSelect[]): SelectedCategory[] {
    const seen = new Set<SelectedCategoryCodeEnum>(); // Use Set to track seen category codes
    const catArray: SelectedCategory[] = [];

    categories.forEach((result: CategoriesForSelect) => {
      const cd = this.getCatEnum(result.categoryId);
      // Only add to catArray if cd has not been seen before
      if (!seen.has(cd)) {
        seen.add(cd);
        const selCat: SelectedCategory = {
          code: cd
        };
        catArray.push(selCat);
      }
    });

    return catArray;
  }

  uniqueOptions(categories: CategoriesForSelect[]): CategoriesForSelect[] {
    const seen = new Set<string>(); // Track seen category IDs
    return categories.filter(category => {
      if (!seen.has(category.categoryId)) {
        seen.add(category.categoryId);
        return true;
      }
      return false;
    });
  }


  getCatEnum(input: string): SelectedCategoryCodeEnum {
    switch (input) {
      case 'CATEGORY_1': return SelectedCategoryCodeEnum._1;
      case 'CATEGORY_2': return SelectedCategoryCodeEnum._2;
      case 'CATEGORY_3': return SelectedCategoryCodeEnum._3;
      case 'CATEGORY_4': return SelectedCategoryCodeEnum._4;
      case 'CATEGORY_5': return SelectedCategoryCodeEnum._5;
      case 'CATEGORY_6': return SelectedCategoryCodeEnum._6;
      case 'CATEGORY_7': return SelectedCategoryCodeEnum._7;
      case 'CATEGORY_8': return SelectedCategoryCodeEnum._8;
      case 'CATEGORY_9': return SelectedCategoryCodeEnum._9;
      case 'CATEGORY_10': return SelectedCategoryCodeEnum._10;
      case 'CATEGORY_11': return SelectedCategoryCodeEnum._11;
      case 'CATEGORY_12': return SelectedCategoryCodeEnum._12;
      case 'CATEGORY_13': return SelectedCategoryCodeEnum._13;
      case 'CATEGORY_14': return SelectedCategoryCodeEnum._14;
      case 'CATEGORY_15': return SelectedCategoryCodeEnum._15;
      default: return SelectedCategoryCodeEnum._1; // Default value if input does not match
    }
  }

  async backToForecast() {
    await this.router.push('calculate-forecast?mode=edit&forecastId=' + this.currentForecast?.forecastId)
    /* works local but not on slow dev
    await this.store.dispatch("setCurrentForecastInfo", this.currentForecast).then(async () => {
      await this.router.push({name: 'CalculateForecast', params: {mode: 'edit' }})
    })*/

  }
  calculateLastYearScope3Percentage() {
    const totalScope123LastYear = (this.reductionResults.scope1LastYear?? 0) + (this.reductionResults.scope2LastYear ?? 0) + (this.reductionResults.scope3LastYear?? 0)
    const scope3LastYear = this.reductionResults.scope3LastYear?? 0

    return prettify((scope3LastYear / totalScope123LastYear * 100).toFixed(1))
  }

calculateBasisYearScope3Percentage(reductionResult : ReductionResult) {
    if(reductionResult && reductionResult.calculations && reductionResult.calculations[0] ) {
      const scope3BasisYearValue =  reductionResult.calculations[0].ghgCalculatedResult?.scope3Result ?? 0
      const scope3BasisYearTotalValue = reductionResult.calculations[0].scoresTotalResult ?? 0

      const scope3BasisYearPct = prettify((scope3BasisYearValue * 100 / scope3BasisYearTotalValue), 1)
      this.scope3BasisYearPct = scope3BasisYearPct ?? '0'
    }
  }

  validateScopeTargetPctInputs() {
  if(this.scope3TargetPct === '') {
    this.scope3TargetPct = '0'
  } else if (this.scope12TargetPct === '') {
    this.scope12TargetPct = '0'
  } else if( this.netzeroTargetPct === '') {
    this.netzeroTargetPct = '90'
    }
  }

  get possibleForecasts(): ForecastInfo[] {
    return this.store.getters.getForecasts ?? []
  }

  get netzeroPiechartBasisTitle() {
    const rt: string = this.getContentfulString('forecast.reduction-target.netzero.piechart.basis.title')
    return rt.replace('#YEAR#', this.netzeroYearBasis + '')
  }

  get netzeroPiechartTargetTitle() {
    const rt: string = this.getContentfulString('forecast.reduction-target.netzero.piechart.target.title')
    return rt.replace('#YEAR#', this.netzeroYearTarget + '')
  }

  get netzeroPiechartBasisValue() {
    // TODO variable must be part of contentful string else problems when translating
    const st: string = this.getContentfulString('forecast.reduction-target.netzero.piechart.basis.value')
    return `${this.netzeroBasis} ${st}`
  }

  get netzeroLineTitle() {
    return this.getContentfulString('forecast.reduction-target.netzero.line.title')
  }

  get netzeroPiechartTargetValue() {
    // TODO variable must be part of contentful string else problems when translating
    const st: string = this.getContentfulString('forecast.reduction-target.netzero.piechart.target.value')
    return `${this.netzeroTarget} ${st}`
  }

  get sortedForecasts() {
    return [...(this.possibleForecasts ?? [])]
        .sort((a, b) => {
          if (!a.name || !b.name) return 0;
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
  }

  get isScope12Improve() {
    if (this.savedTypeMethodScope12 === ReductionInfoMethodEnum.Manual && this.er12 >= 0
        || this.savedTypeMethodScope12 === ReductionInfoMethodEnum.Sbti && this.sr12 >= 0) {
      return true
    } else {
      return false
    }
  }

  get isScope3Improve() {
    if (this.savedTypeMethodScope3 === ReductionInfoMethodEnum.Manual) {
      if (this.er3 >= 0) {
        return true
      } else {
        return false
      }
    }
    if (this.savedTypeMethodScope3 === ReductionInfoMethodEnum.Sbti) {
      if (this.sr3 >= 0) {
        return true
      } else {
        return false
      }
    }
    return false
  }

  get scope12InfoTextSuccess() {
    if(this.savedTypeMethodScope12 === ReductionInfoMethodEnum.Manual) {
      const prettyEm12 = prettify(this.em12)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope12.infotext-success.manual', true)
      return rt
          .replace('#EM12#', prettyEm12)
          .replace('#YEAR#', this.scope12Year + '')
    } else {
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope12.infotext-success.sbti', true)
      return rt
          .replace('#SM12#', this.sm12 + '')
          .replace('#YEAR#', this.scope12Year + '')
    }
  }


  get scope12InfoTextImprove() {
    if(this.savedTypeMethodScope12 === ReductionInfoMethodEnum.Manual) {
      const prettyEr12 = prettify(this.er12, 1)?.toString() ?? '0'
      const prettyEm12 = prettify(this.em12)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope12.infotext-improve.manual', true)
      return rt
          .replace('#EM12#', prettyEm12)
          .replace('#YEAR#', this.scope12Year + '')
          .replace('#ER12#', prettyEr12)
    } else {
      const prettySm12 = prettify(this.sm12, 1)?.toString() ?? '0'
      const prettySr12 = prettify(this.sr12, 1)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope12.infotext-improve.sbti', true)
      return rt
          .replace('#SM12#', prettySm12)
          .replace('#YEAR#', this.scope12Year + '')
          .replace('#SR12#', prettySr12)
    }
  }

  get scope12InfoTextBackground() {
    return this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope12.infotext-background', true)
  }

  get scope3InfoTextSuccess() {
    if (this.savedTypeMethodScope3 === ReductionInfoMethodEnum.Manual) {
      const prettyEm3 = prettify(this.em3)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope3.infotext-success', true)
      return rt.replace('#EM3#', prettyEm3).replace('#YEAR#', this.scope3Year + '')
    } else {
      const prettySr3 = prettify(this.sr3, 1)?.toString() ?? '0'
      const prettySm3 = prettify(this.scope3BasisYearPct)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope3.infotext-success.sbti', true)
      return rt
          .replace('#SM3#', prettySm3)
          .replace('#YEAR#', this.scope3Year + '')
          .replace('#SR3#', prettySr3)
    }
  }

  get scope3InfoTextImprove() {
    if (this.savedTypeMethodScope3 === ReductionInfoMethodEnum.Manual) {
      const prettyEm3 = prettify(this.em3)?.toString() ?? '0'
      const prettyEr3 = prettify(this.er3, 1)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope3.infotext-improve', true)
      return rt
          .replace('#EM3#', prettyEm3)
          .replace('#YEAR#', this.scope3Year + '')
          .replace('#ER3#', prettyEr3)
    } else {
      const prettySm3 = prettify(this.scope3BasisYearPct, 1)?.toString() ?? '0'
      const prettySr3 = prettify(this.sr3, 1)?.toString() ?? '0'
      const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope3.infotext-improve.sbti', true)
      return rt
          .replace('#SM3#', prettySm3)
          .replace('#YEAR#', this.scope3Year + '')
          .replace('#SR3#', prettySr3)
    }
  }

  get scope3InfoTextBackground() {
    const rt: string = this.store.getters.getContentfulContent.renderRichText('forecast.reduction-target.scope3.infotext-background', true)
    return rt
    .replace('#SCOPE3PERCENTAGE#',  this.calculateLastYearScope3Percentage() + '')
  }

  get scope3CategoryInfoTextOne() {
    let textKey = '';
    let rS = '';

    if (this.method3IsSBTi && this.percentageScope3CategoriesSelected >= 67) {
      textKey = 'forecast.reduction-target.scope3-categories.maec.success';
    } else if (this.method3IsSBTi && this.percentageScope3CategoriesSelected < 67) {
      textKey = 'forecast.reduction-target.scope3-categories.maec.warning';
    } else if (this.method3IsManual && this.percentageScope3CategoriesSelected >= 67) {
      textKey = 'forecast.reduction-target.scope3-categories.manual.success';
    } else if (this.method3IsManual && this.percentageScope3CategoriesSelected < 67) {
      textKey = 'forecast.reduction-target.scope3-categories.manual.warning';
    }
    rS= this.store.getters.getContentfulContent.renderRichText(textKey, true)
    //handle injected RichText p's, spans and i's, if any:
    rS = rS.replaceAll('<p>', '').replaceAll('</p>', '')
    rS = rS.replaceAll('<span>', '').replaceAll('</span>', '')
    rS = rS.replaceAll('<i>', '').replaceAll('</i>', '')

    return rS
  }

  get scope3CategoryInfoTextTwo() {
    let textKey = '';
    let rS = '';

    if (this.method3IsSBTi && this.percentageScope3CategoriesSelected >= 67) {
      textKey = 'forecast.reduction-target.scope3-categories.maec.success2';
    } else if (this.method3IsSBTi && this.percentageScope3CategoriesSelected < 67) {
      textKey = 'forecast.reduction-target.scope3-categories.maec.warning2';
    } else if (this.method3IsManual && this.percentageScope3CategoriesSelected >= 67) {
      textKey = 'forecast.reduction-target.scope3-categories.manual.success2';
    } else if (this.method3IsManual && this.percentageScope3CategoriesSelected < 67) {
      textKey = 'forecast.reduction-target.scope3-categories.manual.warning2';
    }

    rS = this.store.getters.getContentfulContent.renderRichText(textKey, true);
    //handle injected RichText p's, spans and i's, if any:
    rS = rS.replaceAll('<p>', '').replaceAll('</p>', '');
    rS = rS.replaceAll('<span>', '').replaceAll('</span>', '');
    rS = rS.replaceAll('<i>', '').replaceAll('</i>', '')

    return rS;
  }


  get reductionIsCalculated(): boolean {
    return (this.reductionResults.targetYearsScope12?.length ?? 0) > 0
  }

  get scope12InputDisabled() {
    return this.possibleScope12Years.length === 0
  }

  get scope3InputDisabled() {
    return this.possibleScope3Years.length === 0
  }

  get netzeroInputDisabled() {
    return this.possibleNetzeroYears.length === 0
  }

  get scope12TargetPctWrap(): string {
    if (this.scope12InputDisabled && !this.method12IsSBTi) {
      return '0'
    } else if (this.method12IsSBTi) {
      const forecast: ForecastInfo = this.currentForecast
      const diffYear = 2030 - (forecast?.sourceCalculationYear ?? 2020)
      const result = Math.max(diffYear * 4.2, 42)
      return String(prettify(result, Number.isInteger(result) ? 0 : 1))
    } else {
      return this.scope12TargetPct
    }
  }

  set scope12TargetPctWrap(pct: string) {
    this.scope12TargetPct = pct
  }

  get scope3TargetPctWrap(): string {
    if (this.scope3InputDisabled && !this.method3IsSBTi) {
      return '0'
    } else if (this.method3IsSBTi) {
      const forecast: ForecastInfo = this.currentForecast
      const diffYear = 2030 - (forecast?.sourceCalculationYear ?? 2020)
      const result = Math.max(diffYear * 2.5, 25)
      return String(prettify(result, Number.isInteger(result) ? 0 : 1))
    } else {
      return this.scope3TargetPct
    }
  }

  set scope3TargetPctWrap(pct: string) {
    this.scope3TargetPct = pct
  }

  get method12IsSBTi(): boolean {
    const res = this.typeMethodScope12 === ReductionInfoMethodEnum.Sbti
    if (res) {
      this.selectedScope12Year = this.endYear
    }
    return res
  }

  get method3IsSBTi(): boolean {
    const res = this.typeMethodScope3 === ReductionInfoMethodEnum.Sbti
    if (res) {
      this.selectedScope3Year = this.endYear
    }
    return res
  }

  get method3IsManual(): boolean {
    const res = this.typeMethodScope3 === ReductionInfoMethodEnum.Manual
    return res
  }

  er12 = 0
  sr12 = 0
  em12 = 0
  sm12 = 0
  scope12Year = 0
  scope3Year = 0

  er3 = 0
  sr3 = 0
  em3 = 0
  sm3 = 0

  netzeroYearBasis = ''
  netzeroYearTarget = ''
  netzeroBasis = ''
  netzeroTarget = ''
  netzeroPieSeriesTarget = [100]

  get netzeroPieSeriesBasis() {
    return [this.netzeroScope1, this.netzeroScope2, this.netzeroScope3]
  }



  get scope3PieSeries() {
    if (this.reductionResults) {
      return [this.reductionResults.scope1LastYear, this.reductionResults.scope2LastYear, this.reductionResults.scope3LastYear]
    } else {
      return [0, 0, 0]
    }

  }

  get netZeroPieOptions() {
    const total = (this.netzeroScope1 ?? 0) + (this.netzeroScope2 ?? 0) + (this.netzeroScope3 ?? 0)
    let scope1Percent: string | undefined
    let scope2Percent: string | undefined
    let scope3Percent: string | undefined

    if(this.netzeroScope1 != 0) {
      scope1Percent = graphPrettify((this.netzeroScope1 ?? 0) / total * 100)
    } else {
      scope1Percent = graphPrettify(this.netzeroScope1)
    }

    if(this.netzeroScope2 != 0) {
      scope2Percent = graphPrettify((this.netzeroScope2 ?? 0) / total * 100)
    } else {
      scope2Percent = graphPrettify(this.netzeroScope2)
    }

    if(this.netzeroScope3 != 0) {
      scope3Percent = graphPrettify((this.netzeroScope3 ?? 0) / total * 100)
    } else {
      scope3Percent = graphPrettify(this.netzeroScope3)
    }

    return {
      chart: {
        fontFamily: 'IBM Plex Sans, system',
        width: '100%',
        type: 'pie'
      },
      labels: [`Scope 1 (${scope1Percent}%)`, `Scope 2 (${scope2Percent}%)`, `Scope 3 (${scope3Percent}%)`],
      colors: [Constants.SCOPE1_COLOR_HEX, Constants.SCOPE2_COLOR_HEX, Constants.SCOPE3_COLOR_HEX],
      legend: {
        position: 'bottom',
        markers: {
          radius: 2
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      tooltip: {
        y: {
          formatter: (value: number) => {
            return graphPrettify(value)
          }
        }
      }
    }
  }

  netzeroPieOptionsBasis = {
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      width: '100%',
      type: 'pie'
    },
    labels: ['Scope 1', 'Scope 2', 'Scope 3'],
    colors: [Constants.SCOPE1_COLOR_HEX, Constants.SCOPE2_COLOR_HEX, Constants.SCOPE3_COLOR_HEX],
    legend: {
      show: true,
      position: 'bottom',
      markers: {
        radius: 2
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: false
    },
    tooltip: {
      y: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    }
  }

  get scope3PieOptions() {
    const total = (this.reductionResults.scope1LastYear ?? 0) + (this.reductionResults.scope2LastYear ?? 0) + (this.reductionResults.scope3LastYear ?? 0)

    const scope1Percent = graphPrettify((this.reductionResults.scope1LastYear ?? 0) / total * 100)
    const scope2Percent = graphPrettify((this.reductionResults.scope2LastYear ?? 0) / total * 100)
    const scope3Percent = graphPrettify((this.reductionResults.scope3LastYear ?? 0) / total * 100)


    return {
      chart: {
        fontFamily: 'IBM Plex Sans, system',
        width: '100%',
        type: 'pie'
      },
      labels: [`Scope 1 (${scope1Percent}%)`, `Scope 2 (${scope2Percent}%)`, `Scope 3 (${scope3Percent}%)`],
      colors: [Constants.SCOPE1_COLOR_HEX, Constants.SCOPE2_COLOR_HEX, Constants.SCOPE3_COLOR_HEX],
      legend: {
        position: 'bottom',
        markers: {
          radius: 2
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      tooltip: {
        y: {
          formatter: (value: number) => {
            return graphPrettify(value)
          }
        }
      }
    }
  }

  get prettyNetzeroTargetPct() {
      return prettify(this.savedNetzeroTargetPct)
  }

  get netzeroPieTargetSize() {
    const sizePie = (2 * Math.sqrt((100 - Number(this.savedNetzeroTargetPct)) / Math.PI)) * 8.8622;
    // Determine the size at which to stop shrinking.
    const stopShrinkSize = 20;

    // If the size goes below the stop shrink size, set it to the stop shrink size; otherwise, use the calculated size
    const clampedSize = sizePie < stopShrinkSize ? stopShrinkSize : sizePie;

    // Convert the clamped size to pixels based on a container width of 320px
    return 320 * (clampedSize / 100);
  }

  netzeroPieOptionsTarget = {
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      width: '100%',
      type: 'pie'
    },
    tooltip: {
      enabled: false
    },
    labels: ['Samlet'],
    colors: ['grey'],
    legend: {
      show: false
    },
    stroke: {
      show: false
    },
    dataLabels: {
      enabled: false
    }
  }

  getMethod(reductionType: ReductionInfoScopeTypeEnum) {
    if (this.reductionResults.reductions) {
      for (const item of this.reductionResults.reductions) {
        if (item.reductionInfo?.scopeType == reductionType) {
          return item.reductionInfo.method
        }
      }
    }
    return null
  }

  get currentForecast() {
    return (this.possibleForecasts ?? []).find(x => x.forecastId === Number(this.selectedForecastId)) ?? this.store.getters.getCurrentForecastInfo;
  }


  private async setupReductionInfoResult(forecastId: number) {
    if (this.editExistingForecast) {
      const existingOrPartialForecastId = forecastId ?? this.store.getters.getCurrentForecastInfo.forecastId
      if (existingOrPartialForecastId) {
        this.selectedForecastId = String(existingOrPartialForecastId)
        await this.getReductionInfo(existingOrPartialForecastId)
        const existingForecast =  (this.possibleForecasts ?? []).find(x => x.forecastId === Number(existingOrPartialForecastId))
        if(existingForecast) {
          this.forecastName = existingForecast.name ?? ''
        }
      }
    } else {
      this.resetForm()
    }
  }

  private async updateScopeEndYearTotalsForScope3PieChart() {
    await reductionTargetClient
      .getReductionTargets(
        Number(this.selectedForecastId),
        this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null'
      )
      .then((result: AxiosResponse<ReductionResults>) => {
        this.reductionResults.scope1LastYear = result.data.scope1LastYear
        this.reductionResults.scope2LastYear = result.data.scope2LastYear
        this.reductionResults.scope3LastYear = result.data.scope3LastYear
      })
      .catch((error) => {
        this.reductionTargetError()
      })
  }

  private async getReductionInfo(forecastId: number | undefined = undefined) {
    this.resetForm()
    await reductionTargetClient
      .getReductionTargets(
        forecastId ? forecastId : +this.selectedForecastId,
        this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null'
      )
      .then((result: AxiosResponse<ReductionResults>) => {
        this.populateResults(result.data)
      })
      .catch((error) => {
        this.reductionTargetError()
      })
  }

  private async loadReductionTarget(reductionTargetId: number) {
    await reductionTargetClient
      .getReductionTarget(reductionTargetId, this.thirdpartyInfo && this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null')
      .then((result: AxiosResponse<ReductionResult>) => {
        this.populateResult(result.data)
      })
      .catch((error) => {
        this.reductionTargetError()
      })
  }

  populateResult(result: ReductionResult) {
    if (result.calculations) {
      this.calculateBasisYearScope3Percentage(result)
      //Order calculations by year
      result.calculations = result.calculations.sort((a, b) => (a.year ?? 0) - (b.year ?? 0))
      this.forecastName = String(result.reductionInfo?.forecastName)
      if (result.reductionInfo?.scopeType === ReductionInfoScopeTypeEnum.Scope12) {
        this.populateScope12(result)
      } else if (result.reductionInfo?.scopeType === ReductionInfoScopeTypeEnum.Scope3) {
        this.populateScope3(result)
      } else if (result.reductionInfo?.scopeType === ReductionInfoScopeTypeEnum.Netzero) {
        this.populateNetzero(result)
      }

      if(this.scope3Completed) {
        if(!this.reductionResults.scope1LastYear) {
          this.updateScopeEndYearTotalsForScope3PieChart()
        }
      }
    } else {
      console.log('!! Result got no Calculations')
    }
  }

  async populateScope12(reductionResult: ReductionResult) {
    if (reductionResult && reductionResult.reductionInfo) {
      this.er12 = reductionResult.reductionInfo.reductionOwn ?? 0
      this.sr12 = reductionResult.reductionInfo.reductionStandard ?? 0
      this.em12 = reductionResult.reductionInfo.targetPctInput ?? 0
      this.sm12 = Number(reductionResult.reductionInfo.targetPctOutput?.toFixed(1) ?? 0)
      this.scope12Year = reductionResult.reductionInfo.year ?? 0

      this.editScope12 = true

      this.typeMethodScope12 = String(reductionResult.reductionInfo.method ?? ReductionInfoMethodEnum.Manual) as ReductionInfoMethodEnum
      this.savedTypeMethodScope12 = String(reductionResult.reductionInfo.method ?? ReductionInfoMethodEnum.Manual) as ReductionInfoMethodEnum
      this.selectedScope12Year = String(reductionResult.reductionInfo.year)
      this.scope12TargetPct = String(prettify(reductionResult.reductionInfo.targetPctInput))
      this.selectedScope12ReductionTargetId = String(reductionResult.reductionInfo.id)

      if (reductionResult.calculations) {
        this.scope12ChartCalculations = reductionResult.calculations.sort((a, b) => (a.year ?? 0) - (b.year ?? 0))
        this.scope12ChartTarget = reductionResult.targetCalculation
        this.scope12Completed = true
        if (this.$refs.scope12chart) {
          await this.$nextTick(() => {
            this.$refs.scope12chart.updateChart(this.scope12ChartSettings)
          })
        }
      }
    }

  }

  populateScope3(reductionResult: ReductionResult) {
    if (reductionResult && reductionResult.reductionInfo) {
      this.er3 = reductionResult.reductionInfo.reductionOwn ?? 0
      this.sr3 = reductionResult.reductionInfo.reductionStandard ?? 0
      this.em3 = reductionResult.reductionInfo.targetPctInput ?? 0
      this.sm3 = Number(reductionResult.reductionInfo.targetPctOutput?.toFixed(1) ?? 0)


      this.scope3Year = reductionResult.reductionInfo.year ?? 0

      this.editScope3 = true

      this.typeMethodScope3 = String(reductionResult.reductionInfo.method ?? ReductionInfoMethodEnum.Manual) as ReductionInfoMethodEnum
      this.savedTypeMethodScope3 = String(reductionResult.reductionInfo.method ?? ReductionInfoMethodEnum.Manual) as ReductionInfoMethodEnum
      this.selectedScope3Year = String(reductionResult.reductionInfo.year)
      this.scope3TargetPct = String(prettify(reductionResult.reductionInfo.targetPctInput))
      this.selectedScope3ReductionTargetId = String(reductionResult.reductionInfo.id)
      this.filterForecastScope3Categories(this.selectedScope3ReductionTargetId)

      if (reductionResult.calculations) {
        this.scope3ChartCalculations = reductionResult.calculations.sort((a, b) => (a.year ?? 0) - (b.year ?? 0))
        this.scope3ChartTarget = reductionResult.targetCalculation
        this.scope3Completed = true
        if (this.$refs.scope3chart) {
          this.$nextTick(() => {
            this.$refs.scope3chart.updateChart(this.scope3ChartSettings)
          })
        }
      }

    }
  }

  populateNetzero(reductionResult: ReductionResult) {
    if (reductionResult && reductionResult.reductionInfo) {
      if (reductionResult.calculations && reductionResult.calculations[0]) {
        this.netzeroYearBasis = String(reductionResult.calculations[0]?.year ?? '-')
        this.netzeroYearTarget = String(reductionResult.reductionInfo.year ?? '-')
        this.netzeroBasis = graphPrettify(reductionResult.calculations[0].ghgCalculatedResult?.totalResult ?? 0, true)?.toString() ?? '0,00'
        this.netzeroTarget = graphPrettify((reductionResult.reductionInfo.targetOutput ?? 0))?.toString() ?? '0,00'   //EMTNZ123
        this.savedNetzeroTargetPct = ''+reductionResult.reductionInfo.targetPctInput

        this.netzeroScope1 = reductionResult.calculations[0].ghgCalculatedResult?.scope1Result
        this.netzeroScope2 = reductionResult.calculations[0].ghgCalculatedResult?.scope2Result
        this.netzeroScope3 = reductionResult.calculations[0].ghgCalculatedResult?.scope3Result
      }
      this.editNetzero = true

      this.typeMethodNetzero = String(reductionResult.reductionInfo.method ?? ReductionInfoMethodEnum.Manual) as ReductionInfoMethodEnum
      this.selectedNetzeroYear = String(reductionResult.reductionInfo.year ?? this.possibleNetzeroYears.sort((a, b) => a - b) [0])
      this.netzeroTargetPct = graphPrettify(reductionResult.reductionInfo.targetPctInput ?? 0)?.toString() ?? '0,00'
      this.selectedNetzeroReductionTargetId = String(reductionResult.reductionInfo.id)

      this.netZeroCompleted = true
    }
  }

  populateResults(result: ReductionResults) {
    if (result && result.reductions) {
      this.reductionResults = result
      if (result.targetYearsScope12) {
        this.possibleScope12Years = result.targetYearsScope12
      }
      if (result.targetYearsScope3) {
        this.possibleScope3Years = result.targetYearsScope3
      }
      if (result.targetYearsNetzero) {
        this.possibleNetzeroYears = result.targetYearsNetzero
      }

      if (result.reductions && result.reductions.length > 0) {
        for (const item of result.reductions) {
          this.populateResult(item)

          if (item.reductionInfo?.id && (this.editExistingForecast)) {
            this.loadReductionTarget(item.reductionInfo?.id)
          }
        }
      }
    }
    if (this.editExistingForecast && this.selectedForecastId) {
      const existingForecast = (this.possibleForecasts ?? []).find(x => x.forecastId === Number(this.selectedForecastId))
      this.forecastName = existingForecast?.name ?? ''
    }
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }


  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset()
    }
    this.selectedScope12ReductionTargetId = ''
    this.selectedScope3ReductionTargetId = ''
    this.selectedNetzeroReductionTargetId = ''
    this.forecastName = ''
    this.typeMethodScope12 = ReductionInfoMethodEnum.Manual
    this.typeMethodScope3 = ReductionInfoMethodEnum.Manual
    this.typeMethodNetzero = ReductionInfoMethodEnum.Manual
    this.selectedScope12Year = this.endYear
    this.selectedScope3Year = this.endYear
    this.selectedNetzeroYear = '2050'
    this.scope12TargetPct = '0'
    this.scope3TargetPct = '0'
    this.netzeroTargetPct = '90'
    this.reductionResults = {}

    this.scope12Completed = false
    this.scope12ChartCalculations = undefined
    this.scope12ChartTarget = undefined
    this.scope3Completed = false
    this.scope3ChartCalculations = undefined
    this.scope3ChartTarget = undefined
    this.netZeroCompleted = false
  }

  get scope12ChartSettings() {
    let setting = 0
    setting += Constants.FORECAST_FILTER_SCOPE1
    setting += Constants.FORECAST_FILTER_SCOPE2
    setting += Constants.FORECAST_FILTER_REDUCTION_TARGET
    return setting
  }

  get scope3ChartSettings() {
    let setting = Constants.FORECAST_FILTER_SCOPE3
    setting += Constants.FORECAST_FILTER_REDUCTION_TARGET
    return setting
  }

  async submitFormForecast(e: Event) {
    await this.getReductionInfo(Number(this.selectedForecastId))
  }

  async save(reductionInfo: ReductionInfo) {
    if (this.editScope12 || this.editScope3 || this.editNetzero) {
      await reductionTargetClient
        .updateReductionTarget(reductionInfo)
        .then((result: AxiosResponse<ReductionResult>) => {
          this.populateResult(result.data)
        })
        .catch((error) => {
          this.reductionTargetError()
        })
    } else {
      await reductionTargetClient
        .createReductionTarget(reductionInfo)
        .then((result: AxiosResponse<ReductionResult>) => {
          this.populateResult(result.data)
        })
        .catch((error) => {
          this.reductionTargetError()
        })
    }
  }

  async submitFormScope12(e: Event) {
    const methodTypeEnumValue: ReductionInfoMethodEnum =
      ReductionInfoMethodEnum[this.getEnumKeyByEnumValue(ReductionInfoMethodEnum, this.typeMethodScope12) as keyof typeof ReductionInfoMethodEnum]

    const reductionInfo: ReductionInfo = {
      forecastId: +this.selectedForecastId,
      year: +this.selectedScope12Year,
      method: methodTypeEnumValue,
      targetPctInput: Number(unprettify(this.scope12TargetPctWrap)),
      scopeType: ReductionInfoScopeTypeEnum.Scope12,
      thirdpartyVatNo: this.thirdpartyInfo.behalfOfVatNr
    }
    if (this.selectedScope12ReductionTargetId != '') {
      reductionInfo.id = +this.selectedScope12ReductionTargetId
    }
    await this.save(reductionInfo)
  }

  async submitFormScope3(e: Event) {
    const methodTypeEnumValue: ReductionInfoMethodEnum =
      ReductionInfoMethodEnum[this.getEnumKeyByEnumValue(ReductionInfoMethodEnum, this.typeMethodScope3) as keyof typeof ReductionInfoMethodEnum]
    const reductionInfo: ReductionInfo = {
      forecastId: +this.selectedForecastId,
      year: +this.selectedScope3Year,
      method: methodTypeEnumValue,
      targetPctInput: Number(unprettify(this.scope3TargetPctWrap)),
      scopeType: ReductionInfoScopeTypeEnum.Scope3,
      thirdpartyVatNo: this.thirdpartyInfo.behalfOfVatNr
    }
    if (this.selectedScope3ReductionTargetId != '') {
      reductionInfo.id = +this.selectedScope3ReductionTargetId
    }
    await this.save(reductionInfo)
  }

  async submitFormNetzero(e: Event) {
    const methodTypeEnumValue: ReductionInfoMethodEnum =
      ReductionInfoMethodEnum[this.getEnumKeyByEnumValue(ReductionInfoMethodEnum, this.typeMethodNetzero) as keyof typeof ReductionInfoMethodEnum]
    const reductionInfo: ReductionInfo = {
      forecastId: +this.selectedForecastId,
      year: +this.selectedNetzeroYear,
      method: methodTypeEnumValue,
      targetPctInput: Number(unprettify(this.netzeroTargetPct)),
      scopeType: ReductionInfoScopeTypeEnum.Netzero,
      thirdpartyVatNo: this.thirdpartyInfo.behalfOfVatNr
    }
    if (this.selectedNetzeroReductionTargetId != '') {
      reductionInfo.id = +this.selectedNetzeroReductionTargetId
    }
    await this.save(reductionInfo)

    if(this.$refs.netzeroComparePie) this.$refs.netzeroComparePie.updateChart()
  }

  // sum af all selected scope 3 compared to entire scope 3. I guess it must be change on target year
  get percentageScope3CategoriesSelected() : number {
    console.log("percentageScope3CategoriesSelected:", this.selectedCategories, "data:", this.scope3ChartCalculations, this.reductionResults)
    let sumScope3Selected = 0
    let sumScope3Total = 0
    this.scope3ChartCalculations?.forEach((year : GroupingCalculatedResults) => {
      if (year.year === Number(this.endYear)) {
        console.log("year:", year)
        sumScope3Total = year.ghgCalculatedResult?.scope3Result?? 0
        this.selectedCategories.forEach((selectedCat) => {
          year.categoryResults?.forEach((category: CategoryResult) => {
            if (selectedCat === category.categoryName) {
              sumScope3Selected += category.categoryResult?? 0
            }
          })
        })
      }
    })
    //If sumscope3total is 0, selectedCategories is empty - However, if empty, all is selected (AK for default),
    let pct = sumScope3Selected === 0 ? 100 : (sumScope3Selected / sumScope3Total) * 100

    console.log("sumScope3Selected:", sumScope3Selected, "sumScope3Total:", sumScope3Total, "pct:", pct)
    return pct
  }

  private reductionTargetError(isGet = false) {
    if (isGet) {
      this.addToastFromMessageKeys(
        'GetReductionError',
        'error',
        'forecast.calculate-forecast.toast.get-error.header',
        'forecast.calculate-forecast.toast.get-error.message',
        true,
        false
      )
    } else {
      this.addToastFromMessageKeys(
        'CreateForecastError',
        'error',
        'forecast.calculate-forecast.toast.save-error.header',
        'forecast.calculate-forecast.toast.save-error.message',
        true,
        false
      )
    }
  }

  getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | undefined {
    const keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue)
    return keys.length > 0 ? keys[0] : undefined
  }

  async exportToExcel(event: any) {
    if (this.selectedForecastId) {
      const countryCode = this.useTextKeys ? 'TE' :  (this.store.getters.getLocale === 'da' ? 'DK' : 'US')
      const env = document.location.host.split(".")[0]
      const response = await forecastClient.exportToExcel2(
        Number(this.selectedForecastId),
        this.thirdpartyInfo.behalfOfVatNr ? this.thirdpartyInfo.behalfOfVatNr : 'null', countryCode, env,
        { responseType: 'blob' }
      )
      const blob = new Blob([response.data], { type: response.headers['content-type'] })
      // due to CORS we cannot access Content-Disposition header here, so we rebuild filename
      const filename = `klimakompasset-resultat-${this.selectedForecastId}.xlsx`
      downloadFile(blob, filename)
    } else {
      window.alert('Der skal vælges en fremskrivning')
    }
  }

  filterForecastScope3Categories(selectedScope3ReductionTargetId: string) {
    if (this.selectedScope3ReductionTargetId) {
      // Reset arrays to avoid overpopulation
      this.selectedCategories = [];
      this.possibleCategories = [];
      this.possibleUpstreamCategories = [];
      this.possibleDownstreamCategories = [];

      reductionTargetClient.getCategories(Number.parseInt(this.selectedScope3ReductionTargetId))
          .then((result: AxiosResponse<CategoryInfo[]>) => {
            result.data.forEach((categoryInfo: CategoryInfo) => {
              if (categoryInfo && categoryInfo.categoryCode && categoryInfo.contentfulId && categoryInfo.category_type && categoryInfo.scope3_percent) {
                if (categoryInfo.selected) {
                  this.selectedCategories.push(categoryInfo.categoryCode);
                }

                const label = this.contentfulContent.findSimpleText(categoryInfo.contentfulId) +
                    ` (${prettify(categoryInfo.scope3_percent, 2)}% i ${this.endYear})`;

                const category = new CategoriesForSelect(categoryInfo.categoryCode, label);
                this.possibleCategories.push(category);

                if (categoryInfo.category_type === 'UPSTREAM') {
                  this.possibleUpstreamCategories.push(category);
                } else {
                  this.possibleDownstreamCategories.push(category);
                }
              }
            });

            // Deduplicate selectedCategories to avoid duplicates
            this.selectedCategories = Array.from(new Set(this.selectedCategories));

            this.showAndSaveForecast();
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }

}
</script>

<style lang='scss' scoped>
.container .px-0 {
  padding-top: 0 !important;
}

.font-weight-bold-no-margin-top {
  font-weight: bold;
  margin-top: 0;
}

.adjust-button-size {
  display: block;
  margin-top: 31px;
}

.bg-alternative {
  background-color: #e8f2ee !important;
}

.form-input .form-group .form-error * {
  border-left: 4px solid $color-validation-error !important;
  padding-left: calc(16px - 4px);
}

.form-select .form-group .form-error * {
  border-left: 4px solid $color-validation-error !important;
  padding-left: calc(16px - 4px);
}


.side-by-side {
  display: flex;
  flex-wrap: wrap;
}

.box50 {
  /* Set the width of each box */
  width: 50%;
  /* Set the display property to inline-block */
  display: inline-block;
  /* Optional: add padding and border */
  padding: 10px;
  border: none;
}

.box80 {
  /* Set the width of each box */
  display: flex;
  flex-basis: 80%;
  flex-direction: column;
  /* Set the display property to inline-block */
}

.box20 {
  display: flex;
  flex-basis: 20%;
}

.box33 {
  /* Set the width of each box */
  width: 33%;
  /* Set the display property to inline-block */
  display: inline-block;
  /* Optional: add padding and border */
  padding: 10px;
  border: none;
}

.line-container {
  display: flex;
  flex-direction: column;
  top: -15%;
  right: 15%;
  align-items: center;
  position: relative;
}

.text {
  text-align: center;
}

.line {
  width: calc(100% + 50px);
  border-bottom: 1px solid black;
  position: relative;
  margin-bottom: 25px;
}

.line:after {
  content: "";
  position: absolute;
  right: -10px;
  top: -9px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid black;
}

p {
  max-width: 100% !important;
}

.netzero {
  align-items: center;
  display: flex;
  padding-bottom: 125px;
}

.netzero-label {
  height: 40%;
  width: 80%;
  justify-self: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    border-bottom: 1px solid black;
  }

  h4 {
    margin: 0
  }

}
.flex-grow-equally {
  flex-grow: 1;
  flex-basis: 0;
}

.column-row-gap-10 {
  column-gap: 10px;
  row-gap: 10px;
}

.netzero-percentage {
  font-size: 4rem;
}

.netzerocompare {
  padding-bottom: 4rem;
}

section {
  padding-top: 40px;
}

.margin-bot-30 {
  margin-bottom: 30px;
}

form {
  padding-bottom: 10px;
}

.custom-tag {
  padding: 0.5rem;
  background-color: var(--ms-tag-bg);
  color: var(--ms-tag-color);
  //padding: var(--ms-py) var(--ms-px);
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
}

.custom-tag:not(:first-child) {
  //Hacky trick to hide additional labels, as they are supposed to be represented by one single label (AK).
  visibility: hidden;
  display: none;
}

</style>
