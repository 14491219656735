<template>
  <SharedHeader :tabMenu="menu">
    <template #firstColumn>
      <h1><SimpleText text-key="import-export.export.headerTitle"/></h1>
      <p><SimpleText text-key="import-export.export.headerText"/></p>
    </template>
  </SharedHeader>
  <div class='row'>

    <div class="container px-0 py-9" id="wrapper-div">
      <form
          id="purpose"
          v-if="calculation.calculationPurpose"
          @change="
      formValidation.clearValidField($event);
    "
          @submit.prevent="exportCalculationAsJson()"
          @invalid.capture.prevent="
      formValidation.handleFormValidity($event, true);
    "
      >
      <div
          v-if="formValidation.formErrors.size"
          aria-atomic="true"
          class="alert alert-error"
          role="alert"
      >
        <div class="alert-body">
          <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
          <ul class="alert-text">
            <li
                v-for="[key, value] of formValidation.formErrors"
                :key="key"
                :data-cy="'error_' + key"
            >
              {{ value }}
            </li>
          </ul>
        </div>
      </div>

      <div class="form-group">
        <label class="form-label" for="input-choose-calculation">
          <SimpleText text-key="generic.choose-calculation.label"/>
        </label>
        <select
            aria-labelledby="choose-calculation-div"
            class=" form-select"
            name="options"
            id="input-choose-calculation"
            v-model="selectedCalculationId"
            @focusout="watchCalculationId()"
            @change="watchCalculationId()"
        >
          <option disabled selected value="">
            <SimpleText text-key="generic.input.dropdown.option-select.name"/>
          </option>
          <option disabled value="">
            <SimpleText text-key="generic.draft"/>
          </option>
          <option v-for="calculation in [...possibleDraftCalculations]"
                  :key="calculation.calculationPurpose.name" :value="calculation.id" :id="calculation.calculationPurpose.name">
            {{ calculationSelectDisplayFormat(calculation) }}
          </option>
          <option disabled value="">
            <SimpleText text-key="generic.completed"/>
          </option>
          <option v-for="calculation in [...possibleFinishedCalculations]"
                  :key="calculation.calculationPurpose.name" :value="calculation.id" :id="calculation.calculationPurpose.name">
            {{ calculationSelectDisplayFormat(calculation) }}
          </option>
        </select>
      </div>
      <div class="form-group">
        <label class="form-label" for="calculation-export-name">
          <SimpleText text-key="import-export.export.calculation-name-in-export"/>
        </label>
        <input id="calculation-export-name" class="form-input" type="text" :disabled="!isCalculationSelected()" v-model="calculationName"
               :data-error="nameOfCalculationError"
               maxlength="40"
               :pattern="findRegexPattern('regex.validation.calculation-name')"
               required
        />
      </div>

      <button id='button-import-calculation' type='submit' class="button button-primary mt-705"
              :disabled="!isCalculationSelected()">
        <SimpleText text-key="import-export.export.button.label"/>
      </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { Menu } from "@/shared/MenuStructure"
import SharedHeader from '@/components/calculator/shared/SharedHeader.vue'
import { Options } from 'vue-class-component'
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import {calculationImportExportClient} from "@/shared/BackendService";
import {downloadFile} from "@/shared/FileHelper";
import {Calculation, CalculationStateEnum} from "../../../openapi/generated-clients/climatecompass";
import Tooltip from "@/components/Tooltip.vue";
import {FormValidation} from "@/shared/FormValidation";

@Options({ components: {Tooltip, SimpleText, SharedHeader } })
export default class Export extends BaseComponent {
  menu = {} as Menu

  possibleDraftCalculations: Calculation[] = []
  possibleFinishedCalculations: Calculation[] = []
  selectedCalculationId = ''
  calculationName = ''

  formValidation = new FormValidation()

  async beforeMount() {
    this.menu = this.store.getters.getMenuStructure.importExportTabs

    this.eventHub.emit("start-loading", "Export")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "Export")
      this.sortCalculationsForSelect()
    })
  }

  async exportCalculationAsJson() {
    let result = {}

    if (this.selectedCalculationId) {
      console.log("exportCalculationAsJson", this.selectedCalculationId, this.calculationName, this.getThirdpartyVatNo())
      await calculationImportExportClient.exportCalculationJson(Number(this.selectedCalculationId), this.calculationName, this.getThirdpartyVatNo()).then((response) => {
        if (response.data) {
          result = response.data
          const jsonData = JSON.stringify(result)
          downloadFile(new Blob([jsonData], { type: "application/json" }), this.calculationName + '.json')
        }
      })
    }
  }

  watchCalculationId() {
    //When a calculation is selected, set its name in the name field and enable name field editing for changing name in the exported JSON file
    const calculationIdNumber = Number(this.selectedCalculationId)
    console.log("watchCalculationId", calculationIdNumber, this.selectedCalculationId, this.store.getters.getCalculations.find((cal: Calculation) => cal.id === calculationIdNumber)?.calculationPurpose.name)
    if (calculationIdNumber) {
      this.calculationName = this.store.getters.getCalculations.find((cal: Calculation) => cal.id === calculationIdNumber)?.calculationPurpose.name
    }
  }

  sortCalculationsForSelect() {
    const cals = this.store.getters.getCalculations
    this.possibleDraftCalculations = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Draft || cal.state === CalculationStateEnum.Duplicated))
        .sort((a: Calculation, b: Calculation) => this.calculationSelectDisplayFormat(a).localeCompare(this.calculationSelectDisplayFormat(b)))
    this.possibleFinishedCalculations = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Finished || cal.state === CalculationStateEnum.FinishedDuplicated || cal.state === CalculationStateEnum.Readonly))
        .sort((a: Calculation, b: Calculation) => this.calculationSelectDisplayFormat(a).localeCompare(this.calculationSelectDisplayFormat(b)))
  }

  calculationSelectDisplayFormat(calculation: Calculation): string {
    const startYear = calculation.calculationPurpose.startDate.substring(0, 4)
    const endYear = calculation.calculationPurpose.endDate.substring(0, 4)
    if (startYear === endYear) {
      return `${calculation.calculationPurpose.name} (${startYear})`
    } else {
      return `${calculation.calculationPurpose.name} (${startYear}-${endYear})`
    }
  }

  isCalculationSelected(): boolean {
    console.log("isCalculationSelected()", this.selectedCalculationId, (this.selectedCalculationId ? true : false))
    return (this.selectedCalculationId ? true : false)
  }

  get nameOfCalculationError() {
    return this.store.getters.getContentfulContent.findSimpleText('purpose.nameOfCalculationError')
  }
}
</script>
