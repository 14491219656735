import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommonProcumentCalculator = _resolveComponent("CommonProcumentCalculator")!
  const _component_CalculatorTabComponent = _resolveComponent("CalculatorTabComponent")!

  return (_openBlock(), _createBlock(_component_CalculatorTabComponent, null, {
    "calculator-primary-1": _withCtx((slotProps) => [
      _createVNode(_component_CommonProcumentCalculator, {
        subgroupingId: _ctx.primary ? _ctx.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER : _ctx.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER,
        title: slotProps.title,
        expanded: true
      }, null, 8, ["subgroupingId", "title"])
    ]),
    "calculator-secondary-1": _withCtx((slotProps) => [
      _createVNode(_component_CommonProcumentCalculator, {
        subgroupingId: _ctx.primary ? _ctx.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER : _ctx.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER,
        title: slotProps.title
      }, null, 8, ["subgroupingId", "title"])
    ]),
    _: 1
  }))
}