<template>
  <SharedHeader :tabMenu="menu">
    <template #firstColumn>
      <h1><SimpleText text-key="thirdparty.has-access-to.headerTitle"/></h1>
      <p><SimpleText text-key="thirdparty.has-access-to.headerText"/></p>
    </template>
  </SharedHeader>

  <div class="container px-0 py-9">
    <div v-if="showSuccess" id="success-box" class="alert alert-success">
      <div class="alert-body">
        <p class="alert-heading"><SimpleText text-key="thirdparty.has-access-to.successTitle"/></p>
        <p class="alert-text">
          <SimpleText text-key="thirdparty.has-access-to.successText"/>
        </p>
      </div>
    </div>

    <div v-if="formValidation.formErrors.size" aria-atomic="true" class="alert alert-error" role="alert" id="third-party-error">
      <div class="alert-body">
        <p class="alert-heading">
          <SimpleText v-if="!specialTitle" text-key="sharedContent.formErrorTitle"/>
          <SimpleText v-if="specialTitle" text-key="sharedContent.formInvalidTitle"/>
        </p>
        <ul class="alert-text">
          <li v-for="[key, value] of formValidation.formErrors" :key="key" :data-cy="'error_' + key">
            <a href="javascript:void(0)" @click.prevent="focusInput(key)" style="text-decoration: none">
            {{ value }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <h1>
      {{ isEdit() ? this.titleEdit : this.titleAdd }}
    </h1>

    <div class="py-1">
      <div class="full-width" v-dompurify-html="documentToHtmlString(this.text)"></div>
    </div>

    <div class="py-3">
      <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
            @invalid.capture.prevent="formValidation.handleFormValidity"
            ref="formGroup">
        <div class="form-group">

          <div id="vatNo-input-wrapper-div" class="form-group">
            <label class="form-label " for="input-type-vatNo">
              <SimpleText text-key="thirdparty.has-access-to.labelCvr"/>
            </label><span class="ml-2"><Tooltip :text="this.labelCvrHint"/></span>
            <input
                id="input-type-vatNo"
                v-model="currentAccess.vatId"
                :data-error="this.cvrError"
                class="form-input"
                :disabled="disableCvrInput"
                name="input-type-vatNo"
                :pattern="findRegexPattern('regex.validation.vat-no')"
                required
                @keyup="loadCompanyName();"
                @change="validateConsentAfterValueUpdate"
            />
          </div>

          <div class="form-group">
            <label class="form-label " for="input-type-company-name">
              <SimpleText text-key="thirdparty.has-access-to.labelCompanyname"/>
            </label>
            <input
                id="input-type-company-name"
                v-model="currentAccess.companyName"
                :data-error="this.noCompanyname"
                class="form-input"
                name="input-type-company-name"
                disabled
                type="text"
                required
            />
          </div>

          <div class="form-group">
            <label class="form-label " for="input-type-contactperson-name">
              <SimpleText text-key="thirdparty.has-access-to.labelContactpersonName"/>
            </label>
            <input
                id="input-type-contactperson-name"
                v-model="currentAccess.contantPersonName"
                :data-error="this.contactpersonError"
                class="form-input"
                name="input-type-contactperson-name"
                :pattern="findRegexPattern('regex.validation.person-name')"
                maxlength="100"
                required
                type="text"
                @input="validateConsentAfterValueUpdate"
            />
          </div>

          <div class="form-group">
            <label class="form-label " for="input-type-contactperson-email">
              <SimpleText text-key="thirdparty.has-access-to.labelContactpersonEmail"/>
            </label>
            <input
                id="input-type-contactperson-email"
                v-model="currentAccess.contactPersonEmail"
                :data-error="this.emailError"
                class="form-input"
                name="input-type-contactperson-email"
                :pattern="findRegexPattern('regex.validation.e-mail')"
                maxlength="100"
                minlength="5"
                required
                type="email"
                @input="validateConsentAfterValueUpdate"
            />
          </div>

          <!-- date -->
          <DateInputComponent
              v-model="currentAccess.expireDate"
              :is-optional="true"
              ref="dateInputComponent"
              @change="validateConsentAfterValueUpdate"
          >
          </DateInputComponent>
          <!--fieldset>
            <legend id="date-picker-legend"></legend>
            <label for="datepicker-access" class="form-label">{{ pageProperties.endDate }}</label>
            <span id="end-date-field-hint" class="form-hint">{{ pageProperties.endDateHint }}</span>
            <div class="date-picker" :data-default-value="currentAccess.expireDate">
              <input
                  id="datepicker-access"
                  v-model="this.currentAccess.expireDate"
                  class="form-input "
                  name="access-expiredate"
                  type="text"
              >
            </div>
          </fieldset-->

          <div class="form-group">
            <label id="create-access-multiselect-dropdown-label" class="form-label" for="multi-select-calculations">
              <SimpleText text-key="thirdparty.has-access-to.selectCalculations"/>
            </label>
            <span class="ml-2"><Tooltip :text="this.selectCalculationsHint"/></span>
            <div class="multiselect-wrapper">
              <Multiselect
                  id="multi-select-calculations"
                  v-model="selectedCalculations"
                  mode="tags"
                  :close-on-select="false"
                  :hideSelected="false"
                  :canClear="false"
                  :groups="true"
                  :options="calsForSelectWithLabels"
                  valueProp="calculationId"
                  label="nameAndYear"
                  ref="calculationsmultiselect"
                  class="multiselect-calculation form-group"
                  :aria="{'aria-labelledby': 'create-access-multiselect-dropdown-label', 'aria-placeholder': null, 'aria-multiselectable': null}"
                  @change="validateConsentAfterValueUpdate"
              />
            </div>
          </div>

          <div class="form-group">
            <input
                id="checkbox-consent"
                type="checkbox"
                class="form-checkbox"
                :checked="consentCheckBoxStatus"
                :disabled="isConsentDisabled()"
                @input="onConsentCheckChanged($event.target.checked)"
            />
            <label class="form-label " for="checkbox-consent" v-dompurify-html="contentMessage"/>
          </div>

        </div>
        <button id="submit-button" class="button button-primary px-8 my-4" type="submit" :disabled="submitButtonDisabled">
          {{ isEdit() ? this.saveButtonEditText : this.saveButtonCreateText }}
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import Multiselect from '@vueform/multiselect'
import Navigation from "@/components/thirdparty/Navigation.vue"
import SharedHeader from "@/components/calculator/shared/SharedHeader.vue"
import {Menu} from "@/shared/MenuStructure"
import {FormValidation} from "@/shared/FormValidation";
import {documentToHtmlString} from '@contentful/rich-text-html-renderer'
import {AccessInfo, Calculation, CalculationInfo, CalculationStateEnum} from "../../../openapi/generated-clients/climatecompass";
import {Prop, Watch} from "vue-property-decorator";
import {Cloneable} from "@/shared/DeepCopyHelper";
import {companyClient} from "@/shared/BackendService";
import DKFDS from "dkfds";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import DateInputComponent from "@/components/DateInput.vue";
import SimpleText from "@/components/SimpleText.vue";
import {watch} from "vue";

class CalculationForSelect {
  calculationId?: number
  nameAndYear: string
  name: string
  year?: number
  contactpersonRid: string

  constructor(_id: number | undefined, _name: string, _year: number | undefined, _contactpersionRid: string) {
    this.calculationId = _id
    this.name = _name
    this.year = _year
    if (this.year) {
      this.nameAndYear = `${this.name} (${this.year})`
    } else {
      this.nameAndYear = `${this.name}`
    }
    this.contactpersonRid = _contactpersionRid
  }
}

class CalculationForSelectWithLabels {
  label: string
  options: CalculationForSelect[]

  constructor(_label: string, _options: CalculationForSelect[]) {
    this.label = _label
    this.options = _options
  }
}

class CalcInfo implements CalculationInfo {
  calculationId?: number;
  calculationName?: string;
  isKladde?: boolean;
  contactpersonRid: string

  constructor(_calculationId: number, _calculationName: string, _isKladde: boolean, _contactpersionRid: string) {
    this.calculationId = _calculationId;
    this.calculationName = _calculationName;
    this.isKladde = _isKladde;
    this.contactpersonRid = _contactpersionRid
  }
}

@Options({
  components: {
    SimpleText,
    DateInputComponent,
    Navigation,
    SharedHeader,
    Multiselect
  }
})
export default class CreateAccess extends BaseComponent {
  /** mode can be 'edit' or 'new' */
  @Prop()
  mode?: string

  @Watch('currentAccess.vatId')
  handleSameCvrFormErrors() {
    const errorElem = document.getElementById('input-type-vatNo')?.parentElement
    const ownVatId = this.store.getters.getCurrentUser.profile['dk:gov:cvr']
    if(this.currentAccess.vatId === ownVatId) {
      this.formValidation.formErrors.set('vatNo-input-wrapper-div', this.sameCvrError)
      errorElem?.classList.add('form-error')
    } else {
      this.formValidation.formErrors.delete("vatNo-input-wrapper-div")
      errorElem?.classList.remove('form-error')
    }
  }

  @Watch('selectedCalculations')
  handleNoCalculationsFormErrors() {
    const errorElem = document.getElementById('multi-select-calculations')?.parentElement?.parentElement
    if(this.selectedCalculations.length > 0 && errorElem?.classList.contains('form-error')) {
      this.formValidation.formErrors.delete("multi-select-calculations")
      errorElem?.classList.remove('form-error')
    }
  }

  enableValidation = false
  menu = {} as Menu
  router = useRouter()
  formValidation = new FormValidation()
  showSuccess = false
  allowLeave = false
  showEndDateError = false
  calsForSelectWithLabels: CalculationForSelectWithLabels[] = []
  currentAccess = {} as AccessInfo
  accessInfos: AccessInfo[] = []
  selectedCalculations: number[] = []
  enableButton = true
  disableButtonWhileSaving = false
  disableCvrInput = false
  consentCheckBoxStatus = true
  contentMessage = ""
  // to make sure resetForm are only performed once when in mode New
  initNew = false

  consentedContactPerson = ''
  consentedContactPersonEmail = ''
  consentedExpireDate? : string
  consentedSelectedCalculations: number[] = []
  consentedVatId = ''
  specialTitle = false

  $refs!: {
    calculationsmultiselect: Multiselect
    formGroup: HTMLFormElement
    dateInputComponent: DateInputComponent
  }

  async beforeMount() {
    this.setupCurrentAccess()
    this.updateConsentedValues()
    this.menu = this.store.getters.getMenuStructure.accessTabs
    this.contentMessage = this.consentMessage2.replace("#link#", '<a href="/klimakompasset/terms-for-use">her</a>')
    this.eventHub.emit("start-loading", "CreateAccess")
    await this.store.dispatch("loadCalculations").finally(() => {
      this.eventHub.emit("stop-loading", "CreateAccess")
    })
    this.getAccessFromDb()
    this.setupCalculations()
    this.enableButton = this.isEdit();
    this.consentCheckBoxStatus = true
  }

  get saveButtonCreateText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.saveButtonCreateText')
  }

  get saveButtonEditText() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.saveButtonEditText')
  }

  get selectCalculationsHint() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.selectCalculationsHint')
  }

  get text() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.text')
  }

  get emailError() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.emailError')
  }

  get contactpersonError() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.contactpersonError')
  }

  get titleAdd() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.titleAdd')
  }

  get titleEdit() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.titleEdit')
  }

  get labelCvrHint() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.labelCvrHint')
  }

  get cvrError() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.cvrError')
  }

  get sameCvrError() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.sameCvrError')
  }

  getAccessFromDb() {
    this.store.dispatch("loadAccesses").finally(() => {
      this.eventHub.emit("stop-loading", "HasAccessTo")
    })
    this.accessInfos = this.store.getters.getAccesses
  }

  get accesses() {
    this.accessInfos = this.store.getters.getAccesses
    return this.accessInfos
  }

  get consentMessage() {
    return this.contentMessage
  }

  @Watch('selectedCalculations')
  watchSelectedCalculations() {
    this.validateConsentAfterValueUpdate()
  }

  validateConsentAfterValueUpdate() {
    if (!this.isValueUnchangedSinceConsent()) {
      this.consentCheckBoxStatus = false
    } else {
      this.consentCheckBoxStatus = true
    }
  }

  get submitButtonDisabled() {
    return !this.consentCheckBoxStatus || this.disableButtonWhileSaving
  }

  beforeRouteLeave(to: any, from: any, next: (arg0: boolean | undefined) => void) {
    if (this.allowLeave) {
      next(undefined)
    } else {
      window.alert(this.leaveError)
      next(false)
    }
  }

  clearFields() {
    if (!this.isEdit()) {
      // clear input fields is new
      this.resetForm()
      this.enableButton = false
      this.currentAccess.id = undefined
    }
  }

  updateConsentedValues() {
    this.consentedVatId = this.currentAccess.vatId
    this.consentedSelectedCalculations = this.selectedCalculations
    this.consentedContactPerson = this.currentAccess.contantPersonName
    this.consentedContactPersonEmail = this.currentAccess.contactPersonEmail
    this.consentedExpireDate = this.currentAccess.expireDate
  }

  isEdit() {
    const bEdit = (this.mode === 'edit')
    //console.log("isEdit", bEdit, this.disableCvrInput, "selCal:", this.selectedCalculations, "ccc", this.currentAccess.calculationInfoList)
    if (bEdit) {
      this.initNew = false
      this.disableCvrInput = true
      /*---Clear existing CSS error tokens on access edit ---*/
      this.formValidation.formErrors.clear()
      document.querySelectorAll(".form-group").forEach(function (element) {
        element.classList.remove('form-error')
      })
      document.querySelectorAll(".form-input").forEach(function (element) {
        element.classList.remove("changed")
      })
    } else {
      if (!this.initNew) {
        this.initNew = true
        this.disableCvrInput = false
        this.clearFields()
      }
    }
    return bEdit
  }

  isValueUnchangedSinceConsent() {
    // if changed calculations then consent must be given again
    const vatIdChanged = (this.consentedVatId !== this.currentAccess.vatId)
    if (vatIdChanged) {
      return false
    }
    const dropdownChanged = !(this.selectedCalculations.length == this.consentedSelectedCalculations.length
                              && this.selectedCalculations.every((obj1: number) => this.consentedSelectedCalculations.some((obj2: number) => obj1 === obj2)))
    if (dropdownChanged) {
      return false
    }
    const contactPersonChanged = (this.consentedContactPerson !== this.currentAccess.contantPersonName)
    if (contactPersonChanged) {
      return false
    }
    const contactPersonEmailChanged = (this.consentedContactPersonEmail !== this.currentAccess.contactPersonEmail)
    if (contactPersonEmailChanged) {
      return false
    }
    const expireDateChanged = (this.consentedExpireDate !== this.currentAccess.expireDate)
    if (expireDateChanged) {
      return false
    }
    return true
  }

  isConsentDisabled() {
    return false
  }

  get documentToHtmlString() {
    return documentToHtmlString
  }

  /** make a collection of calculations for options and update list with selected */
  setupCalculations() {
    const cals = this.store.getters.getCalculations;
    this.calsForSelectWithLabels = []
    const calsForSelectDraft: CalculationForSelect[] = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Draft || cal.state === CalculationStateEnum.Duplicated))
        .map((cal: Calculation) => (new CalculationForSelect(cal.id, cal.calculationPurpose.name,
            cal.emissionCoefficientsVersion?.year, cal.contactPersonRID)))
    if (calsForSelectDraft && calsForSelectDraft.length > 0) {
      this.calsForSelectWithLabels.push(new CalculationForSelectWithLabels(this.getDraft, calsForSelectDraft))
    }
    const calsForSelectCompleted = cals
        .filter((cal: Calculation) => (cal.state === CalculationStateEnum.Finished || cal.state === CalculationStateEnum.FinishedDuplicated || cal.state === CalculationStateEnum.Readonly))
        .map((cal: Calculation) => (new CalculationForSelect(cal.id, cal.calculationPurpose.name,
            cal.emissionCoefficientsVersion?.year, cal.contactPersonRID)))
    if (calsForSelectCompleted && calsForSelectCompleted.length > 0) {
      this.calsForSelectWithLabels.push(new CalculationForSelectWithLabels(this.getCompleted, calsForSelectCompleted))
    }

    for (const cal of cals) {
      if (this.hasSelection(cal.id) && this.selectedCalculations.indexOf(cal.id) == -1) {
        this.selectedCalculations.push(cal.id)
      }
    }

    return this.calsForSelectWithLabels
  }

  get consentMessage2() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.consentMessage')
  }

  get leaveError() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.leaveError')
  }

  get getDraft() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.draft')
  }

  get getCompleted() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.completed')
  }

  get validationErrorSelectOneCalculation() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.validationErrorSelectOneCalculation')
  }

  get toastMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.toastMessage')
  }

  setupCurrentAccess() {
    if (this.isEdit()) {
      if (this.store.getters.getCurrentAccess) {
        //Deep copy the current calculation if it exist. taken from another screenm why is copy needed?
        this.currentAccess = Cloneable.deepCopy<AccessInfo>(this.store.getters.getCurrentAccess)
        this.consentedContactPerson = this.currentAccess.contantPersonName
        this.consentedContactPersonEmail = this.currentAccess.contactPersonEmail
        this.consentedExpireDate = this.currentAccess.expireDate
        if(this.$refs.dateInputComponent) {
          this.$refs.dateInputComponent.init()
        }
      }
    } else {
      // default
      this.currentAccess = ({} as AccessInfo) as AccessInfo
    }
  }

  hasSelection(calculationId: number) {
    if (this.currentAccess.calculationInfoList) {
      for (const cal of this.currentAccess.calculationInfoList) {
        if (calculationId === cal.calculationId) {
          return true;
        }
      }
    }
    return false;
  }

  async editAccess(access: AccessInfo) {
    await this.store.dispatch("setCurrentAccess", access).then(async () => {
      await this.router.push("/setup/access/edit-access")
    })
  }

  async loadCompanyName() {
    if (this.currentAccess.vatId.toString().length !== 8) {
      this.currentAccess.companyName = ""
      return
    }
    let data
    try {
      data = await companyClient.fetchCompany(this.currentAccess.vatId)
      this.formValidation.formErrors.delete("no-company-name")
      if (data && data.data) {
        const access = this.pick(this.accessInfos, this.currentAccess.vatId)
        if (access != null) {
          await this.editAccess(access)
          this.setupCurrentAccess()
          this.setupCalculations()
        }
      } else {
        this.formValidation.formErrors.set(
            "no-company-name",
            this.noCompanyname)
      }
    } catch (error) {
      this.formValidation.formErrors.set(
          "no-company-name",
          this.noCompanyname
      )
    } finally {
      this.currentAccess.companyName = data?.data.name || ""
    }
  }

  pick(accessInfos: AccessInfo[], cvr: string) {
    for (const acc of accessInfos) {
      if ((acc.vatId === cvr) && (!acc.revoked) && ((!acc?.expireDate) || (acc.expireDate >= new Date().toISOString().substr(0, 10)))) {
        return acc;
      }
    }
    return null
  }

  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset();
    }
    this.currentAccess.vatId = ''
    this.currentAccess.contantPersonName = ''
    this.currentAccess.companyName = ''
    this.currentAccess.contactPersonEmail = ''
    this.currentAccess.revoked = false
    this.currentAccess.calculationInfoList = []
    this.currentAccess.vatIdSource = ''
    this.selectedCalculations = []
    this.$refs.calculationsmultiselect?.$emit("clear")
    this.enableButton = false
  }

  async submitForm(e: Event) {
    this.handleNoCalculationsFormErrors()
    // set selected calculations
    const selectedCalculationsToGrantAccess: CalcInfo[] = []
    for (const calculation of this.store.getters.getCalculations) {
      if (this.selectedCalculations.includes(calculation.id)) {
        selectedCalculationsToGrantAccess.push(new CalcInfo(calculation.id, calculation.calculationPurpose.name,
            false, calculation.contactpersonRid));
      }
    }
    this.specialTitle = false
   if (selectedCalculationsToGrantAccess.length === 0) {
      // normal scroll to top does not work with multiselect so this has been made as workaround
      this.formValidation.formErrors.set("multi-select-calculations", this.validationErrorSelectOneCalculation)
      const errorElem = document.getElementById('multi-select-calculations')?.parentElement?.parentElement
      errorElem?.classList.add('form-error')
      document.getElementById("multi-select-calculations")?.scrollIntoView(false)
      } else if (this.currentAccess.vatId === this.store.getters.getCurrentUser.profile['dk:gov:cvr']) {
      const errorElem = document.getElementById('input-type-vatNo')
        this.formValidation.formErrors.set("input-type-vatNo", this.sameCvrError)
        errorElem?.classList.add('form-error')
        this.specialTitle = false
        errorElem?.scrollIntoView(false)
      } else if (this.currentAccess.companyName == '' || null || undefined) {
      this.formValidation.formErrors.set("input-type-company-name", this.noCompanyname)
      const errorElem = document.getElementById('input-type-company-name')
      errorElem?.classList.add('form-error')
      document.getElementById("multi-select-calculations")?.scrollIntoView(false)
    } else {
      this.currentAccess.calculationInfoList = selectedCalculationsToGrantAccess
      const element = e.target as HTMLFormElement
      if (element.reportValidity()) {
        this.disableButtonWhileSaving = true
        if(this.$refs.dateInputComponent && !this.$refs.dateInputComponent.isDateInputEnabled) {
          this.currentAccess.expireDate = undefined
        }
        try {
          await this.store.dispatch("setAccess", this.currentAccess).then(async (response) => {
            this.disableButtonWhileSaving = false
          })
          if (!this.allowLeave) {
            this.allowLeave = true
            if (this.isEdit()) {
              this.addToast("CreateAccessEditSuccess", 'success',
                  this.toastEditHeader, this.toastEditMessage,
                  true, false)
              await this.router.push("/setup/access/access-to")
            } else {
              this.addToast("CreateAccessCreateSuccess", 'success',
                  this.toastHeader,
                  this.toastMessage.replace("#url#", '/klimakompasset/setup/access/access-to#tabs'),
                  true, false, false, true)
              await this.router.push("/setup/access/access-to")
            }
          } else {
            this.formValidation.formErrors.clear()
            this.showSuccess = true
          }
        } catch (error: unknown) {
          if (error instanceof Error && error.message.includes("406")) {
            this.disableButtonWhileSaving = false
            this.formValidation.formErrors.set("input-type-calculations", this.getContentfulString('thirdparty.access.edit.validation.error.multiple-rows'))
            this.scrollToTargetWithOffset('third-party-error', 300)
          }
        }
      }
    }
  }

  exist(vatId : string) {
    for (const item of this.accessInfos) {
      if (item.vatId === vatId) {
        return true
      } else {
        return false
      }
    }
}
  mounted() {
    DKFDS.init()
  }

  updated() {
    DKFDS.init()
  }

  onConsentCheckChanged(checked: boolean) {
    this.enableButton = checked
    this.consentCheckBoxStatus = checked
    this.updateConsentedValues()
  }

  get toastEditHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.toastEditHeader')
  }

  get toastHeader() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.toastHeader')
  }

  get toastEditMessage() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.toastEditMessage')
  }

  get noCompanyname() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.has-access-to.noCompanyname')
  }

  get cvrIsAlreadyInUse() {
    return this.store.getters.getContentfulContent.findSimpleText('thirdparty.cvr-already-in-use')
  }

}
</script>

<style src="@vueform/multiselect/themes/default.css">
</style>

<style lang="scss" scoped>
.form-error:not(.form-group .form-error) {
  border: 2px solid $color-validation-error !important;
}


.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 4px var(--ms-px) 4px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

.multiselect-wrapper {
  max-width: 49rem !important;
  margin-top: 10px;
  margin-left: 0px;
}
</style>
