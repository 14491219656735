<template>
  <Chart
      ref="highestEmissionsStackedColumnChart"
      width="100%"
      height="400"
      type="bar"
      :options="chartOptions"
      :series="chartOptions.series">
  </Chart>
</template>

<script lang="ts">

import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import VueApexCharts, {VueApexChartsComponent} from 'vue3-apexcharts'
import {Options} from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import SimpleText from "@/components/SimpleText.vue";
import { CalculationLine } from '@/components/calculator/results/HighestEmissionResults.vue'
import { graphPrettify, prettify} from '@/shared/PrettifyNumbers'


@Options({
  components: {
    Chart: VueApexCharts,
    SimpleText,
  }
})
export default class HighestEmissionResultsColumnChart extends BaseComponent {
  @Prop()
  rawData?: CalculationLine[]

  @Prop()
  filter = 0

  chartOptions: any



  $refs!: {
    highestEmissionsStackedColumnChart: VueApexChartsComponent
  }

  async beforeMount() {
    if (this.rawData) {
      this.chartOptions = this.getChart()
    }
  }

  getChart() {
    if (!this.rawData) {
      return
    }
    const options = {
      chart: {
        fontFamily: 'IBM Plex Sans, system',
        parentHeightOffset: 10,
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        }
      },
      grid: {
        borderColor: '#D3D3D3',
        strokeDashArray: 7,
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '10px',
                fontWeight: 800,
              }
            }
          }
        },
      },
      tooltip: {
        y: {
          formatter: function(value: string) {
            const val = Number(value)
            return  graphPrettify(val)
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          colors: ['#333'],
          fontSize: '8px',
          fontFamily: 'Helvetica, Arial, sans-serif',
        },
        formatter: function (value: string) {
          const val = Number(value)
          return graphPrettify(val)
        },
        hideOverFlowingLabels: false,
        offsetY: 0,
      },
      xaxis: {
        type: 'String',
        labels: {
          rotate: -70,
          trim: true,
          minHeight: undefined,
          maxHeight: 120,
        },
        categories: this.rawData?.map(calcLine => {
          return calcLine.calculationLineDescription
        })
      },
      yaxis: {
        title: {
          text: this.getContentfulString('shared.ton-co2e.no-format'),
        },
        labels: {
          formatter: (value: number) => {
            return graphPrettify(value)
          }
        }
      },
      legend: {
        position: 'bottom',
        offsetx: 40
      },
      fill: {
        opacity: 1
      }
    } as any

    const series : any[] = []
    if(this.filter === 0 || (this.filter & this.constants.FORECAST_FILTER_SCOPE1) > 0) {
      series.push({
        name: 'Scope 1',
        data: this.rawData?.map(calcLine => {
          return calcLine.scope1Value
        }),
        color: this.constants.SCOPE1_COLOR_HEX
      })
    }
    if(this.filter === 0 || (this.filter & this.constants.FORECAST_FILTER_SCOPE2) > 0) {
      series.push({
        name: 'Scope 2',
        data: this.rawData?.map(calcLine => {
          return calcLine.scope2Value
        }),
        color: this.constants.SCOPE2_COLOR_HEX
      })
    }
    if(this.filter === 0 || (this.filter & this.constants.FORECAST_FILTER_SCOPE3) > 0) {
      series.push({
        name: 'Scope 3',
        data: this.rawData?.map(calcLine => {
          return calcLine.scope3Value
        }),
        color: this.constants.SCOPE3_COLOR_HEX
      })
    }
    options.series = series
    return options
  }

  updateChart() {
    if((this.rawData?.length ?? 0) > 0 && this.$refs.highestEmissionsStackedColumnChart) {
      this.chartOptions = this.getChart()
      this.$refs.highestEmissionsStackedColumnChart.updateOptions(this.chartOptions)

    }
  }
}
</script>

<style scoped>

</style>