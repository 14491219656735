<template>
  <CalculatorTabComponent
    >
    <template v-slot:calculator-primary-1="slotProps">
      <CommonTransportCalculator
        :subgroupingId="constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER"
        :title="slotProps.title"
        :expanded="true"
        >
      </CommonTransportCalculator>
    </template>
    <template v-slot:calculator-primary-2="slotProps">
      <CommonTransportCalculator
        :subgroupingId="constants.GROUP_FLY_OG_SKIBE"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonTransportCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <OtherTransportCalculator
        :subgroupingId="constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER"
        :title="slotProps.title"
      >
      </OtherTransportCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import CommonTransportCalculator from "@/components/calculator/transport/calculators/CommonTransportCalculator.vue"
import OtherTransportCalculator from "@/components/calculator/transport/calculators/OtherTransportCalculator.vue"

@Options({
  components: { OtherTransportCalculator, CommonTransportCalculator, CalculatorTabComponent }
})
export default class OwnAndLeasedView extends BaseComponent {

}
</script>

<style scoped>

</style>