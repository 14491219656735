<template>

  <div class="wrapper-div" id="resultsEsg-wrapper-div">

    <div id="section-one-container" class="section-one-container">
      <h1 id="section-one-main-header">
        <SimpleText text-key="results.esg.result-main-header" />
      </h1>

      <div v-if="results?.subgroupWarnings && results?.subgroupWarnings?.length > 0">
        <p class="main-description-text"><SimpleText text-key="warning.illegal.units" /></p>
        <ul>
          <li v-for="sg in results?.subgroupWarnings" :key="sg">
            <button class="link-button" @click="gotoUrl(sg)">{{ hrefText(sg) }}</button>
          </li>
        </ul>
      </div>

      <p id="main-description-text" class="main-description-text">
        <RichText text-key="results.esg.result-main-description-text" />
      </p>

      <table id="esg-results-table1-emission" class="table results-table w-percent-100 mb-3"
             v-if="results?.groupingResults"
             aria-describedby="esg-result-table1-emission-header1">
        <thead>
        <tr class="bg-primary text-color-white">
          <th id="esg-result-table1-emission-header1" colspan="3" scope="colgroup">
            <SimpleText text-key="results.esg.result-table1-emission-header-one" />
          </th>
        </tr>
        </thead>
        <thead>
        <tr style="font-weight: 600" class="w-percent-100">
          <th headers="esg-result-table1-emission-header1" style="width: 55%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-one">
            <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
          </th>
          <th headers="esg-result-table1-emission-header1" style="width: 15%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-two">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
          <th headers="esg-result-table1-emission-header1" style="width: 30%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-three">
            <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr id="esg-result-scope-one">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-one"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope1Total" class="align-text-right">
            {{ doPrettify(results.esgResult.scope1Total) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-two">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-two"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope2Total" class="align-text-right">
            {{ doPrettify(results.esgResult.scope2Total) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scope-three">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scope-three"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.esgResult?.scope3Total" class="align-text-right">
            {{ doPrettify(results.esgResult.scope3Total) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-scopes-total">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-scopes-total"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-one"></SimpleText>
          </td>
          <td v-if="results.ghgCalculatedResult?.totalResult" class="align-text-right"> {{
              doPrettify(results.ghgCalculatedResult.totalResult)
            }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        <tr id="esg-result-co2intensity">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-co2intensity"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-two"></SimpleText>
          </td>
          <td v-if="results.esgResult?.co2Intensity" class="align-text-right">
            {{ doPrettify(results.esgResult.co2Intensity) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table2-energy-description" />

      <div v-if="results.esgResult?.isMissingESGDataEnergy">
        <RichText text-key="results.esg.pre-table2-esg-missing-share-pct-warning" />
      </div>

      <table id="esg-results-table2-energy" class="table results-table w-percent-100 mb-3"
             v-if="results?.groupingResults"
             aria-describedby="esg-result-table2-energy-header1">
        <thead>
        <tr class="bg-primary text-color-white">
          <th id="esg-result-table2-energy-header1" colspan="4" scope="colgroup">
            <SimpleText text-key="results.esg.result-table2-energy-header-one" />
          </th>
        </tr>
        </thead>
        <thead>
        <tr style="font-weight: 600" class="w-percent-100">
          <th headers="esg-result-table2-energy-header1" style="width: 55%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-one">
            <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
          </th>
          <th headers="esg-result-table2-energy-header1" style="width: 15%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-two">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
          <th headers="esg-result-table2-energy-header1" style="width: 15%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-three">
            <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
          </th>
          <th headers="esg-result-table2-energy-header1" style="width: 15%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-four">
            <SimpleText text-key="results.esg.result-table-subheader-four"></SimpleText>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr id="esg-result-fossil-energy">
          <td class="align-text-left pl-6">
            <SimpleText text-key="results.esg.result-table-row-fossil-energy"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionFossilSourcesResult" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionFossilSourcesResult) }}
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionFossilSourcesPct" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionFossilSourcesPct) }}%
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        <tr id="esg-result-nuclear-energy">
          <td class="align-text-left pl-6">
            <SimpleText text-key="results.esg.result-table-row-nuclear-energy"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionNuclearSourcesResult" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionNuclearSourcesResult) }}
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionNuclearSourcesPct" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionNuclearSourcesPct) }}%
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        <tr id="esg-result-renewable-energy">
          <td class="align-text-left pl-6">
            <SimpleText text-key="results.esg.result-table-row-renewable-energy"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionRenewableSourcesResult" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionRenewableSourcesResult) }}
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyConsumptionRenewableSourcesPct" class="align-text-right">
            {{ doPrettify(results.esgResult.energyConsumptionRenewableSourcesPct) }}%
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        <tr id="esg-result-energytotal">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-energytotal"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyTotal" class="align-text-right">
            {{ doPrettify(results.esgResult.energyTotal) }}
            <div v-if="results.esgResult.mustUpdateDueToESGEnergy">
              <SimpleText text-key="results.esg.result-table.number-may-not-be-correct" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          <td v-if="results.esgResult?.energyTotal" class="align-text-right"> {{ doPrettify(100) }}%</td>
          <td v-else-if="results.esgResult?.mustUpdateDueToESGEnergy" class="italic-text">
            <SimpleText text-key="results.esg.result-table.must-update" />
            <a href="/klimakompasset/overview" style="text-decoration: none">
              <SimpleText text-key="results.esg.result-table.update-link" />
            </a>
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table3-water-and-waste-description" />

      <table id="esg-results-table3-water-and-waste" class="table results-table w-percent-100 mb-3"
             v-if="results?.groupingResults"
             aria-describedby="esg-result-table3-water-and-waste-header1">
        <thead>
        <tr class="bg-primary text-color-white">
          <th id="esg-result-table3-water-and-waste-header1" colspan="3" scope="colgroup">
            <SimpleText text-key="results.esg.result-table3-water-and-waste-header-one" />
          </th>
        </tr>
        </thead>
        <thead>
        <tr style="font-weight: 600" class="w-percent-100">
          <th headers="esg-result-table3-water-and-waste-header1" style="width: 55%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-one">
            <SimpleText text-key="results.esg.result-table-subheader-one"></SimpleText>
          </th>
          <th headers="esg-result-table3-water-and-waste-header1" style="width: 15%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-two">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
          <th headers="esg-result-table3-water-and-waste-header1" style="width: 30%"
              class="py-405 align-text-left bg-alternative color-text-black"
              id="results.esg.result-table-subheader-three">
            <SimpleText text-key="results.esg.result-table-subheader-three"></SimpleText>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr id="esg-result-waterconsumption">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-waterconsumption"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.fuels.m3"></SimpleText>
          </td>
          <td v-if="results.esgResult?.waterConsumption" class="align-text-right">
            {{ doPrettify(results.esgResult.waterConsumption) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-wastetotal">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-row-wastetotal"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-eight"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteTotal" class="align-text-right">
            {{ doPrettify(results.esgResult.wasteTotal) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-wastehazardous">
          <td class="align-text-left  pl-6">
            <SimpleText text-key="results.esg.result-table-row-wastehazardous"></SimpleText>
            <Tooltip
              aria-label="result-result-waste-hazardous-percentage-tooltip"
              textKey="results.esg.result-waste-hazardous-percentage-tooltip"
              style="align-content: flex-end"
              size="md-gr"
            ></Tooltip>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteHazardousPercentage" class="align-text-right">
            {{ doPrettify(results.esgResult.wasteHazardousPercentage) }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>

        <tr id="esg-result-wasterecycled">
          <td class="align-text-left  pl-6">
            <SimpleText text-key="results.esg.result-table-row-wasterecycled"></SimpleText>
          </td>
          <td>
            <SimpleText text-key="results.esg.result-table-measurement-unit-five"></SimpleText>
          </td>
          <td v-if="results.esgResult?.wasteRecycledPercentage" class="align-text-right"> {{
              doPrettify(results.esgResult.wasteRecycledPercentage)
            }}
          </td>
          <td v-else class="italic-text-right">
            <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>

      <RichText text-key="results.esg.pre-table4-electricity-description" />

      <div v-if="results.esgResult?.isMissingESGDataElectricity">
        <RichText text-key="results.esg.pre-table4-esg-missing-share-pct-warning" />
      </div>

      <div v-if="results.esgResult?.isIllegalESGUnitElectricity">
        <RichText text-key="results.esg.pre-table4-esg-illegal-unit-warning" />
      </div>

      <table id="esg-results-table4-electricity" class="table results-table w-percent-100 mb-3"
             v-if="results?.groupingResults"
             aria-describedby="results.esg.result-table4-header-one">
        <thead>
        <tr style="font-weight: 600" class="w-percent-100">
          <th style="width: 40%"
              class="py-405 align-text-left"
              id="results.esg.result-table4-header-one">
            <SimpleText text-key="results.esg.result-table4-header-one"></SimpleText>
          </th>
          <th style="width: 15%"
              class="py-405 align-text-left"
              id="results.esg.result-table4-header-two">
            <SimpleText text-key="results.esg.result-table4-header-two"></SimpleText>
          </th>
          <th style="width: 15%"
              class="py-405 align-text-left"
              id="results.esg.result-table4-header-three">
            <SimpleText text-key="results.esg.result-table4-header-three"></SimpleText>
          </th>
          <th style="width: 15%"
              class="py-405 align-text-left"
              id="results.esg.result-table4-header-four">
            <SimpleText text-key="results.esg.result-table4-header-four"></SimpleText>
          </th>
          <th style="width: 15%"
              class="py-405 align-text-left"
              id="results.esg.result-table4-header-five">
            <SimpleText text-key="results.esg.result-table-subheader-two"></SimpleText>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr id="esg-result-table4-row-electricity">
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-header-electricity"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.electricityRenewableTotal}">
            <span v-if="results.esgResult?.electricityRenewableTotal">{{ doPrettify(results.esgResult.electricityRenewableTotal) }}</span>
            <span v-else>
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </span>
            <div v-if="results.esgResult?.mustUpdateDueToESGElectricity">
              <SimpleText :text-key="results.esgResult?.electricityRenewableTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.electricityNonRenewableTotal}">
            <span v-if="results.esgResult?.electricityNonRenewableTotal">{{ doPrettify(results.esgResult.electricityNonRenewableTotal) }}</span>
            <span v-else>
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </span>
            <div v-if="results.esgResult?.mustUpdateDueToESGElectricity">
              <SimpleText :text-key="results.esgResult?.electricityNonRenewableTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.electricityTotal}">
            <span v-if="results.esgResult?.electricityTotal">{{ doPrettify(results.esgResult.electricityTotal) }}</span>
            <span v-else>
              <SimpleText text-key="results.esg.result-table-no-assigned-value"></SimpleText>
            </span>
            <div v-if="results.esgResult?.mustUpdateDueToESGElectricity">
              <SimpleText :text-key="results.esgResult?.electricityTotal ? 'results.esg.result-table.number-may-not-be-correct' : 'results.esg.result-table.must-update'" />
              <a href="/klimakompasset/overview" style="text-decoration: none">
                <SimpleText text-key="results.esg.result-table.update-link" />
              </a>
            </div>
          </td>

          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
        </tr>
        
        <tr>
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-header-fuel"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.fuelRenewableTotal}">
            <span v-if="results.esgResult?.fuelRenewableTotal">{{ doPrettify(results.esgResult.fuelRenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.fuelNonRenewableTotal}">
            <span v-if="results.esgResult?.fuelNonRenewableTotal">{{ doPrettify(results.esgResult.fuelNonRenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.fuelTotal}">
            <span v-if="results.esgResult?.fuelTotal">{{ doPrettify(results.esgResult.fuelTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
        </tr>

        <tr>
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table-header-district-heating"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.districtHeatingRenewableTotal}">
            <span v-if="results.esgResult?.districtHeatingRenewableTotal">{{ doPrettify(results.esgResult.districtHeatingRenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.districtHeatingNonRenewableTotal}">
            <span v-if="results.esgResult?.districtHeatingNonRenewableTotal">{{ doPrettify(results.esgResult.districtHeatingNonRenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !results.esgResult?.districtHeatingTotal}">
            <span v-if="results.esgResult?.districtHeatingTotal">{{ doPrettify(results.esgResult.districtHeatingTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
        </tr>

        <tr>
          <td class="align-text-left">
            <SimpleText text-key="results.esg.result-table4-header-four"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !table4RenewableTotal}">
            <span v-if="table4RenewableTotal">{{ doPrettify(table4RenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !table4NonRenewableTotal}">
            <span v-if="table4NonRenewableTotal">{{ doPrettify(table4NonRenewableTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>

          <td class="align-text-right" :class="{ 'italic-text': !table4TotalTotal}">
            <span v-if="table4TotalTotal">{{ doPrettify(table4TotalTotal) }}</span>
            <SimpleText v-else text-key="results.esg.result-table-no-assigned-value"></SimpleText>
          </td>
          
          <td>
            <SimpleText text-key="calculation.dropdown.possiblevalue.unit.mwh"></SimpleText>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>

<script lang="ts">

import { Menu } from '@/shared/MenuStructure'
import { useRouter } from 'vue-router'
import { Options } from 'vue-class-component'
import RichText from '@/components/RichText.vue'
import SimpleText from '@/components/SimpleText.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import ContentfulContent from '@/shared/ContentfulContent'
import { Prop } from 'vue-property-decorator'
import {
  GroupingCalculatedResults
} from '../../../../openapi/generated-clients/climatecompass'
import Tooltip from '@/components/Tooltip.vue'

@Options({
  components: {
    Tooltip,
    RichText,
    SimpleText
  }
})
export default class ResultsESG extends BaseComponent {

  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  menu = {} as Menu
  router = useRouter()

  sgLinkArray: { sg: number, url: string, scrollTo: string, groupContentfulKey: string, sgContentfulKey: string }[] = [
    {
      sg: 1,
      url: '/klimakompasset/calculator/energy-and-processes/electricity#tabs',
      scrollTo: 'calculator-section-1',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroupelectricity'
    },
    {
      sg: 2,
      url: '/klimakompasset/calculator/energy-and-processes/electricity#tabs',
      scrollTo: 'calculator-section-2',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-electricityown'
    },
    {
      sg: 3,
      url: '/klimakompasset/calculator/energy-and-processes/heat#tabs',
      scrollTo: 'calculator-section-3',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-heat'
    },
    {
      sg: 4,
      url: '/klimakompasset/calculator/energy-and-processes/heat#tabs',
      scrollTo: 'calculator-section-4',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-fuel'
    },
    {
      sg: 5,
      url: '/klimakompasset/calculator/energy-and-processes/heat#tabs',
      scrollTo: 'calculator-section-5',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-heatprocessown'
    },
    {
      sg: 6,
      url: '/klimakompasset/calculator/energy-and-processes/process#tabs',
      scrollTo: 'calculator-section-6',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-process'
    },
    {
      sg: 7,
      url: '/klimakompasset/calculator/energy-and-processes/process#tabs',
      scrollTo: 'calculator-section-7',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'subcategories.subgroup-processown'
    },
    {
      sg: 8,
      url: '/klimakompasset/calculator/energy-and-processes/other#tabs',
      scrollTo: 'calculator-section-8',
      groupContentfulKey: 'menu.calculatormenu.item.energy-and-processes',
      sgContentfulKey: 'group.text.category.groupheatprocessother'
    },
    {
      sg: 9,
      url: '/klimakompasset/calculator/procurement/materials-primary',
      scrollTo: 'calculator-section-9',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchasematerialsphysical'
    },
    {
      sg: 10,
      url: '/klimakompasset/calculator/procurement/materials-primary',
      scrollTo: 'calculator-section-10',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchasematerialmonetary'
    },
    {
      sg: 11,
      url: '/klimakompasset/calculator/procurement/materials-primary',
      scrollTo: 'calculator-section-11',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchasephysicalother'
    },
    {
      sg: 12,
      url: '/klimakompasset/calculator/procurement/materials-primary',
      scrollTo: 'calculator-section-12',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchasemonetaryother'
    },
    {
      sg: 13,
      url: '/klimakompasset/calculator/procurement/products-and-services-primary#tabs',
      scrollTo: 'calculator-section-13',
      groupContentfulKey: 'Indkøb',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsphysical'
    },
    {
      sg: 14,
      url: '/klimakompasset/calculator/procurement/products-and-services-primary#tabs',
      scrollTo: 'calculator-section-14',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-purchase-products-own-emm-monitare-unit'
    },
    {
      sg: 15,
      url: '/klimakompasset/calculator/procurement/products-and-services-primary#tabs',
      scrollTo: 'calculator-section-15',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsphysicalown'
    },
    {
      sg: 16,
      url: '/klimakompasset/calculator/procurement/products-and-services-primary#tabs',
      scrollTo: 'calculator-section-16',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsmonetaryown'
    },
    {
      sg: 17,
      url: '/klimakompasset/calculator/procurement/other-primary#tabs',
      scrollTo: 'calculator-section-17',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsphysicalown'
    },
    {
      sg: 18,
      url: '/klimakompasset/calculator/procurement/other-primary#tabs',
      scrollTo: 'calculator-section-18',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsmonetaryown'
    },
    {
      sg: 19,
      url: '/klimakompasset/calculator/procurement/materials-secondary#tabs',
      scrollTo: 'calculator-section-19',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchasematerialsphysicalunits'
    },
    {
      sg: 20,
      url: '/klimakompasset/calculator/procurement/materials-secondary#tabs',
      scrollTo: 'calculator-section-20',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchasematerialsmonetaryunits'
    },
    {
      sg: 21,
      url: '/klimakompasset/calculator/procurement/materials-secondary#tabs',
      scrollTo: 'calculator-section-21',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchaseproductsphysicalown'
    },
    {
      sg: 22,
      url: '/klimakompasset/calculator/procurement/materials-secondary#tabs',
      scrollTo: 'calculator-section-22',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsmonetaryown'
    },
    {
      sg: 23,
      url: '/klimakompasset/calculator/procurement/products-and-services-secondary#tabs',
      scrollTo: 'calculator-section-23',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchaseproductsphysical'
    },
    {
      sg: 24,
      url: '/klimakompasset/calculator/procurement/products-and-services-secondary#tabs',
      scrollTo: 'calculator-section-24',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-purchase-products-own-emm-monitare-unit'
    },
    {
      sg: 25,
      url: '/klimakompasset/calculator/procurement/products-and-services-secondary#tabs',
      scrollTo: 'calculator-section-25',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchaseproductsphysicalown'
    },
    {
      sg: 26,
      url: '/klimakompasset/calculator/procurement/products-and-services-secondary#tabs',
      scrollTo: 'calculator-section-26',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsmonetaryown'
    },
    {
      sg: 27,
      url: '/klimakompasset/calculator/procurement/other-secondary#tabs',
      scrollTo: 'calculator-section-27',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-secpurchaseproductsphysicalown'
    },
    {
      sg: 28,
      url: '/klimakompasset/calculator/procurement/other-secondary#tabs',
      scrollTo: 'calculator-section-28',
      groupContentfulKey: 'menu.calculatormenu.item.procurement',
      sgContentfulKey: 'subcategories.subgroup-primarypurchaseproductsmonetaryown'
    },
    {
      sg: 29,
      url: '/klimakompasset/calculator/trash-and-recycling/trash#tabs',
      scrollTo: 'calculator-section-29',
      groupContentfulKey: 'menu.calculatormenu.item.trash-and-recycling',
      sgContentfulKey: 'subcategories.subgroup-trash'
    },
    {
      sg: 30,
      url: '/klimakompasset/calculator/trash-and-recycling/trash#tabs',
      scrollTo: 'calculator-section-30',
      groupContentfulKey: 'menu.calculatormenu.item.trash-and-recycling',
      sgContentfulKey: 'subcategories.subgroup-TrashOther'
    },
    {
      sg: 31,
      url: '/klimakompasset/calculator/trash-and-recycling/other#tabs',
      scrollTo: 'calculator-section-31',
      groupContentfulKey: 'menu.calculatormenu.item.trash-and-recycling',
      sgContentfulKey: 'subcategories.subgroup-TrashOther'
    },
    {
      sg: 32,
      url: '/klimakompasset/calculator/sold-products/processing#tabs',
      scrollTo: 'calculator-section-32',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'subcategories.subgroup-processing--sold-products'
    },
    {
      sg: 33,
      url: '/klimakompasset/calculator/sold-products/use-of-products#tabs',
      scrollTo: 'calculator-section-33',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'subcategories.subgroup-soldproductsused'
    },
    {
      sg: 34,
      url: '/klimakompasset/calculator/sold-products/use-of-products#tabs',
      scrollTo: 'calculator-section-34',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'subcategories.subgroup-sold-rented-own-emf'
    },
    {
      sg: 35,
      url: '/klimakompasset/calculator/sold-products/end-of-life-treatment#tabs',
      scrollTo: 'calculator-section-35',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'subcategories.subgroup-endoflife'
    },
    {
      sg: 36,
      url: '/klimakompasset/calculator/sold-products/end-of-life-treatment#tabs',
      scrollTo: 'calculator-section-36',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'subcategories.subgroup-endoflifeown'
    },
    {
      sg: 37,
      url: '/klimakompasset/calculator/sold-products/other-sold-products#tabs',
      scrollTo: 'calculator-section-37',
      groupContentfulKey: 'menu.calculatormenu.item.sold-products',
      sgContentfulKey: 'group.text.category.groupothersoldproducts'
    },
    {
      sg: 39,
      url: '/klimakompasset/calculator/transport/own-and-leased#tabs',
      scrollTo: 'calculator-section-39',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'EGNE OG LEASEDE KØRETØJER'
    },
    {
      sg: 40,
      url: '/klimakompasset/calculator/transport/own-and-leased#tabs',
      scrollTo: 'calculator-section-40',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-airplaneship'
    },
    {
      sg: 41,
      url: '/klimakompasset/calculator/transport/own-and-leased#tabs',
      scrollTo: 'calculator-section-41',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-ownandleasedtransportsown'
    },
    {
      sg: 42,
      url: '/klimakompasset/calculator/transport/employee-transportation#tabs',
      scrollTo: 'calculator-section-42',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-pendling'
    },
    {
      sg: 43,
      url: '/klimakompasset/calculator/transport/employee-transportation#tabs',
      scrollTo: 'calculator-section-43',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-travelsphysical'
    },
    {
      sg: 44,
      url: '/klimakompasset/calculator/transport/employee-transportation#tabs',
      scrollTo: 'calculator-section-44',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-travelsmonetary'
    },
    {
      sg: 45,
      url: '/klimakompasset/calculator/transport/employee-transportation#tabs',
      scrollTo: 'calculator-section-45',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-employeetransportown'
    },
    {
      sg: 46,
      url: '/klimakompasset/calculator/transport/product-transportation#tabs',
      scrollTo: 'calculator-section-46',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transportphysical'
    },
    {
      sg: 47,
      url: '/klimakompasset/calculator/transport/product-transportation#tabs',
      scrollTo: 'calculator-section-47',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transporttocustomermonetary'
    },
    {
      sg: 48,
      url: '/klimakompasset/calculator/transport/product-transportation#tabs',
      scrollTo: 'calculator-section-48',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transporttocompanyown'
    },
    {
      sg: 49,
      url: '/klimakompasset/calculator/transport/customer-transportation#tabs',
      scrollTo: 'calculator-section-49',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transportphysical'
    },
    {
      sg: 50,
      url: '/klimakompasset/calculator/transport/customer-transportation#tabs',
      scrollTo: 'calculator-section-50',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transporttocustomermonetary'
    },
    {
      sg: 51,
      url: '/klimakompasset/calculator/transport/customer-transportation#tabs',
      scrollTo: 'calculator-section-51',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'subcategories.subgroup-transporttocustomerown'
    },
    {
      sg: 52,
      url: '/klimakompasset/calculator/transport/other-transport#tabs',
      scrollTo: 'calculator-section-52',
      groupContentfulKey: 'menu.calculatormenu.item.transport',
      sgContentfulKey: 'group.text.category.groupothertransport'
    }
  ]


  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  async beforeMount() {
    this.scrollToTargetWithOffset('section-one-container', 10)
  }

  href(sg: number) {
    return this.sgLinkArray.find(item => item.sg === sg)?.url || ''
  }

  gotoUrl(sg: number) {
    this.setScrollTo(sg)
    window.location.href = this.href(sg)
  }

  setScrollTo(sg: number) {
    const item = this.sgLinkArray.find(item => item.sg === sg)
    if (item && item.scrollTo !== '') {
      sessionStorage.setItem("scrollTo", item.scrollTo);
    }
  }

  hrefText(sg: number) {
    const item = this.sgLinkArray.find(item => item.sg === sg)
    if (item) {
      return this.store.getters.getContentfulContent.findSimpleText(item.groupContentfulKey)
        + "->"
        + this.store.getters.getContentfulContent.findSimpleText(item.sgContentfulKey)
    }
  }

  get table4RenewableTotal(){
    if(this.results && this.results.esgResult
      && (this.results.esgResult?.electricityRenewableTotal
        || this.results.esgResult?.fuelRenewableTotal
        || this.results.esgResult?.districtHeatingRenewableTotal)
    ){
      return (this.results.esgResult?.electricityRenewableTotal ?? 0)
        + (this.results.esgResult?.fuelRenewableTotal ?? 0)
        + (this.results.esgResult?.districtHeatingRenewableTotal ?? 0);
    }else{
      return null;
    }
  }

  get table4NonRenewableTotal(){
    if((this.results.esgResult?.electricityNonRenewableTotal
        || this.results.esgResult?.fuelNonRenewableTotal
        || this.results.esgResult?.districtHeatingNonRenewableTotal)
    ){
      return (this.results.esgResult?.electricityNonRenewableTotal ?? 0)
        + (this.results.esgResult?.fuelNonRenewableTotal ?? 0)
        + (this.results.esgResult?.districtHeatingNonRenewableTotal ?? 0);
    }else{
      return null;
    }
  }

  get table4TotalTotal(){
    if(this.results.esgResult?.electricityTotal
        || this.results.esgResult?.fuelTotal
        || this.results.esgResult?.districtHeatingTotal){
      return (this.results.esgResult?.electricityTotal ?? 0)
        + (this.results.esgResult?.fuelTotal ?? 0)
        + (this.results.esgResult?.districtHeatingTotal ?? 0);
    }else{
      return null;
    }
  }

}
</script>

<style scoped lang="scss">

* {
  -webkit-print-color-adjust: exact;
}

.wrapper-div {
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section-one-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  max-width: 100%;
  margin: 2px;
}

.section-two-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-top: 10px;
}

.main-description-text {
  max-width: 100%;
}

.italic-text {
  font-style: italic;
}

.italic-text-right {
  font-style: italic;
  text-align: right;
}

tr > th.py-405.align-text-left.bg-alternative {
  color: black;
  border-right: 1px solid $color-primary !important;
  border-bottom: 1px solid #BFBFBF !important;
}

.link-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}

.link-button:hover {
  color: darkblue;
}
</style>