import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DistrictHeatingCalculator = _resolveComponent("DistrictHeatingCalculator")!
  const _component_FuelCalculator = _resolveComponent("FuelCalculator")!
  const _component_OtherCalculator = _resolveComponent("OtherCalculator")!
  const _component_CalculatorTabComponent = _resolveComponent("CalculatorTabComponent")!

  return (_openBlock(), _createBlock(_component_CalculatorTabComponent, null, {
    "calculator-primary-1": _withCtx((slotProps) => [
      _createVNode(_component_DistrictHeatingCalculator, {
        subgroupingId: _ctx.constants.GROUP_FJERNVARME,
        title: slotProps.title,
        expanded: true
      }, null, 8, ["subgroupingId", "title"])
    ]),
    "calculator-primary-2": _withCtx((slotProps) => [
      _createVNode(_component_FuelCalculator, {
        subgroupingId: _ctx.constants.GROUP_BRÆNDSLER,
        title: slotProps.title
      }, null, 8, ["subgroupingId", "title"])
    ]),
    "calculator-secondary-1": _withCtx((slotProps) => [
      _createVNode(_component_OtherCalculator, {
        subgroupingId: _ctx.constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER,
        title: slotProps.title
      }, null, 8, ["subgroupingId", "title"])
    ]),
    _: 1
  }))
}