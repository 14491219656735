<template>
  <CalculatorTabComponent>
    <template v-slot:calculator-primary-1="slotProps">
      <OtherTrashCalculator
        :subgroupingId="constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG"
        :title="slotProps.title"
        :expanded="true"
      >
      </OtherTrashCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import CommonTransportCalculator from "@/components/calculator/transport/calculators/CommonTransportCalculator.vue"
import OtherTransportCalculator from "@/components/calculator/transport/calculators/OtherTransportCalculator.vue"
import OtherTrashCalculator from "@/components/calculator/trash/calculators/OtherTrashCalculator.vue"

@Options({
  components: { OtherTrashCalculator, OtherTransportCalculator, CommonTransportCalculator, CalculatorTabComponent }
})
export default class OtherTrashView extends BaseComponent {

}
</script>

<style scoped>

</style>