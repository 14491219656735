import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GenericInput = _resolveComponent("GenericInput")!
  const _component_BaseCalculator = _resolveComponent("BaseCalculator")!

  return (_openBlock(), _createBlock(_component_BaseCalculator, {
    expanded: _ctx.expanded,
    subgroupingId: _ctx.constants.GROUP_FJERNVARME,
    title: _ctx.title,
    tooltip: _ctx.tooltip
  }, {
    "basecalculator-row-1-col-2": _withCtx((scopeProps) => [
      (scopeProps.fields[1])
        ? (_openBlock(), _createBlock(_component_GenericInput, {
            key: 0,
            id: `calculator-${_ctx.constants.GROUP_FJERNVARME}-field-${scopeProps.fields[1].id}`,
            "cy-id": `calculator-${_ctx.constants.GROUP_FJERNVARME}-field-${scopeProps.fields[1].id}`,
            modelValue: scopeProps.fields[1].value,
            "onUpdate:modelValue": [($event: any) => ((scopeProps.fields[1].value) = $event), scopeProps.applyConditions],
            disabled: scopeProps.isReadOnly ?? true,
            doubleLineHeight: true,
            fieldId: scopeProps.fields[1].id,
            lable: scopeProps.fields[1].lable,
            max: scopeProps.fields[1].maximumValue,
            min: scopeProps.fields[1].minimumValue,
            possibleValues: scopeProps.fields[1].possibleValues,
            regex: _ctx.findRegexPattern(scopeProps.fields[1].validationRegex),
            required: scopeProps.fields[1].required,
            step: scopeProps.fields[1].step,
            tooltip: scopeProps.fields[1].tooltip,
            type: scopeProps.fields[1].valueType,
            validationError: scopeProps.fields[1].error,
            onSelectedDependents: (arg1, arg2) => scopeProps.setSelectedDependent(arg1, arg2),
            onValueInvalid: (arg1, arg2) => scopeProps.applyValidationError(arg1, arg2),
            onValueValid: (arg1) => scopeProps.applyValidationOk(arg1),
            onInput: scopeProps.setCalculationChanged,
            onFocusout: scopeProps.setCalculationChanged
          }, null, 8, ["id", "cy-id", "modelValue", "onUpdate:modelValue", "disabled", "fieldId", "lable", "max", "min", "possibleValues", "regex", "required", "step", "tooltip", "type", "validationError", "onSelectedDependents", "onValueInvalid", "onValueValid", "onInput", "onFocusout"]))
        : _createCommentVNode("", true)
    ]),
    "basecalculator-row-1-col-3": _withCtx((scopeProps) => [
      (scopeProps.fields[2] && scopeProps.fields[2].valueType !== 'DROPDOWN_DEPENDENT')
        ? (_openBlock(), _createBlock(_component_GenericInput, {
            key: 0,
            id: `calculator-${_ctx.constants.GROUP_FJERNVARME}-field-${scopeProps.fields[2].id}`,
            modelValue: scopeProps.fields[2].value,
            "onUpdate:modelValue": [($event: any) => ((scopeProps.fields[2].value) = $event), scopeProps.applyConditions],
            disabled: scopeProps.isReadOnly ?? true,
            doubleLineHeight: true,
            lable: scopeProps.fields[2].lable,
            max: scopeProps.fields[2].maximumValue,
            min: scopeProps.fields[2].minimumValue,
            possibleValues: scopeProps.fields[2].possibleValues,
            regex: _ctx.findRegexPattern(scopeProps.fields[2].validationRegex),
            required: scopeProps.fields[2].required,
            step: scopeProps.fields[2].step,
            tooltip: scopeProps.fields[2].tooltip,
            type: scopeProps.fields[2].valueType,
            validationError: scopeProps.fields[2].error,
            onValueInvalid: (arg1, arg2) => scopeProps.applyValidationError(arg1, arg2),
            onValueValid: (arg1) => scopeProps.applyValidationOk(arg1),
            onInput: scopeProps.setCalculationChanged,
            onFocusout: scopeProps.setCalculationChanged
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "disabled", "lable", "max", "min", "possibleValues", "regex", "required", "step", "tooltip", "type", "validationError", "onValueInvalid", "onValueValid", "onInput", "onFocusout"]))
        : _createCommentVNode("", true)
    ]),
    "basecalculator-row-1-col-4": _withCtx((scopeProps) => [
      (scopeProps.fields[3])
        ? (_openBlock(), _createBlock(_component_GenericInput, {
            key: 0,
            id: `calculator-${_ctx.subgroupingId}-field-${scopeProps.fields[3].id}`,
            modelValue: scopeProps.fields[3].value,
            "onUpdate:modelValue": [($event: any) => ((scopeProps.fields[3].value) = $event), scopeProps.applyConditions],
            disabled: scopeProps.isReadOnly ?? false,
            doubleLineHeight: true,
            lable: scopeProps.fields[3].lable,
            max: scopeProps.fields[3].maximumValue,
            min: scopeProps.fields[3].minimumValue,
            possibleValues: scopeProps.fields[3].possibleValues,
            regex: _ctx.findRegexPattern(scopeProps.fields[3].validationRegex),
            required: scopeProps.fields[3].required,
            step: scopeProps.fields[3].step,
            tooltip: scopeProps.fields[3].tooltip,
            type: scopeProps.fields[3].valueType,
            blankSetsDefaultValue: true,
            validationError: scopeProps.fields[3].error,
            onInput: scopeProps.setCalculationChanged,
            onFocusout: scopeProps.setCalculationChanged,
            onValueInvalid: (arg1, arg2) => scopeProps.applyValidationError(arg1, arg2),
            onValueValid: (arg1) => scopeProps.applyValidationOk(arg1)
          }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "disabled", "lable", "max", "min", "possibleValues", "regex", "required", "step", "tooltip", "type", "validationError", "onInput", "onFocusout", "onValueInvalid", "onValueValid"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["expanded", "subgroupingId", "title", "tooltip"]))
}