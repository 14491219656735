<template>
  <ul class="accordion my-405" :id="'calculatorInput' + subgroupingId">
    <li class="mb-0" :data-cy-inner="'calculator_' + subgroupingId">
      <button
        class="accordion-button"
        :aria-expanded="expanded || isReadOnly || isTestFramework"
        :aria-controls="`calculator-${subgroupingId}`"
        :id="'calculator-section-' + subgroupingId"
      >
        {{ title?.toUpperCase() }}
        <Tooltip :text="tooltip" size="md" v-if="tooltip" />
        <span class="accordion-icon">
          <span class="icon_text" style="font-weight:normal">
            {{ totalEmissionTitle }}
            <strong>{{ doPrettify(calculationList.reduce((sum, cur) => sum + Number(calculateTotalEmission(cur)), 0).toFixed(2))
              }}</strong>
            <span v-dompurify-html="tonsCo2E" />
          </span>
        </span>
      </button>
      <div :id="`calculator-${subgroupingId}`" aria-hidden="true" class="accordion-content pt-8 px-0 pb-0">
        <form @change="formValidation.clearValidField" @submit.prevent="submitForm($event)"
              @invalid.capture.prevent="formValidation.handleFormValidity($event, false); focusFirstError(formValidation)">
          <div class="container px-6">
            <div
              class="alert alert-error"
              role="alert"
              aria-atomic="true"
              v-if="formValidation.formErrors.size"
              ref="formErrorsAlert"
              :id="`calculator-${subgroupingId}-errors`"
            >
              <div class="alert-body">
                <p class="alert-heading">
                  <SimpleText text-key="sharedContent.formErrorTitle" />
                </p>
                <ul class="alert-text">
                  <li v-for="[key, value] of formValidation.formErrors" :key="key" :data-cy="'error_' + key">
                    <a href="javascript:void(0)" @click.prevent="focusInput(key)" style="text-decoration: none">
                      {{ value }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row mb-7" v-if="!isReadOnly">
              <div class="col" data-cy-name="Beskrivelse">
                <!-- Beskrivelse -->
                <GenericInput
                  v-if="renderedFields[0]"
                  :id="`calculator-${subgroupingId}-field-${fields[0].id}`"
                  :cy-id="`calculator-${subgroupingId}-field-${fields[0].id}`"
                  ref="field0"
                  v-model="renderedFields[0].value"
                  :disabled="isReadOnly"
                  :doubleLineHeight="true"
                  :lable="renderedFields[0].lable"
                  :max="renderedFields[0].maximumValue"
                  :min="renderedFields[0].minimumValue"
                  :possibleValues="renderedFields[0].possibleValues"
                  :regex="findRegexPattern(renderedFields[0].validationRegex)"
                  :required="renderedFields[0].required"
                  :step="renderedFields[0].step"
                  :tooltip="renderedFields[0].tooltip"
                  :type="renderedFields[0].valueType"
                  :validationError="renderedFields[0].error"
                />
              </div>
              <div class="col" data-cy-name="Felt2">
                <slot name="basecalculator-row-1-col-2"
                      :set-selected-dependent="setSelectedDependent"
                      :unit-value-changed="unitValueChanged"
                      :apply-conditions="applyConditions"
                      :apply-validation-error="applyValidationError"
                      :apply-validation-ok="applyValidationOk"
                      :set-calculation-changed="setCalculationChanged"
                      :fields="renderedFields"
                      :is-read-only="isReadOnly"
                ></slot>
              </div>
              <div class="col" data-cy-name="Felt3">
                <slot name="basecalculator-row-1-col-3"
                      :set-selected-dependent="setSelectedDependent"
                      :unit-value-changed="unitValueChanged"
                      :apply-conditions="applyConditions"
                      :apply-validation-error="applyValidationError"
                      :apply-validation-ok="applyValidationOk"
                      :set-calculation-changed="setCalculationChanged"
                      :fields="renderedFields"
                      :is-read-only="isReadOnly"></slot>
              </div>
              <div class="col" data-cy-name="Felt4">
                <slot name="basecalculator-row-1-col-4"
                      :set-selected-dependent="setSelectedDependent"
                      :unit-value-changed="unitValueChanged"
                      :apply-conditions="applyConditions"
                      :apply-validation-error="applyValidationError"
                      :apply-validation-ok="applyValidationOk"
                      :set-calculation-changed="setCalculationChanged"
                      :fields="renderedFields"
                      :is-read-only="isReadOnly"></slot>
              </div>
              <div class="col" data-cy-name="Felt5">
                <slot name="basecalculator-row-1-col-5"
                      :set-selected-dependent="setSelectedDependent"
                      :unit-value-changed="unitValueChanged"
                      :apply-conditions="applyConditions"
                      :apply-validation-error="applyValidationError"
                      :apply-validation-ok="applyValidationOk"
                      :set-calculation-changed="setCalculationChanged"
                      :biofuel-input="biofuelInput"
                      :fields="renderedFields"
                      :is-read-only="isReadOnly"></slot>
              </div>
            </div>
            <div class="row mb-7" v-if="!isReadOnly">
              <div class="col" data-cy-name="Scope1">
                <!-- SCOPE 1 -->
                <GenericInput
                v-if="scopeFields[0]"
                :id="`calculator-${subgroupingId}-field-${scopeFields[0].id}`"
                :cy-id="`calculator-${subgroupingId}-field-${scopeFields[0].id}`"
                v-model="scopeFields[0].value"
                :disabled="!scopeFields[0].valueType.includes('OWN') || isReadOnly"
                :lable="scopeFields[0].lable"
                :max="scopeFields[0].maximumValue"
                :min="scopeFields[0].minimumValue"
                :regex="findRegexPattern(scopeFields[0].validationRegex)"
                :required="scopeFields[0].required"
                :step="scopeFields[0].step"
                :tooltip="this.getTooltip(scopeFields[0].tooltip, scopeFields[0].contentfulId)"
                :type="scopeFields[0].valueType"
                :validationError="scopeFields[0].error"
                :doubleLineHeight="true"
                @update:modelValue="applyConditions"
                @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                @value-valid="(arg1) => applyValidationOk(arg1)"
                @input="setCalculationChanged"
                @focusout="setCalculationChanged"
              /></div>
              <div class="col" data-cy-name="Scope2">
                <!-- SCOPE 2 -->
                <GenericInput
                v-if="scopeFields[1]"
                :id="`calculator-${subgroupingId}-field-${scopeFields[1].id}`"
                :cy-id="`calculator-${subgroupingId}-field-${scopeFields[1].id}`"
                v-model="scopeFields[1].value"
                :disabled="!scopeFields[1].valueType.includes('OWN') || isReadOnly"
                :lable="scopeFields[1].lable"
                :max="scopeFields[1].maximumValue"
                :min="scopeFields[1].minimumValue"
                :regex="findRegexPattern(scopeFields[1].validationRegex)"
                :required="scopeFields[1].required"
                :step="scopeFields[1].step"
                :tooltip="this.getTooltip(scopeFields[1].tooltip, scopeFields[1].contentfulId)"
                :type="scopeFields[1].valueType"
                :validationError="scopeFields[1].error"
                :doubleLineHeight="true"
                @update:modelValue="applyConditions"
                @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                @value-valid="(arg1) => applyValidationOk(arg1)"
                @input="setCalculationChanged"
                @focusout="setCalculationChanged"
              /></div>
              <div class="col" data-cy-name="Scope3">
                <!-- SCOPE 3 -->
                <GenericInput
                v-if="scopeFields[2]"
                :id="`calculator-${subgroupingId}-field-${scopeFields[2].id}`"
                :cy-id="`calculator-${subgroupingId}-field-${scopeFields[2].id}`"
                v-model="scopeFields[2].value"
                :disabled="!scopeFields[2].valueType.includes('OWN') || isReadOnly"
                :lable="scopeFields[2].lable"
                :max="scopeFields[2].maximumValue"
                :min="scopeFields[2].minimumValue"
                :regex="findRegexPattern(scopeFields[2].validationRegex)"
                :required="scopeFields[2].required"
                :step="scopeFields[2].step"
                :tooltip="this.getTooltip(scopeFields[2].tooltip, scopeFields[2].contentfulId)"
                :type="scopeFields[2].valueType"
                :validationError="scopeFields[2].error"
                :doubleLineHeight="true"
                @update:modelValue="applyConditions"
                @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                @value-valid="(arg1) => applyValidationOk(arg1)"
                @input="setCalculationChanged"
                @focusout="setCalculationChanged"
              /></div>
              <div class="col" data-cy-name="UdledningIAlt">
                <!-- Samlet emission -->
                <div class="form-group">
                  <label
                    :for="`calculator-${subgroupingId}-total-emission-field`"
                    class="form-label double-line-height"
                    v-dompurify-html="totalEmissionLabel"
                  ></label>
                  <input
                    :id="`calculator-${subgroupingId}-total-emission-field`"
                    v-model="totalEmission"
                    class="form-input"
                    disabled
                    name="input-type-text"
                    style="text-align: right"
                    type="text"
                  />
                </div>
              </div>
              <div class="col" data-cy-name="UdenforScopes">
                <!-- Udenfor scopes -->
                <GenericInput
                  v-if="scopeFields[3]"
                  :id="`calculator-${subgroupingId}-field-${scopeFields[3].id}`"
                  :cy-id="`calculator-${subgroupingId}-field-${scopeFields[3].id}`"
                  v-model="scopeFields[3].value"
                  :disabled="!scopeFields[3].valueType.includes('OWN') || isReadOnly"
                  :lable="scopeFields[3].lable"
                  :max="scopeFields[3].maximumValue"
                  :min="scopeFields[3].minimumValue"
                  :possibleValues="scopeFields[3].possibleValues"
                  :regex="findRegexPattern(scopeFields[3].validationRegex)"
                  :required="scopeFields[3].required"
                  :step="scopeFields[3].step"
                  :tooltip="this.getTooltip(scopeFields[3].tooltip, scopeFields[3].contentfulId)"
                  :type="scopeFields[3].valueType"
                  :validationError="scopeFields[3].error"
                  :doubleLineHeight="true"
                  @update:modelValue="applyConditions"
                  @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                  @value-valid="(arg1) => applyValidationOk(arg1)"
                  @input="setCalculationChanged"
                  @focusout="setCalculationChanged"
                />
              </div>
            </div>
            <template v-if="isEsgOwnEmissionFactors">
              <div style="padding-bottom: 20px">
                <GenericInput
                  v-if="esgManualFields[0]"
                  :id="`calculator-${subgroupingId}-field-${esgManualFields[0].id}`"
                  v-model="esgManualFields[0].value"
                  :disabled="isReadOnly"
                  :lable="esgManualFields[0].lable"
                  :max="esgManualFields[0].maximumValue"
                  :min="esgManualFields[0].minimumValue"
                  :possibleValues="esgManualFields[0].possibleValues"
                  :required="esgManualFields[0].required"
                  :step="esgManualFields[0].step"
                  :tooltip="this.getTooltip(esgManualFields[0].tooltip, esgManualFields[0].contentfulId)"
                  :type="esgManualFields[0].valueType"
                  :validationError="esgManualFields[0].error"
                  :doubleLineHeight="true"
                  @update:modelValue="setUseInEsg"
                  @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                  @value-valid="(arg1) => applyValidationOk(arg1)"
                  @input="setCalculationChanged"
                  @focusout="setCalculationChanged"
                />

                <div v-if="useInEsg" class="esg-inputs-box">
                  <div class="container px-6">
                    <div class="row mb-5">
                      <div class="col" data-cy-name="esg-desc">
                        <SimpleText text-key="esg.input.description" />
                        <span class="ml-2"><Tooltip :text="getContentfulString('esg.input.tooltip')" /></span>
                      </div>
                    </div>
                    <div class="row mb-5">
                      <!--
                      <div class="col" data-cy-name="esg-conversion">
                        <GenericInput
                            v-if="esgManualFields[4]"
                            :id="`calculator-${subgroupingId}-field-${esgManualFields[4].id}`"
                            v-model="esgManualFields[4].value"
                            :disabled="isReadOnly || esgManualFields[4].isEnabled === false"
                            :lable="esgManualFields[4].lable"
                            :max="esgManualFields[4].maximumValue"
                            :min="esgManualFields[4].minimumValue"
                            :possibleValues="esgManualFields[4].possibleValues"
                            :required="esgManualFields[4].required"
                            :step="esgManualFields[4].step"
                            :tooltip="this.getTooltip(esgManualFields[4].tooltip, esgManualFields[4].contentfulId)"
                            :type="esgManualFields[4].valueType"
                            allowed-decimals="5"
                            :validationError="esgManualFields[4].error"
                            :doubleLineHeight="false"
                            @update:modelValue="applyConditions"
                            @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                            @value-valid="(arg1) => applyValidationOk(arg1)"
                            @input="setCalculationChanged"
                            @focusout="setCalculationChanged"
                        />
                      </div>
                      -->
                      <div class="col" data-cy-name="FossilShare">
                        <GenericInput
                          v-if="esgManualFields[1]"
                          :id="`calculator-${subgroupingId}-field-${esgManualFields[1].id}`"
                          v-model="esgManualFields[1].value"
                          :disabled="isReadOnly"
                          :lable="esgManualFields[1].lable"
                          :max="esgManualFields[1].maximumValue"
                          :min="esgManualFields[1].minimumValue"
                          :possibleValues="esgManualFields[1].possibleValues"
                          :required="esgManualFields[1].required"
                          :step="esgManualFields[1].step"
                          :tooltip="this.getTooltip(esgManualFields[1].tooltip, esgManualFields[1].contentfulId)"
                          :type="esgManualFields[1].valueType"
                          allowed-decimals="4"
                          :validationError="esgManualFields[1].error"
                          :doubleLineHeight="false"
                          @update:modelValue="applyConditions"
                          @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                          @value-valid="(arg1) => applyValidationOk(arg1)"
                          @input="setCalculationChanged"
                          @focusout="setCalculationChanged"
                        />
                      </div>
                      <div class="col" data-cy-name="NuclearShare">
                        <GenericInput
                          v-if="esgManualFields[2]"
                          :id="`calculator-${subgroupingId}-field-${esgManualFields[2].id}`"
                          v-model="esgManualFields[2].value"
                          :disabled="isReadOnly"
                          :lable="esgManualFields[2].lable"
                          :max="esgManualFields[2].maximumValue"
                          :min="esgManualFields[2].minimumValue"
                          :possibleValues="esgManualFields[2].possibleValues"
                          :required="esgManualFields[2].required"
                          :step="esgManualFields[2].step"
                          :tooltip="this.getTooltip(esgManualFields[2].tooltip, esgManualFields[2].contentfulId)"
                          :type="esgManualFields[2].valueType"
                          allowed-decimals="4"
                          :validationError="esgManualFields[2].error"
                          :doubleLineHeight="false"
                          @update:modelValue="applyConditions"
                          @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                          @value-valid="(arg1) => applyValidationOk(arg1)"
                          @input="setCalculationChanged"
                          @focusout="setCalculationChanged"
                        />
                      </div>
                      <div class="col" data-cy-name="RenewableShare">
                        <GenericInput
                          v-if="esgManualFields[3]"
                          :id="`calculator-${subgroupingId}-field-${esgManualFields[3].id}`"
                          v-model="esgManualFields[3].value"
                          :disabled="isReadOnly"
                          :lable="esgManualFields[3].lable"
                          :max="esgManualFields[3].maximumValue"
                          :min="esgManualFields[3].minimumValue"
                          :possibleValues="esgManualFields[3].possibleValues"
                          :required="esgManualFields[3].required"
                          :step="esgManualFields[3].step"
                          :tooltip="this.getTooltip(esgManualFields[3].tooltip, esgManualFields[3].contentfulId)"
                          :type="esgManualFields[3].valueType"
                          allowed-decimals="4"
                          :validationError="esgManualFields[3].error"
                          :doubleLineHeight="false"
                          @update:modelValue="applyConditions"
                          @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                          @value-valid="(arg1) => applyValidationOk(arg1)"
                          @input="setCalculationChanged"
                          @focusout="setCalculationChanged"
                        />
                      </div>
                      <div class="col" data-cy-name="EsgConversion">
                        <GenericInput
                          v-if="esgManualFields[4]"
                          :id="`calculator-${subgroupingId}-field-${esgManualFields[4].id}`"
                          v-model="esgManualFields[4].value"
                          :disabled="isReadOnly || esgManualFields[4].isEnabled === false"
                          :lable="esgManualFields[4].lable"
                          :max="esgManualFields[4].maximumValue"
                          :min="esgManualFields[4].minimumValue"
                          :possibleValues="esgManualFields[4].possibleValues"
                          :required="esgManualFields[4].required"
                          :step="esgManualFields[4].step"
                          :tooltip="this.getTooltip(esgManualFields[4].tooltip, esgManualFields[4].contentfulId)"
                          :type="esgManualFields[4].valueType"
                          allowed-decimals="5"
                          :validationError="esgManualFields[4].error"
                          :doubleLineHeight="false"
                          @update:modelValue="applyConditions"
                          @value-invalid="(arg1, arg2) => applyValidationError(arg1, arg2)"
                          @value-valid="(arg1) => applyValidationOk(arg1)"
                          @input="setCalculationChanged"
                          @focusout="setCalculationChanged"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="row mb-5" v-if="!isReadOnly">
              <!-- scope3 kategori -->
              <div class="col-1" style="max-width: 30%; flex-basis: 30%; padding: 0 11px;"
                   data-cy-name="Scope3Kategori">
                <GenericInput
                  v-if="scope3category"
                  :id="`calculator-${subgroupingId}-field-${scope3category.id}`"
                  :cy-id="`calculator-${subgroupingId}-field-${scope3category.id}`"
                  v-model="scope3category.value"
                  :lable="this.scope3categoryLabel"
                  :possibleValues="scope3category.possibleValues"
                  :required="true"
                  :disabled="false"
                  :tooltip="scope3category.tooltip"
                  :type="'DROPDOWN'"
                  :validationError="scope3category.error"
                  @update:modelValue="applyConditions"
                  @input="setCalculationChanged"
                  @focusout="setCalculationChanged"
                />
              </div>
              <div class="col-8" style="max-width: 40%; flex-basis: 40%; padding: 0 11px;" data-cy-name="Bemaerkninger">
                <div class="form-group" v-if="remarksField">
                  <label class="form-label" :for="`calculator-${subgroupingId}-input-area`">
                    {{ remarksField.lable }}
                    <span class="weight-normal"><SimpleText text-key="sharedContent.optionalLabel" /></span>
                  </label>
                  <textarea
                    class="form-input mw-100"
                    rows="2"
                    :id="`calculator-${subgroupingId}-input-area`"
                    name="input-area"
                    maxlength="500"
                    v-model.lazy="remarksField.value"
                    :disabled="isReadOnly"
                  ></textarea>
                </div>
              </div>
              <div class="col">
                <div class="form-group file-input">
                  <form enctype="multipart/form-data">
                    <label class="form-label" :for="`calculator-${subgroupingId}-fileinput`">
                      {{ attachFile }}
                      <span class="weight-normal"><SimpleText text-key="sharedContent.optionalLabel" /></span>
                    </label>
                    <span class="form-hint" :id="`calculator-${subgroupingId}-fileinput-hint`">
                      {{ attachFileHint }}
                    </span>
                    <div class="row mb-4 mt-1">
                      <div class="col-12">

                        <button class="button button-secondary" id="attach-file-button"
                                @click.prevent="dummyUploadFile()">
                          <SimpleText text-key="calculation.attach-file-button.name"></SimpleText>
                        </button>
                      </div>
                    </div>
                    <input
                      style="display: none;"
                      type="file"
                      :id="`calculator-${subgroupingId}-fileinput`"
                      name="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      @change="addFile"
                      :disabled="isReadOnly"
                    />
                  </form>
                </div>
                <ul class="file-list" v-if="fileList && fileList.length > 0">
                  <li v-for="file in fileList" :key="file?.fileName">
                    <button :disabled="isReadOnly" v-if="file" class="button button-unstyled"
                            @click.prevent="deleteFile(file)">
                      <svg class="icon-svg" focusable="false" aria-hidden="true">
                        <use xlink:href="#trash-can"></use>
                      </svg>
                    </button>
                    {{ file?.fileName && truncate(file.fileName, 40) }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="row align-items-end mb-5">
              <button ref="saveButton" v-if="!isReadOnly && !calculationChanged || isTestFramework"
                      :id="'saveButton_'+ subgroupingId" :disabled="saveButtonDisable"
                      class="button button-primary px-6 saveButton" type="submit">
                <span class="button-text"><SimpleText text-key="sharedContent.save" /></span>
              </button>
              <button v-if="!isReadOnly && calculationChanged || isTestFramework"
                      :id="'updateCalculationButton_'+ subgroupingId" class="button button-primary px-6"
                      @click.prevent="applyConditions()">
                <span class="button-text"><SimpleText text-key='sharedContent.calculaterow' /></span>
              </button>
              <div class="alert alert-info alert--show-icon mb-0 mt-0 ml-5" role="alert" aria-atomic="true"
                   v-if="showEmissionWarning">
                <div class="alert-body">
                  <p class="alert-heading">
                    <SimpleText text-key="sharedContent.important" />
                  </p>
                  <p class="alert-text">{{ countryEmissionWarningText }}</p>
                </div>
              </div>
            </div>
          </div>
        </form>
        <GenericTableComponent
          v-for="table in genericTables"
          :id="table.calculateId"
          :key="table.calculateId"
          :content="table"
          :ref="setGenericTableComponentRef"
          @editClick="editCalculation(table.calculateId)"
          @deleteClick="deleteCalculation(table.calculateId)"
          @showToastAndDelayHide="showToastAndDelayHide(table.fieldValues?.find((field) => field.id === fields[0]?.id)?.value, constants.TOAST_TYPE_CALCULATOR_DELETE)"
        />

        <CypressTestElement :state="hasBeenRecalculated" :inner="lastSavedCalculationId"
                            :identifier="'cypress_calculator_' + subgroupingId"></CypressTestElement>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { Field, FieldEnum } from 'openapi/generated-clients/climatecompass'
import GenericInput from './/GenericInput.vue'
import CalculatorInputField from '@/types/contentful-types/CalculatorInputField'
import GenericTableComponent from './CalculatorTableComponent.vue'
import SimpleText from '@/components/SimpleText.vue'

import Toast from '@/components/Toast.vue'

import { Options, mixins } from 'vue-class-component'
import BaseCalculator from '@/components/calculator/BaseCalculator'
import { getCookie } from '@/shared/CookieHelper'
import CypressTestElement from '@/components/testhelpers/CypressTestElement.vue'
import SwitchToggle from '@/components/SwitchToggle.vue'

interface CombinedField extends Field, CalculatorInputField {
  value: string
  selectedDependents?: FieldEnum[]
}

@Options({
  components: {
    SwitchToggle,
    SimpleText,
    CypressTestElement,
    GenericInput,
    Toast,
    GenericTableComponent
  }
})
export default class BaseCalculatorComponent extends mixins(BaseCalculator) {

  getTooltip(input: string, id: string) {
    if (this.useTextKeys && id) {
      return input + '. ContentfulId: ' + id
    } else {
      return input
    }
  }

  dummyUploadFile() {
    const uploadInput = document.getElementById(`calculator-${this.subgroupingId}-fileinput`) as HTMLInputElement
    if (uploadInput) {
      uploadInput.click()
    }
  }

  get isTestFramework() {
    return getCookie('CYPRESS-TEST').includes('true')
  }

  get totalEmissionLabel() {
    return this.store.getters.getContentfulContent.findSimpleText('calculation.total-emissions.field.label')
  }

  get totalEmissionTitle() {
    return this.store.getters.getContentfulContent.findSimpleText('calculation.total-emissions.title')
  }

  get tonsCo2E() {
    return this.store.getters.getContentfulContent.findSimpleText('shared.ton-co2e')
  }

  get attachFile() {
    return this.store.getters.getContentfulContent.findSimpleText('calculation.attach-file.title')
  }

  get attachFileHint() {
    return this.store.getters.getContentfulContent.findSimpleText('calculation.attach-file.description')
  }

  get scope3categoryLabel() {
    return this.store.getters.getContentfulContent.findSimpleText('masterdata.scope3categoryLabel')
  }

  get useInEsgTrue() {
    return this.store.getters.getContentfulContent.findSimpleText('esg.use-in-esg.true')
  }

  get useInEsgFalse() {
    return this.store.getters.getContentfulContent.findSimpleText('esg.use-in-esg.false')
  }

  mounted() {
    this.scrollTo()
  }

  scrollTo() {
    const sectionId = sessionStorage.getItem('scrollTo')
    if (sectionId) {
      setTimeout(() => {
        //console.log('sectionId:', sectionId)
        const target = document.getElementById(sectionId)
        if (target) {
          //console.log('target, scroll to', target)
          target.scrollIntoView({ behavior: 'smooth', block: 'center' })
          // Try to find and click the accordion button if closed
          if (target.getAttribute("aria-expanded") === "false") {
            //console.log("Opening accordion:", target);
            target.click();
          }
        }
      }, 500) // small delay to ensure DOM is ready
      sessionStorage.removeItem("scrollTo");
    }
  }


}

</script>

<style lang="scss" scoped>

.accordion-button {
  background-color: $color-primary;
  color: white;
  border-radius: 0;
  font-weight: 600;

  &:focus,
  &:hover {
    color: white;
    background-color: $color-primary;
  }
}

.accordion-button[aria-expanded='true'] {
  background-image: url('~@/assets/minus.svg');
  background-color: $color-primary;
}

.accordion-button[aria-expanded='false'] {
  background-image: url('~@/assets/plus.svg');
  background-color: $color-primary;
}

.accordion {
  border: 1px solid $color-primary;
  display: inline-table;
}

.esg-inputs-box {
  border: 1px solid grey;
  padding: 25px 25px 25px 20px;
  margin: 10px 0px 25px 0px;
}

</style>
