import {
  AddressesApi,
  CalculateApi,
  CalculationsApi,
  CompaniesApi,
  Configuration,
  ConfigurationApi,
  ConsentsApi,
  ContactPersonsApi,
  Countries,
  CountriesApi,
  CountriesApiFp,
  FilesApi,
  ForecastApi,
  MonitorApi,
  NewsApi,
  ModelError,
  ProductionUnitsApi,
  ReductionTargetApi,
  SessionApi,
  ThirdpartyApi,
  VersionsApi,
  TestContextApi,
  StatisticsApi,
  RegexPatternsApi,
  EmissionsfactorImportApi,
  CalculationImportExportApi,
  AiApi, NotificationsApi, ComparisonsApi, CountriesWithLocalizedNames
} from '../../openapi/generated-clients/climatecompass'
import { BASE_PATH } from "../../openapi/generated-clients/climatecompass/base";

import axios, {AxiosInstance, AxiosPromise} from "axios";
import store from "@/store";
import {Store} from "vuex";
import createCache from "vuex-cache";
import { ref, computed } from "vue";
import {Constants} from "@/shared/Constants";
import {getCookie} from "@/shared/CookieHelper";
/* eslint-disable */
const eventHub = require('tiny-emitter/instance')
const pendingLoads = ref<string[]>([]);
const pendingLoadsTimeBased = ref<string[]>([])
const timeBasedTimeout = ref<boolean>(false)

export const getPendingLoads = () => {
  const getPending = () => {
    return pendingLoads
  }

  const getTimeout = () => {
    return timeBasedTimeout
  }

  return {
    getPending,
    pendingLoads,
    getTimeout,
    timeBasedTimeout
  }
}

const config: Configuration = {
  apiKey: '',
  username: '',
  password: '',
  accessToken: '',
  basePath: '',
  baseOptions: ''
}
const axiosInstance: AxiosInstance = axios.create()

let uniqueId: string

const countryClientApi = new CountriesApi(config, BASE_PATH, axiosInstance);
const configurationClientApi = new ConfigurationApi(config, BASE_PATH, axiosInstance);

const cacheStore = new Store({
  plugins: [
    createCache({
      timeout: 60 * 60 * 1000 // Equal to 1 hours in milliseconds.
    })
  ],
  actions: {
    GET_COUNTRIES: async (_) => {
      return (await countryClientApi.fetchCountries()) ?? "";
    },
    GET_COUNTRIES_ALL: async (_) => {
      return (await countryClientApi.fetchCountriesAll()) ?? "";
    },
    GET_CONFIGURATION_KEY: async (_, key: string) => {
      return (await configurationClientApi.getKey(key, true)) ?? "";
    }
  }
});

function handleRejection(error: any) {
  handleResponseOnPending(error)
  console.error("Error in call to backend", error.config?.url, error.response?.status, error.response)
//  console.log("Error, call to backend 2", error.config?.url, error, axios.isAxiosError(error), JSON.stringify(error))
//  console.log("Error, call to backend 3", error.response?.status, error.response, typeof error.response?.status)

  if (error.response?.status == 409) {
    //console.log("Got frontend refresh status code....")
    eventHub.emit("userclient-deprecated")
  } else if (error.response?.status == 503) {
    //console.log("error response", error.response)
    if (error.response?.data?.errorCode == 'SERVICE_DOWN_FOR_MAINTENANCE') {
      //console.log("CARDBOARD_UPGRADE", error)
      eventHub.emit(Constants.CARDBOARD_UPGRADE)
    } else {
      //console.log("CARDBOARD_BACKEND_DOWN", error)
      eventHub.emit(Constants.CARDBOARD_BACKEND_DOWN)
    }
  } else if (error.response?.status == 502) {
    eventHub.emit(Constants.CARDBOARD_BACKEND_DOWN)
  } else if (error == "Error: Network Error") {
    eventHub.emit(Constants.CARDBOARD_NETWORK_ERROR)
  } else {
    const errordata = error.response?.data as ModelError
//    console.log("errordata", errordata)
    eventHub.emit('add-error-toast', errordata, error)
  }
}

axiosInstance.interceptors.request.use(
    (req) => {
      let userClientLoadTimestamp = store.getters.getUserClientLoadTimestamp
      req.headers = {'X-frontend-load-timestamp': userClientLoadTimestamp, ...req.headers} ;
      const accessToken = store.getters['oidcStore/oidcAccessToken']
      if (accessToken) {
        req.headers.Authorization = 'Bearer ' + accessToken
      }
      if (uniqueId) {
        req.headers.UNIQUE_ID = uniqueId
      }
      if(req.url && !pendingLoads.value.includes(req.url) && req.url.includes("klimakompasset/v1") && (!req.url.includes('forecast') || req.url.includes('forecast/export'))) {
        if (req.url.endsWith("files")
            || req.url.includes("v1/calculations/")
            || req.url.includes("v1/thirdparty/given/")
            || req.url.match(/v1\/calculates\/[0-9]*\/declaration/)
            || req.url.match(/v1\/calculates\/[0-9]*\/rfi/)) {
          pendingLoads.value.push(req.url)
        } else {
          pendingLoadsTimeBased.value.push(req.url)
          setTimeout(() => {
            if (pendingLoadsTimeBased.value.length > 0) {
              timeBasedTimeout.value = true
            }
          }, Constants.SPINNER_ACTIVATE_TIMEOUT)
        }
      }
      return req
    },
    (error) => {
      handleRejection(error)
      //Checks if the error is already an instance of Error, else create unknown error (SonarCube demands it)
      const rejectionReason = error instanceof Error ? error : new Error('Request failed with an unknown error')
      return Promise.reject(rejectionReason)
    }
)

function handleResponseOnPending(res: any) {
  if (res.config && res.config.url) {
    if (pendingLoads.value.includes(res.config.url)) {

      const index = pendingLoads.value.indexOf(res.config.url, 0)
      if (index > -1) {
        pendingLoads.value.splice(index, 1)
      }
    }

    if (pendingLoadsTimeBased.value.includes(res.config.url)) {
      const index = pendingLoadsTimeBased.value.indexOf(res.config.url, 0)
      if (index > -1) {
        pendingLoadsTimeBased.value.splice(index, 1)
        if (pendingLoadsTimeBased.value.length === 0) {
          timeBasedTimeout.value = false
        }
      }
    }
  }
}

axiosInstance.interceptors.response.use(
    (res) => {
      handleResponseOnPending(res);

      if (res.headers.unique_id) {
        uniqueId = res.headers.unique_id
      }
      return res
    },
    (error) => {
      handleRejection(error)
      //Checks if the error is already an instance of Error, else create unknown error (SonarCube demands it)
      const rejectionReason = error instanceof Error ? error : new Error('Response failed with an unknown error')
      return Promise.reject(rejectionReason)
    }
)

export const contactPersonClient = new ContactPersonsApi(config, BASE_PATH, axiosInstance)
export const calculationClient = new CalculationsApi(config, BASE_PATH, axiosInstance)
export const pNumberClient = new ProductionUnitsApi(config, BASE_PATH, axiosInstance)
export const addressClient = new AddressesApi(config, BASE_PATH, axiosInstance)
export const accessClient = new ThirdpartyApi(config, BASE_PATH, axiosInstance)
export const newsClient = new NewsApi(config, BASE_PATH, axiosInstance)
export const forecastClient = new ForecastApi(config, BASE_PATH, axiosInstance)
export const reductionTargetClient = new ReductionTargetApi(config, BASE_PATH, axiosInstance)
export const testContextClient = new TestContextApi(config, BASE_PATH, axiosInstance)
export const notificationClient = new NotificationsApi(config, BASE_PATH, axiosInstance)
export const comparisonClient = new ComparisonsApi(config, BASE_PATH, axiosInstance)


class CachedConfigurationApi extends ConfigurationApi {
  public getKey(key: string, reload: boolean, options?: any): AxiosPromise<string> {
    return cacheStore.cache.dispatch("GET_CONFIGURATION_KEY", key);
  }
}
export const configurationClient = new CachedConfigurationApi(config, BASE_PATH, axiosInstance)

export const noneCachedConfigurationClient = new ConfigurationApi(config, BASE_PATH, axiosInstance)

class CachedCountriesApi extends CountriesApi {
  public fetchCountries(options?: any): AxiosPromise<Countries> {
    return cacheStore.cache.dispatch("GET_COUNTRIES");
  }
  public fetchCountriesAll(options?: any): AxiosPromise<CountriesWithLocalizedNames> {
    return cacheStore.cache.dispatch("GET_COUNTRIES_ALL");
  }
}
export const countryClient = new CachedCountriesApi(config, BASE_PATH, axiosInstance);

export const regexPatternsClient = new RegexPatternsApi(config, BASE_PATH, axiosInstance)

export const companyClient = new CompaniesApi(config, BASE_PATH, axiosInstance)
export const fileClient = new FilesApi(config, BASE_PATH, axiosInstance)
export const calculateClient = new CalculateApi(config, BASE_PATH, axiosInstance)
export const consentClient = new ConsentsApi(config, BASE_PATH, axiosInstance)
export const sessionClient = new SessionApi(config, BASE_PATH, axiosInstance)

export const versionClient = new VersionsApi(config, BASE_PATH, axiosInstance)
export const monitorClient = new MonitorApi(config, BASE_PATH, axiosInstance)
export const statisticsClient = new StatisticsApi(config, BASE_PATH, axiosInstance)
export const emissionsfactorImportClient = new EmissionsfactorImportApi(config, BASE_PATH, axiosInstance)
export const calculationImportExportClient = new CalculationImportExportApi(config, BASE_PATH, axiosInstance)
export const aiClient = new AiApi(config, BASE_PATH, axiosInstance)
