import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, vModelText as _vModelText, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c9b8da2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container px-0" }
const _hoisted_2 = {
  key: 0,
  "aria-atomic": "true",
  class: "alert alert-error",
  ref: "formErrorsAlert",
  role: "alert"
}
const _hoisted_3 = { class: "alert-body" }
const _hoisted_4 = { class: "alert-heading" }
const _hoisted_5 = { class: "alert-text" }
const _hoisted_6 = ["data-cy"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "py-3" }
const _hoisted_9 = { class: "py-4 row form-group" }
const _hoisted_10 = { class: "col-md-4 form-group" }
const _hoisted_11 = {
  id: "multiselect-comparison-choose-calculation-label",
  class: "form-label",
  for: "multiselect-comparison-calculation-select"
}
const _hoisted_12 = {
  class: "multiselect-wrapper",
  id: "multiselect-comparison-calculation-wrapper"
}
const _hoisted_13 = {
  class: "col-md-4 form-group",
  style: {"margin-top":"0"},
  id: "comparison-name-div"
}
const _hoisted_14 = {
  class: "form-label",
  for: "input-comparison-name"
}
const _hoisted_15 = ["data-error"]
const _hoisted_16 = { class: "col-md-4 form-group submit-wrapper" }
const _hoisted_17 = {
  key: 0,
  id: "submit-button-label",
  class: "form-label",
  for: "submit-chosen-calculations"
}
const _hoisted_18 = {
  key: 1,
  id: "submit-button-label-alt",
  class: "form-label hidden-label",
  for: "submit-chosen-calculations"
}
const _hoisted_19 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SimpleText = _resolveComponent("SimpleText")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formValidation.formErrors.size)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, [
              _createVNode(_component_SimpleText, { "text-key": "generic.input.error.title" })
            ]),
            _createElementVNode("ul", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formValidation.formErrors, ([key, value]) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: key,
                  "data-cy": 'error_' + key
                }, [
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    onClick: _withModifiers(($event: any) => (_ctx.focusInput(key)), ["prevent"]),
                    style: {"text-decoration":"none"}
                  }, _toDisplayString(value), 9, _hoisted_7)
                ], 8, _hoisted_6))
              }), 128))
            ])
          ])
        ], 512))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("form", {
        onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.formValidation.clearValidField && _ctx.formValidation.clearValidField(...args))),
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitForm && _ctx.submitForm(...args)), ["prevent"])),
        onInvalidCapture: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.formValidation.handleFormValidity && _ctx.formValidation.handleFormValidity(...args)), ["prevent"])),
        ref: "formGroup"
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("label", _hoisted_11, [
              _createVNode(_component_SimpleText, { "text-key": "comparison.comparison-multiselect-dropdown.header" })
            ]),
            _createVNode(_component_Tooltip, {
              text: this.multiSelectTooltip,
              size: "lg-h3"
            }, null, 8, ["text"]),
            _createElementVNode("div", _hoisted_12, [
              (!_ctx.editExistingComparison)
                ? (_openBlock(), _createBlock(_component_Multiselect, {
                    key: 0,
                    id: "multiselect-comparison-calculation-select",
                    modelValue: this.selectedComparisons,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.selectedComparisons) = $event)),
                    mode: "tags",
                    "close-on-select": false,
                    hideSelected: false,
                    canClear: false,
                    groups: true,
                    "group-hide-empty": true,
                    options: [
                               {
                                label: this.selectRemoveLabel,
                                options:this.representativeCalculations
                               },
                            ],
                    valueProp: "categoryId",
                    label: "calculationPresentationName",
                    ref: "comparison-calculation-select",
                    class: "multiselect-calculation form-group",
                    aria: {'aria-labelledby':'multiselect-comparison-choose-calculation-label', 'aria-placeholder': null, 'aria-multiselectable': null},
                    placeholder: _ctx.getContentfulString('comparison.multiselect.placeholder.text'),
                    onFocusout: _ctx.removeExtraCustomTags,
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.replaceWithCustomTag = false))
                  }, _createSlots({ _: 2 }, [
                    (_ctx.replaceWithCustomTag)
                      ? {
                          name: "tag",
                          fn: _withCtx(() => [
                            _withDirectives(_createElementVNode("span", { class: "custom-tag" }, _toDisplayString(_ctx.selectedComparisons.length) + " " + _toDisplayString(_ctx.customTagTitle), 513), [
                              [_vShow, _ctx.replaceWithCustomTag]
                            ])
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["modelValue", "options", "placeholder", "onFocusout"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, [
              _createVNode(_component_SimpleText, { "text-key": "comparison.create.input-field.title" })
            ]),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                id: "input-comparison-name",
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.comparisonName) = $event)),
                "data-error": _ctx.comparisonNameError,
                class: "form-input",
                name: "input-comparison-name",
                size: "50",
                maxlength: "50",
                minlength: "2",
                required: "",
                type: "text"
              }, null, 8, _hoisted_15), [
                [_vModelText, _ctx.comparisonName]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_16, [
            (this.validateComparisonListLength)
              ? (_openBlock(), _createElementBlock("label", _hoisted_17, [
                  _createVNode(_component_SimpleText, { "text-key": "comparison.create.button-hint" })
                ]))
              : (_openBlock(), _createElementBlock("label", _hoisted_18, [
                  _createVNode(_component_SimpleText, { "text-key": "comparison.create.button-hint" })
                ])),
            _createElementVNode("button", {
              id: "submit-chosen-calculations",
              class: "button button-primary",
              type: "submit",
              disabled: this.validateComparisonListLength
            }, [
              _createVNode(_component_SimpleText, { "text-key": "comparison.create-comparison.submit-button.title" })
            ], 8, _hoisted_19)
          ])
        ])
      ], 544)
    ])
  ]))
}