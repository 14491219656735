<template>
  <div class="container px-0 py-9" id="wrapper-div" v-if="pageAccess">
    <h1>Validering af Emissionsfaktorer fra excelfil</h1>
    <div
      v-if="formValidation.formErrors.size"
      aria-atomic="true"
      class="alert alert-error"
      role="alert"
    >
      <div class="alert-body">
        <p class="alert-heading"><SimpleText text-key="sharedContent.formErrorTitle"/></p>
        <ul class="alert-text">
          <li
            v-for="[key, value] of formValidation.formErrors"
            :key="key"
            :data-cy="'error_' + key"
          >
            {{ value }}
          </li>
        </ul>
      </div>
    </div>

    <div class="col-md-3" style="padding-top: 20px; padding-bottom: 20px">
      <button style="display:block;width:300px; height:30px;" onclick="document.getElementById('upload-file2').click()">Vælg en excelfil for validering</button>
      <input type='file' id="upload-file2" style="display:none" @change="addFile">
    </div>

    <label class="form-label " for="sheetName">Excel fanenavn:</label>
    <input
      type="text"
      id="form-input-4"
      class="form-input"
      v-model="sheetName"
      maxlength="50"
      name="sheetName"
      :data-error="this.sheetNameError"
      :pattern="findRegexPattern('regex.validation.calculation-name')"
      required
    >
    <br>{{ this.excelFileName.fileName }}<br>
    <div class="d-flex justify-content-center mt-4">
      <button class="button button-secondary adjust-button-size" @click="validateFile">Valider</button>
    </div>



    <div v-if="validationErrors.length > 0">
      <input type="checkbox" id="show-warnings" v-model="showWarnings"  class="form-checkbox checkbox-medium">
      <label for="show-warnings" class="ml-2">Vis advarsler</label>
      <h2 v-if="this.excelFileName.fileName">Valideringslog. Fejl: {{validationErrors.filter((e) => e.name !== 'Advarsel').length}}, advarsler: {{validationErrors.filter((e) => e.name === 'Advarsel').length}}</h2>
      <h2 v-else>Valideringslog</h2>
      <div class="table--responsive-scroll" style="height: 550px; overflow: auto">
        <table class="table w-percent-100">
          <caption></caption>
          <thead>
          <tr>
            <th id="excel-file-name" colspan="5" v-if="this.excelFileName.fileName" style="text-align: center;">
              {{ this.excelFileName.fileName }}
            </th>
          </tr>
          </thead>
          <thead>
          <tr>
            <th style="width: 15%" id="validate_error_type">Type</th>
            <th style="width: 30%" id="validate_error_description">Beskrivelse</th>
            <th style="width: 55%" id="validate_error_value">Værdi</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in filteredItems" :key="item">
            <td style="width: 15%">{{ item.name }}</td>
            <td style="width: 30%">{{ item.value }}</td>
            <td style="width: 55%" class="align-text-left-max-size">{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
        {{ this.status }}
    </div>
    {{ this.deleteError }}
  </div>
  <div class="container px-0 py-9" v-else>Din brugerprofil har ikke adgang til dette skærmbillede</div>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import {configurationClient, emissionsfactorImportClient} from "@/shared/BackendService";
import DKFDS from "dkfds";
import {
  EmissionCoefficientOverview,
  FileMessage,
  ValidationError
} from "../../../openapi/generated-clients/climatecompass";
import { FormValidation } from '@/shared/FormValidation'


export default class EmissionFactorValidate extends BaseComponent {

  //variables
  excelFile = {} as File
  excelFileName = {} as Partial<FileMessage>
  formValidation = new FormValidation()

  validationErrors: ValidationError[] = []
  deleteError = ''
  status = ''
  pageAccess = false
  sheetName = ''
  showWarnings = true

  async beforeMount() {
    DKFDS.init()
    await configurationClient
        .getExcelValidationAccess()
        .then((response) => {
          this.pageAccess = response.data as unknown as boolean
        })
        .catch((error) => {
          console.error(error)
        })
  }

  get sheetNameError() {
    return "Der skal angives et excelfanenavn"
  }

  get filteredItems() {
    //console.log("filter items", this.showWarnings)
    if (this.showWarnings) {
      return this.validationErrors
    } else {
      return this.validationErrors.filter((e) => e.name !== 'Advarsel')
    }
  }

  async addFile(e: Event) {
    const element = e.target as HTMLInputElement
    if (!this.excelFile) {
      //this.excelFile = {}
    }
    if (!this.excelFileName) {
      this.excelFileName = {}
    }
    if (!element.files) return
    this.excelFile = element.files[0]
    this.excelFileName.fileName = element.files[0].name
    this.excelFileName.fileType = element.files[0].type
    element.form?.reset()
    this.formValidation.formErrors.clear()
    //await this.validateFile()
  }

  async validateFile() {
    this.formValidation.formErrors.clear()
    console.log('Excel file : ' + this.excelFileName.fileName, this.sheetName)
    this.status = 'Validerer...'
    let error = false
    if (this.excelFileName.fileName === undefined) {
      this.formValidation.formErrors.set("no-file", "Der skal vælges en Excel fil");
      error = true
    }
    if (!this.sheetName) {
      this.formValidation.formErrors.set("no-name", "Der skal angives et Excel fanenavn");
      error = true
    }
    if (!error) {
      this.validationErrors = (await emissionsfactorImportClient.validateExcel(this.excelFile, this.sheetName)).data || []
    }
    if (this.validationErrors.length === 0) {
      this.status = "OK"
    } else {
      this.status = ''
    }
  }

  /// ------ Async-Functions end ------///

  dummyUploadFile() {
    const uploadInput = document.getElementById(`map-fileinput`) as HTMLInputElement
    if (uploadInput) {
      uploadInput.click()
    }
  }
}

</script>

<style lang="scss" scoped>

.sql-log {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
}

#section1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

#section1 .form-group {
  flex: 1;
  margin-right: 10px;
}

#section2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

#button-section {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.align-text-left-max-size {
  word-wrap: break-word; /* Breaks long words into the next line */
  word-break: break-word; /* Ensures long, unbreakable text will break */
  overflow-wrap: anywhere; /* Allows breaks at any point for unbreakable strings */
  white-space: normal; /* Allows text wrapping (default behavior for most tables) */
  max-width: 250px; /* Set a maximum width for the cell */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis if text is truncated */
}

</style>
