<template>
  <div class='d-print-none'>
    <Chart
        ref="forecastChart"
        :width="width"
        height="350"
        type="bar"
        :series="chartOptions.series"
        :options="chartOptions"
        :class="additionalClass"
        @animation-end="focusChart(); prerenderPdfImage();"
        :id="id"
    >
    </Chart>
    <div
        v-if="(!settings || (settings & constants.FORECAST_FILTER_SCOPES) > 0 || (settings & constants.FORECAST_FILTER_REDUCTION_TARGET) > 0)"
        style="font-size: 12px; position:relative; top: -40px; margin-left: 77px;">
      * =
      <SimpleText :text-key="'forecast.resultview.chart.annotation.forecasted'"/>
    </div>
    <CypressTestElement id="forecast-column-chart-helper" :type="chartType" :state="chartIsUpdating"
                        :inner="filterSelections"></CypressTestElement>
  </div>
  <!-- Ensuring width and rendering of legend colors (exclusions not rendered correctly) -->
  <img style='max-width:  600px' :src='base64ChartImage' class='d-print-only' alt=''/>
</template>

<script lang="ts">
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import VueApexCharts, {VueApexChartsComponent} from 'vue3-apexcharts'
import {Options} from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {
  CategoryResult,
  GroupingCalculatedResult,
  GroupingCalculatedResults,
  MergedSubgroupingCalculatedResult,
  PurchaseGroupingCalculatedResult, SelectedCategory
} from '../../../openapi/generated-clients/climatecompass'
import {graphPrettify, prettify} from "@/shared/PrettifyNumbers";
import {Constants} from "@/shared/Constants";
import SimpleText from "@/components/SimpleText.vue";
import CypressTestElement from "@/components/testhelpers/CypressTestElement.vue";
import { setForecasts } from '@/store/mutations'

interface ChartColumn {
  name: string
  data: number[]
}

@Options({
  components: {
    CypressTestElement,
    Chart: VueApexCharts,
    SimpleText
  }
})
export default class ForecastColumnChart extends BaseComponent {
  @Prop({required: true})
  rawData?: GroupingCalculatedResults[] | undefined

  @Prop({required: false})
  rawDataReduction?: GroupingCalculatedResults | undefined

  @Prop()
  settings?: number

  @Prop({required: false})
  width = '100%'

  @Prop()
  isMaec = false

  @Prop()
  id?: string

  base64ChartImage = ''

  chartOptions: any
  additionalClass = ''

  annotationLabelText = ''
  percentage = 0

  // Cypress helpers
  chartIsUpdating = 'UPDATING'
  filterSelections = ''
  chartType = ''

  $refs!: {
    forecastChart: VueApexChartsComponent
  }
  calculatedPercentage = '0'

  beforeMount() {
    if (this.rawData) {
      if (!this.settings || (this.settings & Constants.FORECAST_FILTER_SCOPES) > 0) {
        this.chartType = 'SCOPES'
        this.chartOptions = this.getScopesChart(false, this.isMaec, undefined, undefined)
      } else if ((this.settings & Constants.FORECAST_FILTER_CATEGORIES) > 0) {
        this.chartType = 'MAINCATEGORIES'
        this.chartOptions = this.getMainCategoryChart()
      } else if ((this.settings & Constants.FORECAST_FILTER_STATICSCOPE) > 0) {
        this.chartOptions = this.getStaticScopeChart()
      } else if ((this.settings & Constants.FORECAST_FILTER_REDUCTION_TARGET) > 0) {
        this.chartOptions = this.getReductionTargetChart(this.isMaec)
      } else {
        this.chartOptions = this.getCategoryChart()
      }
    }
  }

  focusChart() {
    this.chartIsUpdating = 'RENDERED'
    if (document.documentElement.scrollTop === 0) {
      const chart = document.getElementById('forecast-column-chart')
      this.$nextTick(() => {
        if (chart) chart.scrollIntoView({block: "nearest", behavior: "smooth"})
      })
    }
  }

  updateChart(filter?: number) {
    this.chartIsUpdating = 'RENDERED'
    if (this.$refs.forecastChart && this.rawData) {
      if (!this.settings || (this.settings & Constants.FORECAST_FILTER_SCOPES) > 0) {
        this.chartType = 'SCOPES'
        this.$refs.forecastChart.updateOptions(this.getScopesChart(false, this.isMaec, undefined, undefined), true, true, true)
      } else if ((this.settings & Constants.FORECAST_FILTER_CATEGORIES) > 0) {
        this.chartType = 'MAINCATEGORIES'
        this.$refs.forecastChart.updateOptions(this.getMainCategoryChart(filter), true, true, true)
      } else if ((this.settings & Constants.FORECAST_FILTER_STATICSCOPE) > 0) {
        this.$refs.forecastChart.updateOptions(this.getStaticScopeChart(filter), true, true, true)
      } else if ((this.settings & Constants.FORECAST_FILTER_REDUCTION_TARGET) > 0) {
        this.$refs.forecastChart.updateOptions(this.getReductionTargetChart(this.isMaec), true, true, true)
      } else {
        this.$refs.forecastChart.updateOptions(this.getCategoryChart(filter), true, true, true)
      }
    }
  }

  updateReductionTargetByCategoryChart(selCatArray : SelectedCategory[], allCatSelected: boolean, isScope3MAEC: boolean) {
    const options = this.getScopesChart(true, isScope3MAEC, allCatSelected, selCatArray)
    this.$refs.forecastChart.updateOptions(options, true, true, true)


  }
  async prerenderPdfImage() {
    const apexChart = this.$refs.forecastChart.chart
    if (apexChart) {
      await apexChart.dataURI().then((x: any) => {
        this.base64ChartImage = x.imgURI
      })
    }
  }

  getStaticScopeChart(filter?: number) {
    if (!this.rawData) return undefined
    const chartOptions = {} as any

    const forecastYears: string[] = []
    const newSeriesScopeEnergy: number[] = []
    const newSeriesScopeProcurement: number[] = []
    const newSeriesScopeTransport: number[] = []
    const newSeriesScopeTrash: number[] = []
    const newSeriesScopeProducts: number[] = []
    const dummySeries1: number[] = []
    const dummySeries2: number[] = []
    //const firstYearTotal = this.rawData ? this.rawData[0].scoresTotalResult ?? 0 : 0
    let startYear = 2015
    let endYear = 2035
    let startValue = 0
    let endValue = 0

    const firstYear = this.rawData ? this.rawData[0] : {}
    const lastYear = this.rawData ? this.rawData[this.rawData.length - 1] : {}
    if (firstYear && firstYear.year && lastYear && lastYear.year) {
      startYear = firstYear.year ?? 0
      endYear = lastYear.year ?? 0
    }

    let scoresTotalResultEnd = 0
    // Starting value
    let startingYearTotal = 0
    const filterValue = this.settings

    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0) {
      scoresTotalResultEnd = lastYear?.ghgCalculatedResult?.scope1Result ?? 0
      startingYearTotal = firstYear?.ghgCalculatedResult?.scope1Result ?? 0

      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue = firstYear.ghgCalculatedResult?.scope1Result ?? 0
        endValue = lastYear.ghgCalculatedResult?.scope1Result ?? 0
      }

      this.chartType = 'SCOPE1'
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0) {
      scoresTotalResultEnd += lastYear?.ghgCalculatedResult?.scope2Result ?? 0
      startingYearTotal += firstYear?.ghgCalculatedResult?.scope2Result ?? 0

      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue += firstYear.ghgCalculatedResult?.scope2Result ?? 0
        endValue += lastYear.ghgCalculatedResult?.scope2Result ?? 0
      }
      this.chartType = 'SCOPE2'
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      scoresTotalResultEnd += lastYear?.ghgCalculatedResult?.scope3Result ?? 0
      startingYearTotal += firstYear?.ghgCalculatedResult?.scope3Result ?? 0

      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue += firstYear.ghgCalculatedResult?.scope3Result ?? 0
        endValue += lastYear.ghgCalculatedResult?.scope3Result ?? 0
      }
      this.chartType = 'SCOPE3'
    }

    [firstYear, lastYear].forEach(year => {
      if (year) {
        year.groupingResults?.forEach((grouping) => {
          switch (grouping.groupingId) {
            case 1:
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0)
                newSeriesScopeEnergy.push(Number(grouping.calculatedResult?.scope1Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0)
                newSeriesScopeEnergy.push(Number(grouping.calculatedResult?.scope2Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0)
                newSeriesScopeEnergy.push(Number(grouping.calculatedResult?.scope3Result))
              break
            case 2:
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0)
                newSeriesScopeProcurement.push(Number(grouping.calculatedResult?.scope1Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0)
                newSeriesScopeProcurement.push(Number(grouping.calculatedResult?.scope2Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0)
                newSeriesScopeProcurement.push(Number(grouping.calculatedResult?.scope3Result))
              break
            case 3:
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0)
                newSeriesScopeTransport.push(Number(grouping.calculatedResult?.scope1Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0)
                newSeriesScopeTransport.push(Number(grouping.calculatedResult?.scope2Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0)
                newSeriesScopeTransport.push(Number(grouping.calculatedResult?.scope3Result))
              break
            case 4:
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0)
                newSeriesScopeTrash.push(Number(grouping.calculatedResult?.scope1Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0)
                newSeriesScopeTrash.push(Number(grouping.calculatedResult?.scope2Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0)
                newSeriesScopeTrash.push(Number(grouping.calculatedResult?.scope3Result))
              break
            case 5:
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0)
                newSeriesScopeProducts.push(Number(grouping.calculatedResult?.scope1Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0)
                newSeriesScopeProducts.push(Number(grouping.calculatedResult?.scope2Result))
              if (filterValue && (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0)
                newSeriesScopeProducts.push(Number(grouping.calculatedResult?.scope3Result))
              break
          }
          //Append empty value to not display dummy series in chart
        })
        dummySeries1.push(0)
        dummySeries2.push(0)
      }
    })
    forecastYears.push(String(startYear))
    forecastYears.push(String(endYear))
    forecastYears.push(this.getContentfulString("forecast.columnchart.label.difference"))


    const newSeries = []
    const filteredColorOptions = []

    if (newSeriesScopeEnergy.length > 0 && newSeriesScopeEnergy.some(x => x != 0)) {
      filteredColorOptions.push(Constants.GROUPING1_COLOR_HEX)
      //  newSeriesScopeEnergy.push(0)
      newSeriesScopeEnergy.push(0)
      newSeriesScopeEnergy.push(0)
      newSeries.push({name: this.Contentful.findSimpleText('theme.text.category.energy'), data: newSeriesScopeEnergy})
    }
    if (newSeriesScopeProcurement.length > 0 && newSeriesScopeProcurement.some(x => x != 0)) {
      filteredColorOptions.push(Constants.GROUPING2_COLOR_HEX)
      // newSeriesScopeProcurement.push(0)
      newSeriesScopeProcurement.push(0)
      newSeriesScopeProcurement.push(0)
      newSeries.push({
        name: this.Contentful.findSimpleText('theme.text.category.purchase'),
        data: newSeriesScopeProcurement
      })
    }
    if (newSeriesScopeTransport.length > 0 && newSeriesScopeTransport.some(x => x != 0)) {
      filteredColorOptions.push(Constants.GROUPING3_COLOR_HEX)
      // newSeriesScopeTransport.push(0)
      newSeriesScopeTransport.push(0)
      newSeriesScopeTransport.push(0)
      newSeries.push({
        name: this.Contentful.findSimpleText('theme.text.category.transport'),
        data: newSeriesScopeTransport
      })
    }
    if (newSeriesScopeTrash.length > 0 && newSeriesScopeTrash.some(x => x != 0)) {
      filteredColorOptions.push(Constants.GROUPING4_COLOR_HEX)
      // newSeriesScopeTrash.push(0)
      newSeriesScopeTrash.push(0)
      newSeriesScopeTrash.push(0)
      newSeries.push({name: this.Contentful.findSimpleText('theme.text.category.waste'), data: newSeriesScopeTrash})
    }
    if (newSeriesScopeProducts.length > 0 && newSeriesScopeProducts.some(x => x != 0)) {
      filteredColorOptions.push(Constants.GROUPING5_COLOR_HEX)
      // newSeriesScopeProducts.push(0)
      newSeriesScopeProducts.push(0)
      newSeriesScopeProducts.push(0)
      newSeries.push({
        name: this.Contentful.findSimpleText('theme.text.category.soldproducts'),
        data: newSeriesScopeProducts
      })
    }


    const percentage = ((scoresTotalResultEnd - startValue) * 100 / startValue).toFixed(0)
    this.calculatedPercentage = percentage
    const upperResultLabel = '' + endYear + ': ' + graphPrettify(scoresTotalResultEnd) + ' ' + this.getContentfulString('forecast.columnchart.common.unit')
    const lowerResultLabel = '' + startYear + ': ' + graphPrettify(startValue) + ' ' + this.getContentfulString('forecast.columnchart.common.unit')
    let midResultLabel = '0%'
    if (startValue !== 0) {
      midResultLabel = (Number(percentage) > 0 ? '+' + prettify(Number(percentage)) : percentage) + '%'
    }
    const startValueIsNegative = startValue < 0
    const isPositivePercentage = Number(percentage) > 0
    const resultingForecastIsNegative = scoresTotalResultEnd < 0
    newSeries.push({name: '', data: dummySeries1})
    newSeries.push({
      name: this.getContentfulString('forecast.resultview.chart.annotation.difference'),
      data: dummySeries2
    })

    this.additionalClass = 'option2_' + newSeries.length


    // Append dummy series for "results"
    if (this.rawData && this.rawData[0]) {
      switch (true) {
        case startValueIsNegative && isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL);
          dummySeries1.push(startingYearTotal);
          filteredColorOptions.push(Constants.RGBA_IMPROVED);
          dummySeries2.push(scoresTotalResultEnd - startingYearTotal);
          break;

        case startValueIsNegative && !isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_WORSENED);
          dummySeries1.push(startingYearTotal);
          filteredColorOptions.push(Constants.RGBA_WORSENED);
          dummySeries2.push(scoresTotalResultEnd);
          break;

        case !startValueIsNegative && isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL);
          dummySeries1.push(startingYearTotal);
          filteredColorOptions.push(Constants.RGBA_WORSENED);
          dummySeries2.push(scoresTotalResultEnd - startingYearTotal);
          break;

        case !startValueIsNegative && resultingForecastIsNegative:
          filteredColorOptions.push(Constants.RGBA_IMPROVED);
          dummySeries1.push(startingYearTotal);
          filteredColorOptions.push(Constants.RGBA_IMPROVED);
          dummySeries2.push(scoresTotalResultEnd);
          break;

        default:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL);
          dummySeries1.push(scoresTotalResultEnd);
          filteredColorOptions.push(Constants.RGBA_IMPROVED);
          dummySeries2.push(startingYearTotal - scoresTotalResultEnd);
          break;
      }
    }


    chartOptions.xaxis = {
      type: 'category',
      categories: forecastYears
    }

    chartOptions.colors = filteredColorOptions
    chartOptions.series = newSeries
    chartOptions.yaxis = {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      labels: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    }

    const differenceIsLargerThan20Percent = Number(percentage) >= 20 || Number(percentage) <= -20

    chartOptions.annotations = {
      position: 'front',
      points: [
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: startValue,
          marker: {
            size: 2,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 65,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: 15,
            offsetX: 80,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? lowerResultLabel : '',
            style: {
              background: 'none',
              color: '#777',
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              textAlign: 'left',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        },
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: endValue,
          marker: {
            size: 2,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 65,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: 15,
            offsetX: 80,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? upperResultLabel : '',
            style: {
              textAlign: 'left',
              background: 'none',
              color: '#777',
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        },
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: (endValue - startValue) / 2 + startValue,
          marker: {
            size: 0,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 65,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: differenceIsLargerThan20Percent ? 16 : -6,
            offsetX: 80,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? midResultLabel : [upperResultLabel, midResultLabel, lowerResultLabel],
            style: {
              background: 'none',
              color: '#777',
              fontSize: differenceIsLargerThan20Percent ? '18px' : '14px',
              fontWeight: 800,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        }
      ],
    }
    return Object.assign(chartOptions, this.defaultScopesChartOptions)
  }

  getMainCategoryChart(filter?: number) {
    this.additionalClass = 'forecast-category-chart'
    if (!this.rawData) return
    const chartOptions = {} as any
    const startYearGroups = this.rawData[0].groupingResults?.sort((a, b) => (a.groupingId ?? 0) - (b.groupingId ?? 0))
    const startYear = this.rawData[0].year
    const endYear = this.rawData[this.rawData.length - 1].year
    const endYearGroups = this.rawData[this.rawData.length - 1].groupingResults?.sort((a, b) => (a.groupingId ?? 0) - (b.groupingId ?? 0))
    const categories = [] as string[]
    const newSeriesScope1 = [] as any[]
    const newSeriesScope2 = [] as any[]
    const newSeriesScope3 = [] as any[]
    const colorOptions = [] as string[]

    startYearGroups?.forEach((grouping: GroupingCalculatedResult) => {
      newSeriesScope1.push(grouping.calculatedResult?.scope1Result)
      newSeriesScope2.push(grouping.calculatedResult?.scope2Result)
      newSeriesScope3.push(grouping.calculatedResult?.scope3Result)

      categories.push(
          (this.groupingIdToContentfulString(grouping.groupingId ?? 0) + ' ' + (startYear ?? '')).trim(),
          (this.groupingIdToContentfulString(grouping.groupingId ?? 0) + ' ' + (endYear ?? '')).trim()
      );
      // Add empty column
      categories.push('')
      if (endYearGroups) {
        endYearGroups.forEach((endYearGrouping: GroupingCalculatedResult) => {
          if (endYearGrouping.groupingId === grouping.groupingId) {
            newSeriesScope1.push(endYearGrouping.calculatedResult?.scope1Result)
            newSeriesScope2.push(endYearGrouping.calculatedResult?.scope2Result)
            newSeriesScope3.push(endYearGrouping.calculatedResult?.scope3Result)
          }
        })
      }

      //Add empty column
      newSeriesScope1.push(0)
      newSeriesScope2.push(0)
      newSeriesScope3.push(0)
    })

    const newSeries = []

    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE1) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
    }
    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE2) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
    }
    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
    }
    if (filter
        && (filter & Constants.FORECAST_FILTER_SCOPE1) === 0
        && (filter & Constants.FORECAST_FILTER_SCOPE2) === 0
        && (filter & Constants.FORECAST_FILTER_SCOPE3) === 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
    }

    this.filterSelections = newSeries.map(series => {
      return series.name
    }).join(',')

    chartOptions.xaxis = {
      type: 'category',
      categories: categories
    }

    chartOptions.yaxis = {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      labels: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    }

    chartOptions.series = newSeries

    return Object.assign(chartOptions, this.defaultCategoryChartOptions)
  }

  getCategoryChart(filter?: number) {
    this.additionalClass = 'forecast-category-chart'
    if (!this.rawData) return
    const chartOptions = {} as any
    const startYearGroups = this.rawData[0].groupingResults
    const startYear = this.rawData[0].year
    const endYear = this.rawData[this.rawData.length - 1].year
    const endYearGroups = this.rawData[this.rawData.length - 1].groupingResults
    const categories = [] as (string | string[])[]
    const newSeriesScope1 = [] as any[]
    const newSeriesScope2 = [] as any[]
    const newSeriesScope3 = [] as any[]
    const colorOptions = [] as string[]

    let selectedGroupingId = 0
    if (filter) {
      if ((filter & Constants.FORECAST_FILTER_CAT_ENERGY) > 0) {
        selectedGroupingId = 1
        this.chartType = 'ENERGY'
      }
      if ((filter & Constants.FORECAST_FILTER_CAT_PROCUREMENT) > 0) {
        selectedGroupingId = 2
        this.chartType = 'PROCUREMENT'
      }
      if ((filter & Constants.FORECAST_FILTER_CAT_TRANSPORT) > 0) {
        selectedGroupingId = 3
        this.chartType = 'TRANSPORT'
      }
      if ((filter & Constants.FORECAST_FILTER_CAT_TRASH) > 0) {
        selectedGroupingId = 4
        this.chartType = 'TRASH'
      }
      if ((filter & Constants.FORECAST_FILTER_CAT_PRODUCTS) > 0) {
        selectedGroupingId = 5
        this.chartType = 'PRODUCTS'
      }
    }


    startYearGroups?.filter(x => x.groupingId === selectedGroupingId).forEach((grouping: GroupingCalculatedResult) => {
      if (selectedGroupingId === 2) {
        grouping.purchaseGroupingCalculatedResults?.forEach((purchaseGroup: PurchaseGroupingCalculatedResult, index) => {
          purchaseGroup.mergedSubgroupingsCalculated?.forEach((subgrouping: (MergedSubgroupingCalculatedResult), y) => {

            // Add categories to x-axis ONCE
            const primaryOrSecondary = index === 0
                ? "(" + this.getContentfulString('text.primary') + ")"
                : "(" + this.getContentfulString('text.secondary') + ")";

            categories.push(this.categoryStringFormatter(
                this.publicCategoryGroupForecastColumnChartPurchase(subgrouping.mergedSubgroupingName ?? '', grouping.groupingName ?? '')
                + ' ' + primaryOrSecondary + ' ' + (startYear ?? ''),
            ));
            categories.push(this.categoryStringFormatter(
                this.publicCategoryGroupForecastColumnChartPurchase(subgrouping.mergedSubgroupingName ?? '', grouping.groupingName ?? '')
                + ' ' + primaryOrSecondary + ' ' + (endYear ?? '')
            ));

            // Add empty column
            categories.push('')

            newSeriesScope1.push(subgrouping.calculatedResult?.scope1Result)
            newSeriesScope2.push(subgrouping.calculatedResult?.scope2Result)
            newSeriesScope3.push(subgrouping.calculatedResult?.scope3Result)

            if (endYearGroups) {
              endYearGroups.forEach((endYearGrouping: GroupingCalculatedResult) => {
                if (endYearGrouping.groupingId === selectedGroupingId) {
                  endYearGrouping.purchaseGroupingCalculatedResults?.forEach((purchaseGroup: PurchaseGroupingCalculatedResult, t) => {
                    if (t === index) {
                      purchaseGroup.mergedSubgroupingsCalculated?.forEach((endYearSubGrouping: MergedSubgroupingCalculatedResult, c) => {
                        if (c === y) {
                          newSeriesScope1.push(endYearSubGrouping.calculatedResult?.scope1Result)
                          newSeriesScope2.push(endYearSubGrouping.calculatedResult?.scope2Result)
                          newSeriesScope3.push(endYearSubGrouping.calculatedResult?.scope3Result)
                        }
                      })
                    }
                  })

                }
              })
            }

            //Add empty column
            newSeriesScope1.push(0)
            newSeriesScope2.push(0)
            newSeriesScope3.push(0)
          })
        })

      } else {
        grouping?.mergedSubgroupingsCalculated?.forEach((subgrouping: (MergedSubgroupingCalculatedResult), y) => {
          // Add categories to x-axis ONCE
          categories.push(this.categoryStringFormatter(
              this.publicCategoryGroup(subgrouping.mergedSubgroupingName ?? '', grouping.groupingName ?? '') + ' ' + (startYear ?? '')
          ));

          categories.push(this.categoryStringFormatter(
              this.publicCategoryGroup(subgrouping.mergedSubgroupingName ?? '', grouping.groupingName ?? '') + ' ' + (endYear ?? '')
          ));

          // Add empty column
          categories.push('')

          newSeriesScope1.push(subgrouping.calculatedResult?.scope1Result)
          newSeriesScope2.push(subgrouping.calculatedResult?.scope2Result)
          newSeriesScope3.push(subgrouping.calculatedResult?.scope3Result)

          if (endYearGroups) {
            endYearGroups.forEach((endYearGrouping: GroupingCalculatedResult) => {
              if (endYearGrouping.groupingId === selectedGroupingId) {
                endYearGrouping.mergedSubgroupingsCalculated?.forEach((endYearSubGrouping: MergedSubgroupingCalculatedResult, c) => {
                  if (c === y) {
                    newSeriesScope1.push(endYearSubGrouping.calculatedResult?.scope1Result)
                    newSeriesScope2.push(endYearSubGrouping.calculatedResult?.scope2Result)
                    newSeriesScope3.push(endYearSubGrouping.calculatedResult?.scope3Result)
                  }
                })
              }
            })
          }

          //Add empty column
          newSeriesScope1.push(0)
          newSeriesScope2.push(0)
          newSeriesScope3.push(0)
        })
      }

    })

    const newSeries = []

    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE1) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
    }
    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE2) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
    }
    if (!filter || (filter & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
    }
    if (filter
        && (filter & Constants.FORECAST_FILTER_SCOPE1) === 0
        && (filter & Constants.FORECAST_FILTER_SCOPE2) === 0
        && (filter & Constants.FORECAST_FILTER_SCOPE3) === 0) {
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
    }
    this.filterSelections = newSeries.map(series => {
      return series.name
    }).join(',')
    chartOptions.xaxis = {
      type: 'category',
      categories: categories
    }

    chartOptions.yaxis = {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      labels: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    }

    chartOptions.series = newSeries

    return Object.assign(chartOptions, this.defaultCategoryChartOptions)
  }

  getReductionTargetChart(isMaec: boolean | undefined) {
    return this.getScopesChart(true, isMaec, false, undefined)
  }

  // get sum S3 not selected for a year
  getScope3SumNoSelected(forecastYear: GroupingCalculatedResults, selCatArray : SelectedCategory[], allSelected: boolean | undefined) : number {
    let sumNoSelected = 0
    if (allSelected) {
      return 0
    } else {
      forecastYear.categoryResults?.forEach((res: CategoryResult) => {
        let hit = false
        selCatArray.forEach((sel: SelectedCategory) => {
          if (res.categoryName === sel.code) {
            hit = true
          }
        })
        if (!hit) {
          if (forecastYear.year === 2020) {
            //console.log("case 1 or 3, sum", res.categoryName, "res:", res.categoryResult, "forecastYear:", forecastYear)
          }
          sumNoSelected += res.categoryResult ?? 0
        }
      })
      return sumNoSelected
    }
  }

  getScope3SumSelected(forecastYear: GroupingCalculatedResults, selCatArray : SelectedCategory[], allSelected: boolean | undefined) : number {
    let sumSelected = 0
    if (allSelected) {
      return forecastYear.ghgCalculatedResult?.scope3Result?? 0
    } else {
      forecastYear.categoryResults?.forEach((res: CategoryResult) => {
        let hit = false
        selCatArray.forEach((sel: SelectedCategory) => {
          if (res.categoryName === sel.code) {
            hit = true
          }
        })
        if (hit) {
          sumSelected += res.categoryResult ?? 0
        }
      })
      return sumSelected
    }
  }

  getScopesChart(reduction = false, isMaec = false, allCatSelected : boolean | undefined, selCatArray : SelectedCategory[] | undefined) {
    console.log("getScopesChart reduction:", reduction, "isMAEC:", isMaec, "allCatSel:", allCatSelected, "selCat:", selCatArray,
      "rawDataReduction:", this.rawDataReduction, "rawData:",this.rawData, "filterValue:", this.settings)
    if (!this.rawData) return
    const chartOptions = {} as any

    const forecastYears: string[] = []
    const newSeriesScope1: number[] = []
    const newSeriesScope2: number[] = []
    const newSeriesScope3: number[] = []
    const newSeriesScope3NoReduction: number[] = []
    const newSeriesScope3Reduction: number [] = []
    const dummySeriesReduction: number[] = []
    const dummySeries1: number[] = []
    const dummySeries2: number[] = []
    const firstYearTotal = this.rawData ? this.rawData[0].scoresTotalResult ?? 0 : 0
    let startYear = 2015
    let endYear = 2035
    let startValue = 0
    let endValue = 0

    const firstYear = this.rawData ? this.rawData[0] : {}
    const lastYear = this.rawData ? this.rawData[this.rawData.length - 1] : {}
    if (firstYear && firstYear.year && lastYear && lastYear.year) {
      startYear = firstYear.year ?? 0
      endYear = lastYear.year ?? 0
    }

    let scoresTotalResultEnd = 0
    // Starting value
    let startingYearTotal = 0
    let reductionTarget = 0
    const filterValue = this.settings

    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0) {
      scoresTotalResultEnd = lastYear?.ghgCalculatedResult?.scope1Result ?? 0
      if (reduction && this.rawDataReduction) {
        reductionTarget += this.rawDataReduction.ghgCalculatedResult?.scope1Result ?? 0
      }
      startingYearTotal = reduction ? lastYear?.ghgCalculatedResult?.scope1Result ?? 0 : firstYear?.ghgCalculatedResult?.scope1Result ?? 0
      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue = firstYear.ghgCalculatedResult?.scope1Result ?? 0
        endValue = lastYear.ghgCalculatedResult?.scope1Result ?? 0
      }
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0) {
      scoresTotalResultEnd += lastYear?.ghgCalculatedResult?.scope2Result ?? 0
      startingYearTotal += reduction ? lastYear?.ghgCalculatedResult?.scope2Result ?? 0 : firstYear?.ghgCalculatedResult?.scope2Result ?? 0
      if (reduction && this.rawDataReduction) {
        reductionTarget += this.rawDataReduction.ghgCalculatedResult?.scope2Result ?? 0
      }
      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue += firstYear.ghgCalculatedResult?.scope2Result ?? 0
        endValue += lastYear.ghgCalculatedResult?.scope2Result ?? 0
      }
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      scoresTotalResultEnd += reduction && isMaec ? lastYear?.ghgCalculatedResult?.scope3ResultReduction ?? 0 : lastYear?.ghgCalculatedResult?.scope3Result ?? 0
      if (reduction && isMaec) {
        startingYearTotal += lastYear?.ghgCalculatedResult?.scope3ResultReduction ?? 0
      } else {
        startingYearTotal += reduction ? lastYear?.ghgCalculatedResult?.scope3Result ?? 0 : firstYear?.ghgCalculatedResult?.scope3Result ?? 0
      }
      if (reduction && this.rawDataReduction) {
        if (isMaec) {
          reductionTarget += this.rawDataReduction.ghgCalculatedResult?.scope3ResultReduction ?? 0
        } else {
          reductionTarget += this.rawDataReduction.ghgCalculatedResult?.scope3Result ?? 0
        }
      }
      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue += reduction && isMaec ? firstYear.ghgCalculatedResult?.scope3ResultReduction ?? 0 : firstYear.ghgCalculatedResult?.scope3Result ?? 0
        endValue += reduction && isMaec ? lastYear.ghgCalculatedResult?.scope3ResultReduction ?? 0 : lastYear.ghgCalculatedResult?.scope3Result ?? 0
      }
    }
    // none

    //Udregning til sammenligningsgrafen, hvis ingen er valgt
    if (filterValue
        && (filterValue & Constants.FORECAST_FILTER_SCOPE1) === 0
        && (filterValue & Constants.FORECAST_FILTER_SCOPE2) === 0
        && (filterValue & Constants.FORECAST_FILTER_SCOPE3) === 0) {
      scoresTotalResultEnd = lastYear?.ghgCalculatedResult?.scope1Result ?? 0
      startingYearTotal = firstYear?.ghgCalculatedResult?.scope1Result ?? 0

      if (firstYear && firstYear.ghgCalculatedResult && lastYear && lastYear.ghgCalculatedResult) {
        startValue = firstYear.ghgCalculatedResult?.scope1Result ?? 0
        endValue = lastYear.ghgCalculatedResult?.scope1Result ?? 0
        scoresTotalResultEnd += lastYear?.ghgCalculatedResult?.scope2Result ?? 0
        startingYearTotal += firstYear?.ghgCalculatedResult?.scope2Result ?? 0
      }

      if (firstYear && firstYear.ghgCalculatedResult) {
        startValue += firstYear.ghgCalculatedResult?.scope2Result ?? 0
        endValue += lastYear.ghgCalculatedResult?.scope2Result ?? 0
        scoresTotalResultEnd += lastYear?.ghgCalculatedResult?.scope3Result ?? 0
        startingYearTotal += firstYear?.ghgCalculatedResult?.scope3Result ?? 0

        startValue += firstYear.ghgCalculatedResult?.scope3Result ?? 0
        endValue += lastYear.ghgCalculatedResult?.scope3Result ?? 0
      }
    }

    this.rawData?.forEach((forecastYear: GroupingCalculatedResults, index) => {
      //forecastYears.push(String(forecastYear.year) + (index > 0 ? '*' : ''))
      forecastYears.push(String(forecastYear.year) + (forecastYear.isForecastedData ? '*' : ''))
      newSeriesScope1.push(Number(forecastYear.ghgCalculatedResult?.scope1Result))
      newSeriesScope2.push(Number(forecastYear.ghgCalculatedResult?.scope2Result))
      newSeriesScope3.push(Number(forecastYear.ghgCalculatedResult?.scope3Result))
      if (reduction && (filterValue ?? 0 & Constants.FORECAST_FILTER_SCOPE3) > 0) {
        //console.log("years", forecastYear)
        if (isMaec) {
          if (allCatSelected) {
            // Hvis den valgte målsætningsmetode for scope 3 er "Mininum Absolute Emissions (MAEC)" OG alle scope 3 kategorier er tilvalgt,
            // viser de grønne søjler 67% af udledningen for hele scope 3
            newSeriesScope3Reduction.push(Number(forecastYear.ghgCalculatedResult?.scope3ResultReduction))
            // 2. Hvis den valgte målsætningsmetode for scope 3 er "Mininum Absolute Emissions (MAEC)" OG alle scope 3 kategorier er tilvalgt fylder
            // den lysegrå søjle 33% af udledningen for hele scope 3  i målsætningsåret.
            newSeriesScope3NoReduction.push((forecastYear.ghgCalculatedResult?.scope3Result?? 0) * 0.33)
          } else if (selCatArray && !allCatSelected) {
            //Hvis den valgte målsætningsmetode for scope 3 er "Mininum Absolute Emissions (MAEC)" OG mindst én scope 3 kategorier er fravalgt,
            // viser de grønne søjler summen af de udledninger for de tilvalgt scope 3 kategorier.
            newSeriesScope3Reduction.push(this.getScope3SumSelected(forecastYear, selCatArray, allCatSelected))
            // 1. Hvis den valgte målsætningsmetode for scope 3 er "Mininum Absolute Emissions (MAEC)" OG mindst én scope 3 kategorier er fravalgt
            // svarer de lysegrå søjler til summen af udledninger for alle fravalgt scope 3 kategorier  i målsætningsåret.
            newSeriesScope3NoReduction.push(this.getScope3SumNoSelected(forecastYear, selCatArray, allCatSelected))
          } else {
            newSeriesScope3Reduction.push(Number(forecastYear.ghgCalculatedResult?.scope3ResultReduction))
            newSeriesScope3NoReduction.push(Number(forecastYear.ghgCalculatedResult?.scope3ResultNoReduction))
          }
        } else {
          if (selCatArray) {
            // Hvis den valgte målsætningsmetode for scope 3 er "Manuel", viser de grønne søjler summen af de udledninger for de tilvalgte scope 3 kategorier.
            newSeriesScope3Reduction.push(this.getScope3SumSelected(forecastYear, selCatArray, allCatSelected))
            // 3. Hvis den valgte målsætningsmetode for scope 3 er "Manuel" svarer den lysegrå søjle altid til summen af udledninger
            // for alle fravalgt scope 3 kategorier  i målsætningsåret.
            newSeriesScope3NoReduction.push(this.getScope3SumNoSelected(forecastYear, selCatArray, allCatSelected))
          }
        }
      }
      //Append empty value to not display dummy series in chart
      dummySeriesReduction.push(0)
      dummySeries1.push(0)
      dummySeries2.push(0)
    })

    if (reduction && this.rawDataReduction) {
      dummySeries1.push(0)
      dummySeries2.push(0)
      forecastYears.push(this.getContentfulString('forecast.columnchart.label.target') + ' ' + this.rawDataReduction.year)
      newSeriesScope1.push(0)
      newSeriesScope2.push(0)
      newSeriesScope3.push(0)
      if (reduction && (isMaec || (!isMaec && selCatArray)) && (filterValue ?? 0 & Constants.FORECAST_FILTER_SCOPE3) > 0) {
        newSeriesScope3NoReduction.push(0)
        newSeriesScope3Reduction.push(0)
      }
    }
    forecastYears.push(this.getContentfulString("forecast.columnchart.label.difference"))
    forecastYears.push('')
    forecastYears.push('')
    newSeriesScope1.push(0)
    newSeriesScope2.push(0)
    newSeriesScope3.push(0)
    if (reduction && (isMaec || (!isMaec && selCatArray)) && (filterValue ?? 0 & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      newSeriesScope3NoReduction.push(0)
      newSeriesScope3Reduction.push(0)
    }
    newSeriesScope1.push(0)
    newSeriesScope2.push(0)
    newSeriesScope3.push(0)
    if (reduction && (isMaec || (!isMaec && selCatArray)) && (filterValue ?? 0 & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      newSeriesScope3NoReduction.push(0)
      newSeriesScope3Reduction.push(0)
    }

    chartOptions.xaxis = {
      type: 'category',
      categories: forecastYears
    }
    const newSeries = []
    const filteredColorOptions = []
    // Use of bitwise comparison - google the single & operator
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE1) > 0) {
      filteredColorOptions.push(Constants.SCOPE1_COLOR_HEX)
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE2) > 0) {
      filteredColorOptions.push(Constants.SCOPE2_COLOR_HEX)
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
    }
    if (!filterValue || (filterValue & Constants.FORECAST_FILTER_SCOPE3) > 0) {
      if (reduction && (isMaec || (!isMaec && selCatArray))) {
//      if (reduction && isMaec) {
        filteredColorOptions.push(Constants.SCOPE3_COLOR_HEX)
        filteredColorOptions.push(Constants.COLOR_GRAY_HEX)
        newSeries.push({
          name: this.Contentful.findSimpleText('forecast.columnchart.label.scope3-maec'),
          data: newSeriesScope3Reduction
        })
        newSeries.push({
          name: this.Contentful.findSimpleText('forecast.columnchart.label.outside-scope3-maec'),
          data: newSeriesScope3NoReduction
        })
      } else {
        filteredColorOptions.push(Constants.SCOPE3_COLOR_HEX)
        newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
      }
    }
    if (filterValue
        && (filterValue & Constants.FORECAST_FILTER_SCOPE1) === 0
        && (filterValue & Constants.FORECAST_FILTER_SCOPE2) === 0
        && (filterValue & Constants.FORECAST_FILTER_SCOPE2) === 0
        && (filterValue & Constants.FORECAST_FILTER_SCOPE3) === 0) {
      filteredColorOptions.push(Constants.SCOPE1_COLOR_HEX)
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope1.text'), data: newSeriesScope1})
      filteredColorOptions.push(Constants.SCOPE2_COLOR_HEX)
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope2.text'), data: newSeriesScope2})
      filteredColorOptions.push(Constants.SCOPE3_COLOR_HEX)
      newSeries.push({name: this.Contentful.findSimpleText('forecast.resultview.scope3.text'), data: newSeriesScope3})
    }

    this.filterSelections = newSeries.map(series => {
      return series.name
    }).join(',')
    let percentage = startValue > 0 ? ((scoresTotalResultEnd - startValue) * 100 / startValue).toFixed(0) : '0'
    if (reduction) {
      if (scoresTotalResultEnd < 0) {
        //Converting the display %difference on graph as the initial value, but as a negative. This is due to the percentage calculation being correct, but the displayed value needs to be as (-),
        //since in this case, scoresTotalResultEnd is also a negative.
        percentage = (-((scoresTotalResultEnd - reductionTarget) / scoresTotalResultEnd * 100)).toFixed(0)
      } else if (scoresTotalResultEnd != 0) {
        percentage = ((scoresTotalResultEnd - reductionTarget) / scoresTotalResultEnd * 100).toFixed(0)
      } else {
        percentage = scoresTotalResultEnd < 0 ? "-100" : "100"
      }
    }
    const upperResultLabel = endYear + ': ' + graphPrettify(scoresTotalResultEnd) + ' ' + this.getContentfulString('forecast.columnchart.common.unit')
    const lowerResultLabel = (reduction ? this.getContentfulString('forecast.reduction-target.target') + ': ' : '' + startYear + ': ')
        + graphPrettify(reduction ? reductionTarget : startValue) + ' ' +
        this.getContentfulString('forecast.columnchart.common.unit')

    const midResultLabel = (Number(percentage) > 0 ? '+' + Number(percentage) : percentage) + '%'

    const startValueIsNegative = startValue < 0
    const isPositivePercentage = Number(percentage) > 0
    const resultingForecastIsNegative = scoresTotalResultEnd < 0

    newSeries.push({name: '', data: dummySeries1})
    newSeries.push({
      name: this.getContentfulString('forecast.resultview.chart.annotation.difference'),
      data: dummySeries2
    })

    if (reduction && this.rawDataReduction) {
      dummySeriesReduction.push(reductionTarget)
      newSeries.push({
        name: this.getContentfulString('forecast.columnchart.label.target') + ' ' + this.rawDataReduction.year,
        data: dummySeriesReduction
      })
    }

    // Append dummy series for "results"

    if (this.rawData && this.rawData[0]) {
      switch (true) {
        case startValueIsNegative && isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL)
          dummySeries1.push(startingYearTotal)
          filteredColorOptions.push(Constants.RGBA_IMPROVED)
          dummySeries2.push(scoresTotalResultEnd - startingYearTotal)
          break

        case startValueIsNegative && !isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_WORSENED)
          dummySeries1.push(startingYearTotal)
          filteredColorOptions.push(Constants.RGBA_WORSENED)
          dummySeries2.push(scoresTotalResultEnd)
          break

        case !startValueIsNegative && resultingForecastIsNegative:
          filteredColorOptions.push(Constants.RGBA_IMPROVED)
          dummySeries1.push(reduction ? scoresTotalResultEnd : startingYearTotal)
          filteredColorOptions.push(Constants.RGBA_IMPROVED)
          dummySeries2.push(reduction ? reductionTarget : scoresTotalResultEnd)
          break

        case !startValueIsNegative && isPositivePercentage:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL)
          dummySeries1.push(reduction ? reductionTarget : startingYearTotal)
          filteredColorOptions.push(Constants.RGBA_WORSENED)
          dummySeries2.push(reduction ? scoresTotalResultEnd - reductionTarget : scoresTotalResultEnd - startingYearTotal)
          break

        default:
          filteredColorOptions.push(Constants.RGBA_NEUTRAL)
          dummySeries1.push(scoresTotalResultEnd)
          filteredColorOptions.push(Constants.RGBA_IMPROVED)
          dummySeries2.push(reduction ? reductionTarget - scoresTotalResultEnd : startingYearTotal - scoresTotalResultEnd)
          break
      }
    }
    if (reduction) {
      filteredColorOptions.push(Constants.REDUCTION_COLOR_HEX)
    }

    dummySeriesReduction.push(0)
    dummySeriesReduction.push(0)
    dummySeries1.push(0)
    dummySeries2.push(0)
    dummySeries1.push(0)
    dummySeries2.push(0)

    this.additionalClass = (reduction ? "reduction_" : "") + 'option_' + newSeries.length


    chartOptions.colors = filteredColorOptions
    chartOptions.series = newSeries
    chartOptions.yaxis = {
      title: {
        text: this.getContentfulString('shared.ton-co2e.no-format'),
      },
      labels: {
        formatter: (value: number) => {
          return graphPrettify(value)
        }
      }
    }

    const differenceIsLargerThan20Percent = Number(percentage) >= 20 || Number(percentage) <= -20

    chartOptions.annotations = {
      position: 'front',
      points: [
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: reduction ? reductionTarget : startValue,
          marker: {
            size: 2,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 30,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: 15,
            offsetX: 45,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? lowerResultLabel : '',
            style: {
              background: 'none',
              color: '#777',
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              textAlign: 'left',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        },
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: endValue,
          marker: {
            size: 2,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 30,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: 15,
            offsetX: 45,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? upperResultLabel : '',
            style: {
              textAlign: 'left',
              background: 'none',
              color: '#777',
              fontSize: '14px',
              fontWeight: 600,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        },
        {
          x: this.getContentfulString("forecast.columnchart.label.difference"),
          y: reduction ? (endValue - reductionTarget) / 2 + reductionTarget : (endValue - startValue) / 2 + startValue,
          marker: {
            size: 0,
            fillColor: "#fff",
            strokeColor: "#333",
            strokeWidth: 3,
            shape: "circle",
            radius: 2,
            offsetY: 0,
            offsetX: 30,
            cssClass: '',
          },
          label: {
            textAnchor: 'start',
            offsetY: differenceIsLargerThan20Percent ? 16 : -6,
            offsetX: 45,
            borderColor: '#c2c2c2',
            borderWidth: 0,
            borderRadius: 2,
            text: differenceIsLargerThan20Percent ? midResultLabel : [upperResultLabel, midResultLabel, lowerResultLabel],
            style: {
              background: 'none',
              color: '#777',
              fontSize: differenceIsLargerThan20Percent ? '18px' : '14px',
              fontWeight: 800,
              fontFamily: undefined,
              cssClass: 'apexcharts-point-annotation-label',
              padding: {
                left: 5,
                right: 5,
                top: 0,
                bottom: 2,
              }
            },
          }
        }
      ],
    }
    return Object.assign(chartOptions, this.defaultScopesChartOptions)
  }

  defaultScopesChartOptions = {

    animations: {
      enabled: true
    },
    dataLabels: {
      formatter: function (value: string, {seriesIndex, dataPointIndex, w}: any) {
        if (value === '0') return ''
        const maxDataPoints = w.config.series[0].data.length - 1
        const maxSeriesIndex = w.config.series.length - 1
        if (seriesIndex <= maxSeriesIndex) {
          if (dataPointIndex < maxDataPoints - 1) {
            graphPrettify(Number(value), true)
          } else {
            return ''
          }
        }
        return graphPrettify(Number(value), true)
      }
    },
    style: {
      colors: ['#FFF'],
    },
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 900,

      options: {
        dataLabels: {
          style: {
            fontSize: '10px'
          },
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        style: {
          fontSize: '10px'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                style: {
                  fontSize: '11px',
                  fontWeight: 600
                },
              }
            },
          },
        },
      }
    }, {
      breakpoint: 480,
      options: {
        dataLabels: {
          style: {
            fontSize: '9px'
          },
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        style: {
          fontSize: '9px'
        }
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 600,
            },
            offsetY: -5,

          }
        },
      },
    },
    legend: {
      position: 'bottom',
    },
    fill: {
      opacity: 1,
    }
  }
  defaultCategoryChartOptions = {
    dataLabels: {
      formatter: function (value: number, {seriesIndex, dataPointIndex, w}: any) {
        return ((dataPointIndex - 1) % 3 === 1) ? '' : graphPrettify(value, true)
      },
      style: {
        colors: ['#FFF']
      },
    },
    animations: {
      enabled: true
    },
    chart: {
      fontFamily: 'IBM Plex Sans, system',
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true
      },
      zoom: {
        enabled: true
      }
    },
    responsive: [{
      breakpoint: 900,

      options: {
        dataLabels: {
          style: {
            fontSize: '10px'
          },
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        style: {
          fontSize: '10px'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                style: {
                  fontSize: '11px',
                  fontWeight: 600
                },
              }
            },
          },
        },
      }
    }, {
      breakpoint: 480,

      options: {
        dataLabels: {
          style: {
            fontSize: '9px'
          },
        },
        legend: {
          position: 'bottom',
          offsetX: -10,
          offsetY: 0
        },
        style: {
          fontSize: '9px'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              total: {
                style: {
                  fontSize: '10px',
                  fontWeight: 400
                },
              }
            },
          },
        },
      }
    }],
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 600
            },
            offsetY: -5,
          }
        },
      },
    },
    legend: {
      position: 'bottom',
      show: true,
      showForSingleSeries: true,
    },
    fill: {
      opacity: 1,
    },
    colors: [
      Constants.SCOPE1_COLOR_HEX,
      Constants.SCOPE2_COLOR_HEX,
      Constants.SCOPE3_COLOR_HEX
    ]
  }


}
</script>
<style lang="scss">
//Ugly css hack to hide dummy series
.option2_7 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(6),
    div.apexcharts-legend-series:nth-of-type(7) {
      display: none;
    }
  }
}

.option2_6 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(5),
    div.apexcharts-legend-series:nth-of-type(6),
    div.apexcharts-legend-series:nth-of-type(7) {
      display: none;
    }
  }
}

.option2_5 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(4),
    div.apexcharts-legend-series:nth-of-type(5),
    div.apexcharts-legend-series:nth-of-type(6),
    div.apexcharts-legend-series:nth-of-type(7) {
      display: none;
    }
  }
}

.option2_4 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(3),
    div.apexcharts-legend-series:nth-of-type(4),
    div.apexcharts-legend-series:nth-of-type(5),
    div.apexcharts-legend-series:nth-of-type(6),
    div.apexcharts-legend-series:nth-of-type(7) {
      display: none;
    }
  }
}

.option2_3 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(2),
    div.apexcharts-legend-series:nth-of-type(3),
    div.apexcharts-legend-series:nth-of-type(4),
    div.apexcharts-legend-series:nth-of-type(5),
    div.apexcharts-legend-series:nth-of-type(6),
    div.apexcharts-legend-series:nth-of-type(7) {
      display: none;
    }
  }
}

.option_5 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(4), div.apexcharts-legend-series:nth-of-type(5) {
      display: none;
    }
  }
}

.reduction_option_5 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(3) {
      display: none;
    }
  }
}

.option_4 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(3), div.apexcharts-legend-series:nth-of-type(4), div.apexcharts-legend-series:nth-of-type(5) {
      display: none;
    }
  }
}

.reduction_option_4 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(2) {
      display: none;
    }
  }
}

.option_3 {
  div.apexcharts-legend {
    div.apexcharts-legend-series:nth-of-type(2), div.apexcharts-legend-series:nth-of-type(3), div.apexcharts-legend-series:nth-of-type(4), div.apexcharts-legend-series:nth-of-type(5) {
      display: none;
    }
  }
}

.apexcharts-point-annotation-label > tspan:first-of-type, .apexcharts-point-annotation-label > tspan:last-of-type {
  font-size: 12px !important;
  font-weight: 400;
}

.apexcharts-point-annotation-label > tspan:nth-of-type(2) {
  font-size: 14px !important;
}
</style>
