<template>
  <CalculatorTabComponent
    >
    <template v-slot:calculator-primary-1="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER : constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II "
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
        >
      </CommonProcumentCalculator>
    </template>
    <template v-slot:calculator-primary-2="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER : constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </CommonProcumentCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER :  constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II"
        :title="slotProps.title"
      >
      </CommonProcumentCalculator>
    </template>
    <template v-slot:calculator-secondary-2="slotProps">
      <CommonProcumentCalculator
        :subgroupingId="primary ? constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER :  constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II"
        :title="slotProps.title"
      >
      </CommonProcumentCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import CommonProcumentCalculator from "@/components/calculator/procurement/calculators/CommonProcumentCalculator.vue"

@Options({
  components: { CommonProcumentCalculator, CalculatorTabComponent }
})
export default class MaterialsView extends BaseComponent {
  @Prop()
  primary? : boolean
}
</script>

<style scoped>

</style>