<template>

  <div class='wrapper-div' id='highest-emission-results-wrapper-div'>

    <section id='section-one-container' class='section-container'>
      <h1 id='section-one-main-header'>
        <SimpleText text-key='results.highest-emissions.main-header' />
      </h1>
      <div id='main-description-text' class='main-description-text'>
        <RichText text-key='results.highest-emissions.main-description-text' />
      </div>
    </section>

    <section id='section-two-container' class='section-container'>

      <div>
        <div class='form-group' aria-labelledby="multiselect-scopes-label">
          <label id='multiselect-scopes-label' class='form-label'>
            <SimpleText text-key='forecast.result.scope123.field.forecast-scope.name' />
          </label>
          <div class='multiselect-wrapper col-3 p-0 m-0'>
            <Multiselect
              v-model='selectedScopes'
              mode='tags'
              :close-on-select='false'
              :hideSelected='false'
              :canClear='false'
              :groups='false'
              :options='possibleScopes'
              valueProp='scopeId'
              label='scopePresentationName'
              :aria="{'aria-labelledby':'multiselect-scopes-label', 'aria-placeholder': null, 'aria-multiselectable': null}"
              ref='input-highest-emissions-scope'
              class='multiselect-calculation form-group'
              :placeholder="getContentfulString('forecast.resultview.filter.placeholder.allscopesselected')"

            />
          </div>
        </div>
        <!-- sonar-ignore -->
        <div class='padding-10-bottom-top'>
          <a
            id='submit-button-highest-emissions'
            class='button button-primary'
            type='submit'
            role='button'
            @click.prevent='renderChart()'
            @keydown.enter="renderChart()"
            @keydown.space.prevent="renderChart()"
            onKeyDown="renderChart()"
          >
            <SimpleText :text-key="'results.highest-emissions.submit-button-text'" />
          </a>
        </div>
      </div>

      <div class='align-self-center'>
        <h4 id='section-two-figure-description-header'>
          {{ graphDescription }}
        </h4>
        <p id='section-two-figure-description-main-text' class='main-description-text'>
          {{
            this.dynamicContentfulText('results.highest-emission.figure-description-main-text', '#TotalCo2Emission#', String(doPrettify(this.currentScopesTotal)))
          }}
        </p>
      </div>

      <HighestEmissionResultsColumnChart
        v-if='tableTopTwenty'
        id='highest-emission-result-column-chart'
        ref='highestEmissionResultsColumnChart'
        :raw-data='tableTopTwenty'
        :filter='chartSettings'
      >

      </HighestEmissionResultsColumnChart>
    </section>


    <div id='section-tree-container' class='section-container'>

      <table id='esg-results-table' class='table results-table w-percent-100' v-if='results?.groupingResults'
             aria-describedby='highest-emission-result-table-header1'
             aria-label='OVERSIGT OVER CO2-E UDLEDNINGEN I TON CO2-E FORDELT PÅ UNDERKATEGORIER'>
        <thead>
        <tr class='bg-primary text-color-white'>
          <th id='highest-emission-result-table-header1' colspan='4' scope='colgroup'>
            <SimpleText text-key='results.highest-emission.table-header-one' />
          </th>
        </tr>
        </thead>
        <thead>
        <tr style='font-weight: 600' class="w-percent-100">
          <th style='width: 30%' class='py-405 align-text-left bg-alternative color-text-black' id="results.highest-emission.table-subheader-one" headers="highest-emission-result-table-header1">
            <SimpleText text-key='results.highest-emission.table-subheader-one'></SimpleText>
          </th>
          <th style='width: 40%' class='py-405 align-text-left bg-alternative color-text-black' id="results.highest-emission.table-subheader-two" headers="highest-emission-result-table-header1">
            <SimpleText text-key='results.highest-emission.table-subheader-two'></SimpleText>
          </th>
          <th style='width: 15%' class='py-405 align-text-left bg-alternative color-text-black' id="results.highest-emission.table-subheader-three" headers="highest-emission-result-table-header1">
            {{ tableHeaderTotalDescription }}
          </th>
          <th style='width: 15%' class='py-405 align-text-left bg-alternative color-text-black' id="results.highest-emission.table-subheader-four" headers="highest-emission-result-table-header1">
            {{ tableHeaderTotalPercentageDescription }}
          </th>
        </tr>
      </thead>
        <tbody>

        <tr id='highest-emission-result-table-totals' class='align-text-right bg-primary text-color-white'>
          <td colspan='2'>
          </td>
          <td class='align-text-right' headers="results.highest-emission.table-subheader-three"> {{
              doPrettify(this.currentScopesTotal)
            }}
          </td>
          <td class='italic-text align-text-right' headers="results.highest-emission.table-subheader-four">
            {{ doPrettify(this.tableHeaderTotalPercentage) }}%
          </td>
        </tr>

        <tr :key='line.calculationLineId'
            v-for='line in tableTopTwenty.sort((a,b)=>(b.scopeTotal - a.scopeTotal))'>
          <td headers="results.highest-emission.table-subheader-one" >{{ line.calculationLineDescription }}</td>
          <td headers="results.highest-emission.table-subheader-two">{{ line.position }}</td>
          <td headers="results.highest-emission.table-subheader-three" class='align-text-right'>{{ doPrettify(line.scopeTotal) }}</td>
          <td headers="results.highest-emission.table-subheader-four" class='align-text-right'>{{ doPrettify(line.scopePercentage) }}%</td>
        </tr>

        </tbody>
      </table>
    </div>


  </div>

</template>

<script lang='ts'>

import { Menu } from '@/shared/MenuStructure'
import { useRouter } from 'vue-router'
import { Options } from 'vue-class-component'
import RichText from '@/components/RichText.vue'
import SimpleText from '@/components/SimpleText.vue'
import BaseComponent from '@/components/calculator/base-components/BaseComponent'
import ContentfulContent from '@/shared/ContentfulContent'
import { Constants } from '@/shared/Constants'
import { Prop } from 'vue-property-decorator'
import {
  Calculate,
  GroupingCalculatedResults, SubgroupingCalculatedResult
} from '../../../../openapi/generated-clients/climatecompass'
import Tooltip from '@/components/Tooltip.vue'
import HighestEmissionResultsColumnChart from '@/components/calculator/results/HighestEmissionResultsColumnChart.vue'
import Multiselect from '@vueform/multiselect'
import { calculateClient } from '@/shared/BackendService'

class ScopeForSelect {
  scopeId: string
  scopePresentationName: string

  constructor(scopeId: string, scopePresentationName: string) {
    this.scopeId = scopeId
    this.scopePresentationName = scopePresentationName
  }
}

export interface CalculationLine {
  calculationLineId: number
  calculationLineDescription: string
  position: string
  scopePercentage: number
  scopeTotal: number
  scope1Value: number
  scope2Value: number
  scope3Value: number
}

@Options({
  components: {
    HighestEmissionResultsColumnChart,
    Tooltip,
    RichText,
    SimpleText,
    Multiselect
  }
})

export default class HighestEmissionResults extends BaseComponent {

  @Prop()
  results: GroupingCalculatedResults = {} as GroupingCalculatedResults

  @Prop()
  printMode?: boolean

  tableTopTwenty: CalculationLine[] = []
  menu = {} as Menu
  router = useRouter()
  currentScopesTotal = 0
  graphDescription = ''
  tableHeaderTotalDescription = ''
  tableHeaderTotalPercentageDescription = ''
  tableHeaderTotalPercentage = 0
  top20SubGroupings: SubgroupingCalculatedResult[] = []
  possibleScopes: ScopeForSelect[] = []
  selectedScopes: string[] = []


  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent

  $refs!: {
    highestEmissionResultsColumnChart: HighestEmissionResultsColumnChart
  }

  async beforeMount() {
    this.scrollToTargetWithOffset('section-one-container', 10)

    this.possibleScopes.push(new ScopeForSelect('Scope1', 'Scope 1'))
    this.possibleScopes.push(new ScopeForSelect('Scope2', 'Scope 2'))
    this.possibleScopes.push(new ScopeForSelect('Scope3', 'Scope 3'))

    this.renderChart()
  }

  async init() {
    this.graphDescription = this.selectedScopesDependantText('results.highest-emission.figure-description-header')
    this.tableHeaderTotalDescription = this.selectedScopesDependantText('results.highest-emission.table-subheader-three')
    this.tableHeaderTotalPercentageDescription = this.selectedScopesDependantText('results.highest-emission.table-subheader-four')

    const calculationLineList: CalculationLine[] = []
    if (!this.calculation.id) return

    const result = await calculateClient.getAllCalculationLines(this.calculation.id, 'null');
    const allCalculationLines: Calculate[] = result.data;

    allCalculationLines.forEach((line: Calculate) => {
      let scope1CalcLineTotal = 0
      let scope2CalcLineTotal = 0
      let scope3CalcLineTotal = 0
      let scopeTotal = 0
      let scopePercentage = 0

      // Since field-value indexes move around, it is necessary to map them in reverse, using the remarksfield position, as it is the only constant index across all group calculation lines.
      const positionOfRemarksField = line.fieldValues.findIndex(x => x.id === Constants.INPUT_BEMÆRKNING)

      let valueScope1 = 0
      let valueScope2 = 0
      let valueScope3 = 0

      if (positionOfRemarksField) {
        valueScope3 = Number(line.fieldValues[positionOfRemarksField - 2].value)
        valueScope2 = Number(line.fieldValues[positionOfRemarksField - 3].value)
        valueScope1 = Number(line.fieldValues[positionOfRemarksField - 4].value)
      } else {
        console.error('Missing Remarks field')
      }

      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE1) > 0)
        scope1CalcLineTotal += valueScope1
      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE2) > 0)
        scope2CalcLineTotal += valueScope2
      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE3) > 0)
        scope3CalcLineTotal += valueScope3

      scopeTotal = scope1CalcLineTotal + scope2CalcLineTotal + scope3CalcLineTotal

      let currentTotal = 0
      const scope1ValueTotal = this.results.ghgCalculatedResult?.scope1Result
      const scope2ValueTotal = this.results.ghgCalculatedResult?.scope2Result
      const scope3ValueTotal = this.results.ghgCalculatedResult?.scope3Result
      const allScopesTotalValue = this.results.ghgCalculatedResult?.totalResult ?? 0

      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE1) > 0) currentTotal += scope1ValueTotal ?? 0
      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE2) > 0) currentTotal += scope2ValueTotal ?? 0
      if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE3) > 0) currentTotal += scope3ValueTotal ?? 0

      this.currentScopesTotal = currentTotal
      if (allScopesTotalValue !== 0) {
        scopePercentage = scopeTotal * 100 / allScopesTotalValue
      }

      let groupingName = ''
      let subGroupingName = ''
      let mergedSubGroupingName = ''

      groupingName = this.getGroupingName(this.getGroupingBySubGroupingId(line.subgroupingId))
      subGroupingName = this.getSubGroupingName(line.subgroupingId)
      mergedSubGroupingName = this.getMergedSubgroupingName(line.subgroupingId)


      calculationLineList.push({
        calculationLineId: line.id,
        calculationLineDescription: line.fieldValues[0].value,
        scopePercentage: scopePercentage,
        position: `${groupingName} > ${mergedSubGroupingName} > ${subGroupingName}`,
        scopeTotal: scopeTotal,
        scope1Value: valueScope1,
        scope2Value: valueScope2,
        scope3Value: valueScope3
      } as CalculationLine)
    })
    const calculationLineListOrderedByHighestEmission: CalculationLine[]
        = calculationLineList.sort((a, b) => {return b.scopeTotal - a.scopeTotal})
    this.tableTopTwenty = calculationLineListOrderedByHighestEmission.length > 20 ? calculationLineListOrderedByHighestEmission.slice(0, 20) : calculationLineListOrderedByHighestEmission
    if (this.results.ghgCalculatedResult?.totalResult && this.results.ghgCalculatedResult?.totalResult > 0) {
      this.tableHeaderTotalPercentage = this.currentScopesTotal * 100 / this.results.ghgCalculatedResult?.totalResult
    }
  }

  getGroupingBySubGroupingId(subGroupingId: number) {
    switch (subGroupingId) {
      case this.constants.GROUP_FORBRUG_AF_ELEKTRICITET :
      case this.constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_FJERNVARME :
      case this.constants.GROUP_BRÆNDSLER :
      case this.constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_PROCESUDLEDNING :
      case this.constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER :
        return 1

      case this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II :
      case this.constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER :
        return 2

      case this.constants.GROUP_AFFALD :
      case this.constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG :
        return 4

      case this.constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER :
      case this.constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER :
      case this.constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_END_OF_LIFE_BEHANDLING :
      case this.constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER :
      case this.constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER :
        return 5

      case this.constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER :
      case this.constants.GROUP_FLY_OG_SKIBE :
      case this.constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_PENDLING :
      case this.constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER :
      case this.constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK :
      case this.constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER :
      case this.constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK :
      case this.constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II :
      case this.constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II :
      case this.constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER :
      case this.constants.GROUP_TRANSPORT_ANDET :
        return 3

      default :
        return 0
    }
  }

  getGroupingName(groupingId: number) {
    switch (groupingId) {
      case 1:
        return this.getContentfulString('calculator.grouping.energy')
      case 2:
        return this.getContentfulString('calculator.grouping.purchases')
      case 3:
        return this.getContentfulString('calculator.grouping.transport')
      case 4:
        return this.getContentfulString('calculator.grouping.garbage')
      case 5:
        return this.getContentfulString('calculator.grouping.products')
    }
  }

  getMergedSubgroupingName(subGroupingId: number) {
    switch (subGroupingId) {
      // ENERGI OG PROCESSER
      // Elektricitet
      case Constants.GROUP_FORBRUG_AF_ELEKTRICITET:
      case Constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.energyandprocessestabs.item.electricity')
      // Varme og Processenergi
      case Constants.GROUP_FJERNVARME:
      case Constants.GROUP_BRÆNDSLER:
      case Constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.energyandprocessestabs.item.heat-and-process-energy')
      // Processudledning
      case Constants.GROUP_PROCESUDLEDNING:
      case Constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.energyandprocessestabs.item.process')
      // Andet udledning ved energi og processer
      case Constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER:
        return this.getContentfulString('menu.energyandprocessestabs.item.other-energy-and-processes')

      // INDKØB
      // Indkøb af materialer ved primære indkøb
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('menu.procurementtabs.item.materials-primary')
      // Inkøb af produkter og services ved primære indkøb
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('menu.procurementtabs.item.products-and-services-primary')
      // Andet udledning ved primære indkøb
      case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER:
      case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('menu.procurementtabs.item.other-primary')
      // Sekundære indkøb af materialer
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('menu.procurementtabs.item.materials-secondary')
      // Sekundære indkøb af produkter og services
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('menu.procurementtabs.item.products-and-services-secondary')
      // Andet udledning ved sekundære indkøb
      case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER:
      case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('menu.procurementtabs.item.other-secondary')

      // TRANSPORT
      // Egne og leasede Transportmidler
      case Constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER:
      case Constants.GROUP_FLY_OG_SKIBE:
      case Constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.transporttabs.item.own-and-leased')
      // Medarbejder Transport
      case Constants.GROUP_PENDLING:
      case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER:
      case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK:
      case Constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.transporttabs.item.employee-transportation')
      // Varetransport til virksomhed
      case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER:
      case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK:
      case Constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.transporttabs.item.product-transportation')
      // Varetransport til kunde
      case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II:
      case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II:
      case Constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.transporttabs.item.customer-transportation')
      // Andet (Transport)
      case Constants.GROUP_TRANSPORT_ANDET:
        return this.getContentfulString('menu.transporttabs.item.other-transport')

      // AFFALD OG GENBRUG
      // Affald
      case Constants.GROUP_AFFALD:
      case Constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.trashandrecyclingtabs.item.trash')
      // Andet(Affald)
      case Constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG:
        return this.getContentfulString('menu.trashandrecyclingtabs.item.other-trash-and-recycling')

      // SOLGTE PRODUKTER
      // Forarbejning og processering
      case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER:
      case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.soldproductstabs.item.processing')
      // Brug af solgte og udlejede produkter
      case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:
      case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:
        return this.getContentfulString('menu.soldproductstabs.item.use-of-products')
      // Andet (Solgte produkter)
      case Constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER:
        return this.getContentfulString('menu.soldproductstabs.item.other-sold-products')

      // END OF LIFE
      // End of Life Behandling
      case Constants.GROUP_END_OF_LIFE_BEHANDLING:
      case Constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('menu.soldproductstabs.item.end-of-life-treatment')

      default:
        return 'ERROR- Cannot locate ID: ' + subGroupingId.toString()
    }
  }

  getSubGroupingName(subGroupingId: number) {
    switch (subGroupingId) {
      case Constants.GROUP_FORBRUG_AF_ELEKTRICITET:
        return this.getContentfulString('subcategories.subgroupelectricity')
      case Constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-electricityown')
      case Constants.GROUP_FJERNVARME:
        return this.getContentfulString('subcategories.subgroup-heat')
      case Constants.GROUP_BRÆNDSLER:
        return this.getContentfulString('subcategories.subgroup-fuel')
      case Constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER :
        return this.getContentfulString('subcategories.subgroup-heatprocessown')
      case Constants.GROUP_PROCESUDLEDNING:
        return this.getContentfulString('subcategories.subgroup-process')
      case Constants.GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-processown')
      case Constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER:
        return this.getContentfulString('subcategories.subgroup-EnergyProcessOther')
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsphysical')
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasematerialmonetary')
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsphysicalown')
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasematerialsmonetaryown')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsphysical')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsmonetary')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsphysicalown')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchaseproductsmonetaryown')
      case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasephysicalother')
      case Constants.GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-primarypurchasemonetaryother')
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchasematerialsphysicalunits')
      case Constants.GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchasematerialsmonetaryunits')
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchasematerialsphysicalown')
      case Constants.GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchasematerialsmonetaryown')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchaseproductsphysical')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchaseproductsmonetary')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchaseproductsphysicalown')
      case Constants.GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-secpurchaseproductsmonetaryown')
      case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-secpurchasephysicalother')
      case Constants.GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-secpurchasemonetaryother')
      case Constants.GROUP_AFFALD :
        return this.getContentfulString('subcategories.subgroup-trash')
      case Constants.GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-trashown')
      case Constants.GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG:
        return this.getContentfulString('subcategories.subgroup-TrashOther')
      case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER:
        return this.getContentfulString('group.text.category.groupprocesssoldproducts')
      case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER:
        return this.getContentfulString('subcategories.subgroup-soldproductsused')
      case Constants.GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-soldproductsown')
      case Constants.GROUP_END_OF_LIFE_BEHANDLING:
        return this.getContentfulString('subcategories.subgroup-endoflife')
      case Constants.GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-endoflifeown')
      case Constants.GROUP_ANDET_VED_SOLGTE_PRODUKTER:
        return this.getContentfulString('subcategories.subgroup-SoldProductsOther')
      case Constants.GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-SoldProductsProcessingOwn')
      case Constants.GROUP_EGNE_OG_LEASEDE_KØRETØJER:
        return this.getContentfulString('subcategories.subgroup-ownandleasedtransports')
      case Constants.GROUP_FLY_OG_SKIBE:
        return this.getContentfulString('subcategories.subgroup-airplaneship')
      case Constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-ownandleasedtransportsown')
      case Constants.GROUP_PENDLING:
        return this.getContentfulString('subcategories.subgroup-pendling')
      case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-travelsphysical')
      case Constants.GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK:
        return this.getContentfulString('subcategories.subgroup-travelsmonetary')
      case Constants.GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-employeetransportown')
      case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER:
        return this.getContentfulString('subcategories.subgroup-transportphysical')
      case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK:
        return this.getContentfulString('subcategories.subgroup-transportmonetary')
      case Constants.GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-transporttocompanyown')
      case Constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II:
        return this.getContentfulString('subcategories.subgroup-transporttocustomerphysical')
      case Constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II:
        return this.getContentfulString('subcategories.subgroup-transporttocustomermonetary')
      case Constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER:
        return this.getContentfulString('subcategories.subgroup-transporttocustomerown')
      case Constants.GROUP_TRANSPORT_ANDET:
        return this.getContentfulString('subcategories.subgroup-other')
      default:
        return 'ERROR- Cannot locate ID: ' + subGroupingId.toString()
    }
  }

  get chartSettings() {
    let setting = 0
    if (this.selectedScopes.includes('Scope1')) {
      setting += Constants.FORECAST_FILTER_SCOPE1
    }
    if (this.selectedScopes.includes('Scope2')) {
      setting += Constants.FORECAST_FILTER_SCOPE2
    }
    if (this.selectedScopes.includes('Scope3')) {
      setting += Constants.FORECAST_FILTER_SCOPE3
    }
    return setting
  }

  async renderChart() {
    await this.init()
    if (this.$refs.highestEmissionResultsColumnChart) {
      this.$refs.highestEmissionResultsColumnChart.updateChart()
    }
  }

  selectedScopesDependantText(contentfulId: string) {

    const array = []
    if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE1) > 0) array.push(1)
    if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE2) > 0) array.push(2)
    if (this.chartSettings === 0 || (this.chartSettings & this.constants.FORECAST_FILTER_SCOPE3) > 0) array.push(3)

    return this.dynamicContentfulText(contentfulId, '#SelectedScopes#', 'Scope ' + array.join(' + '))
  }

  dynamicContentfulText(contentfulId: string, textToReplace: string, replacementValue: string) {
    const rt: string = this.getContentfulString(contentfulId)
    return rt.replace(textToReplace, replacementValue)
  }

}
</script>

<style scoped lang="scss">

* {
  -webkit-print-color-adjust: exact;
}

.wrapper-div {
  padding-top: 3em;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.section-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5em;
  max-width: 100%;
  margin: 2px;
}

.padding-10-bottom-top {
  padding: 10px 0;
}

.align-self-center {
  align-self: center;
}

tr > th.py-405.align-text-left.bg-alternative {
  color: black;
  border-right: 1px solid $color-primary !important;
}

</style>