/** usefull contants used in kk-2 */
export class Constants {
  public static readonly TOAST_TYPE_CALCULATOR_SAVED = 1
  public static readonly TOAST_TYPE_CALCULATOR_DELETE = 2
  public static readonly TOAST_TYPE_CALCULATOR_ERROR = 3
  public static readonly TOAST_TYPE_CALCULATOR_UPDATED = 4
  public static readonly TOAST_DEFAULT_TIMEOUT = 6000

  public static readonly SPINNER_ACTIVATE_TIMEOUT = 3000

  public static readonly CARDBOARD_UPGRADE = 'cardboard-upgrade'
  public static readonly CARDBOARD_BACKEND_DOWN = 'cardboard-backend-down'
  public static readonly CARDBOARD_NETWORK_ERROR = 'cardboard-network-error'

  public static readonly THEME_NAME_ENERGY = 'Energi & processer'
  public static readonly THEME_NAME_PURCHASES = 'Primære og Sekundære indkøb'
  public static readonly THEME_NAME_TRANSPORT= 'Transport'
  public static readonly THEME_NAME_TRASH = 'Affald & genbrug'
  public static readonly THEME_NAME_SOLDPRODUCTS = 'Solgte produkter'

  public static readonly THEME_NUMBER_ENERGY = 1
  public static readonly THEME_NUMBER_PURCHASES = 2
  public static readonly THEME_NUMBER_TRANSPORT= 3
  public static readonly THEME_NUMBER_TRASH = 4
  public static readonly THEME_NUMBER_SOLDPRODUCTS = 5

  public static readonly GROUP_FORBRUG_AF_ELEKTRICITET = 1
  public static readonly GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER = 2
  public static readonly GROUP_FJERNVARME = 3
  public static readonly GROUP_BRÆNDSLER = 4
  public static readonly GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER = 5
  public static readonly GROUP_PROCESUDLEDNING = 6
  public static readonly GROUP_PROCESUDLEDNING_MED_EGNE_EMISSIONSFAKTORER = 7
  public static readonly GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER = 8
  public static readonly GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER = 9
  public static readonly GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER = 10
  public static readonly GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER = 11
  public static readonly GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER = 12
  public static readonly GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER = 13
  public static readonly GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER = 14
  public static readonly GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER = 15
  public static readonly GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER = 16
  public static readonly GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_FYSISKE_ENHEDER = 17
  public static readonly GROUP_ANDET_UDLEDNING_VED_PRIMÆRE_INDKØB_I_MONETÆRE_ENHEDER = 18
  public static readonly GROUP_INDKØB_AF_MATERIALER_I_FYSISKE_ENHEDER_II = 19
  public static readonly GROUP_INDKØB_AF_MATERIALER_I_MONETÆRE_ENHEDER_II = 20
  public static readonly GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II = 21
  public static readonly GROUP_INDKØB_AF_MATERIALER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II = 22
  public static readonly GROUP_INDKØB_AF_PRODUKTER_I_FYSISKE_ENHEDER_II = 23
  public static readonly GROUP_INDKØB_AF_PRODUKTER_I_MONETÆRE_ENHEDER_II = 24
  public static readonly GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_FYSISKE_ENHEDER_II = 25
  public static readonly GROUP_INDKØB_AF_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER_I_MONETÆRE_ENHEDER_II = 26
  public static readonly GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_FYSISKE_ENHEDER = 27
  public static readonly GROUP_ANDET_UDLEDNING_VED_SEKUNDÆRE_INDKØB_I_MONETÆRE_ENHEDER = 28
  public static readonly GROUP_AFFALD = 29
  public static readonly GROUP_AFFALD_MED_EGNE_EMISSIONSFAKTORER = 30
  public static readonly GROUP_ANDET_UDLEDNING_VED_AFFALD_OG_GENBRUG = 31
  public static readonly GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER = 32
  public static readonly GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER = 33
  public static readonly GROUP_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER = 34
  public static readonly GROUP_END_OF_LIFE_BEHANDLING = 35
  public static readonly GROUP_END_OF_LIFE_BEHANDLING_MED_EGNE_EMISSIONSFAKTORER = 36
  public static readonly GROUP_ANDET_VED_SOLGTE_PRODUKTER = 37
  public static readonly GROUP_FORARBEJDNING_OG_PROCESSERING_AF_SOLGTE_PRODUKTER_MED_EGNE_EMISSIONSFAKTORER = 38
  public static readonly GROUP_EGNE_OG_LEASEDE_KØRETØJER = 39
  public static readonly GROUP_FLY_OG_SKIBE = 40
  public static readonly GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER = 41
  public static readonly GROUP_PENDLING = 42
  public static readonly GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE_ENHEDER = 43
  public static readonly GROUP_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE_ENHEDER_BELØB_I_DKK = 44
  public static readonly GROUP_MEDARBEJDERTRANSPORT_MED_EGNE_EMISSIONSFAKTORER = 45
  public static readonly GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER = 46
  public static readonly GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK = 47
  public static readonly GROUP_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER = 48
  public static readonly GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II = 49
  public static readonly GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II = 50
  public static readonly GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER = 51
  public static readonly GROUP_TRANSPORT_ANDET = 52

  public static readonly GROUP_NAME_EL = 'Elektricitet'
  public static readonly GROUP_NAME_VARME_OG_PROCESENERGI = 'Varme og procesenergi'
  public static readonly GROUP_NAME_PROCESUDLEDNING = 'Procesudledning'
  public static readonly GROUP_NAME_EGNE_OG_LEASEDE_TRANSPORTFORM = 'Egne og leasede transportform'
  public static readonly GROUP_NAME_MEDARBEJDERTRANSPORT = 'Medarbejdertransport'
  public static readonly GROUP_NAME_VARETRANSPORT_TIL_VIRKSOMHED = 'Varetransport til virksomhed'
  public static readonly GROUP_NAME_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDEN = 'Varetransport fra virksomhed til kunden'
  public static readonly GROUP_NAME_AFFALD = 'Affald'
  public static readonly GROUP_NAME_FORARBEJDNING_AF_SOLGTE_PRODUKTER = 'Forarbejdning og processering af solgte produkter'
  public static readonly GROUP_NAME_BRUG_AF_SOLGTE_OG_UDLEJEDE_PRODUKTER = 'Brug af solgte og udlejede produkter'
  public static readonly GROUP_NAME_END_OF_LIGE_BEHANDLING = 'End-of-life behandling'
  public static readonly GROUP_NAME_SOLGTE_OG_UDLEJEDE_PRODUKTER_EGNE = 'Brug af solgte og udlejede produkter med egne emissionsfaktorer'
  public static readonly GROUP_NAME_PRIMARE_INDKOEB_RAAVARER_PRODUKTION = 'Primære indkøb af råvarer til produktion'
  public static readonly GROUP_NAME_SEKUNDARE_INDKOEB_HJAELPEMATERIALER_OG_SERVICEYDELSER = 'Sekundære indkøb af hjælpematerialer og serviceydelser'
  public static readonly GROUP_NAME_INDKOEB_MATERIALER = 'Indkøb af materialer'
  public static readonly GROUP_NAME_INDKOEB_PRODUKTER_OG_SERVICES = 'Indkøb af produkter og services'
  public static readonly GROUP_NAME_ANDET = 'Andet'

  public static readonly GROUPS_WITH_POSSIBLE_OWN_ESG_FACTORS
    = [Constants.GROUP_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER,
       Constants.GROUP_VARME_OG_PROCESENERGI_MED_EGNE_EMISSIONSFAKTORER,
       Constants.GROUP_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER,
       Constants.GROUP_EGNE_OG_LEASEDE_TRANSPORTMIDLER_MED_EGNE_EMISSIONSFAKTORER,
       Constants.GROUP_TRANSPORT_ANDET
  ]

  public static readonly GROUPS_OWN_EMISSION_FACTORS = [
    2, 5 ,7, 8, 11, 12, 15, 16, 17, 18, 21, 22, 25, 26, 27, 28, 30, 31, 32, 34, 36, 37, 38, 41, 45, 48, 51, 52
  ]

  public static readonly UNIT_FIELDS_OWN_EMISSION_FACTORS = [
    1135, 1136, 1137, 1138, 1139, 1140, 1141, 1142, 1143, 1144, 1145, 1146, 1147, 1148, 1149, 1150, 1151, 1152, 1153
  ]

  public static readonly INPUT_INCLUDE_IN_ESG: string = 'INCLUDE_IN_ESG'
  public static readonly INPUT_FOSSIL_ENERGY_SHARE_PCT: string = 'FOSSIL_ENERGY_SHARE_PCT'
  public static readonly INPUT_NUCLEAR_ENERGY_SHARE_PCT: string = 'NUCLEAR_ENERGY_SHARE_PCT'
  public static readonly INPUT_RENEWABLE_ENERGY_SHARE_PCT: string = 'RENEWABLE_ENERGY_SHARE_PCT'
  public static readonly INPUT_ESG_CONVERSION: string = 'ESG_CONVERSION'
  public static readonly INPUT_ESG_OLD: string = 'ESG'

  public static readonly INPUT_BESKRIVELSE = 1
  public static readonly INPUT_BEMÆRKNING = 9
  public static readonly INPUT_ANDEL_AF_BIOGAS = 12
  public static readonly FE_ID_INPUT_ANDEL_BIOGAS = 'fe_id-41'
  public static readonly INPUT_BRAENDSELSTYPE = 13
  public static readonly INPUT_ENHED_FYSISKE = 19
  public static readonly INPUT_EMISSIONSFAKTOR_NOT_REQUIRED = 25
  public static readonly INPUT_EMISSIONSFAKTOR_HEAT = 39
  public static readonly INPUT_MÆNGDE_KMWH_MJ_L_NM3 = 46
  public static readonly INPUT_BIOBRÆNDSTOFANDEL = 50
  public static readonly INPUT_ENHED_KM_L_KWH = 51
  public static readonly INPUT_ENHED_KM_L_KWH_AAPÅ = 54
  public static readonly INPUT_ENHED_PERSONKM_L_KWH = 55
  public static readonly INPUT_ENHED_KM_L_KWH_TONKM = 56
  public static readonly INPUT_DROPDOWN_TRANSPORT_DRIVMIDDEL_E = 61
  public static readonly INPUT_DROPDOWN_DEPENDENT_UNIT_1 = 47;
  public static readonly INPUT_DROPDOWN_DEPENDENT_UNIT_2 = 1135;
  public static readonly INPUT_DROPDOWN_DEPENDENT_UNIT_3 = 1153;
  public static readonly INPUT_ENHED_L = 'L'
  public static readonly FE_ID_ENHED_L = 'fe_id-5'
  public static readonly INPUT_PERSONBILER_EL = 'Personbiler (El)'
  public static readonly INPUT_VAREBILER_EL = 'Varerbiler (El)'
  public static readonly INPUT_LNG = 'LNG'
  public static readonly FE_ID_ENHED_LNG = 'fe_id-5'
  public static readonly INPUT_LPG = 'LPG'
  public static readonly INPUT_CBG = 'CNG'
  public static readonly INPUT_LBG = 'LBG'
  public static readonly INPUT_CNG = 'CBG'
  public static readonly INPUT_LASTBILER_BIODIESEL_HVO = 'Lastbiler (Biodiesel HVO)'
  public static readonly INPUT_SKIB_MARINE_GAS_OIL = 'Skib (Marine gas oil)'
  public static readonly INPUT_FLYBENZIN = 'Flybenzin'
  public static readonly INPUT_MARINE_FUEL_OIL = 'Marine fuel oil'
  public static readonly FE_ID_ENHED_M3 = 'fe_id-1864'
  public static readonly FE_ID_ENHED_NM3 =  'fe_id-43'
  public static readonly DROPDOWN_PRODUKT_GRUPPE = 31
  public static readonly DROPDOWN_SPECIFIKKE_PRODUKTER = 32
  public static readonly DROPDOWN_MATERIALE_GRUPPE = 1128
  public static readonly DROPDOWN_SPECIFIKKE_MATERIALER = 26
  public static readonly DROPDOWN_MATERIALE_GRUPPE_II = 1129
  public static readonly FE_ID_BUS_DIESEL = 'fe_id-300'
  public static readonly FE_ID_LASTBILER_DIESEL = 'fe_id-302'
  public static readonly FE_ID_MOTORCYKLER_BENZIN = 'fe_id-304'
  public static readonly FE_ID_PERSONBILER_BENZIN = 'fe_id-305'
  public static readonly FE_ID_PERSONBILER_DIESEL = 'fe_id-306'
  public static readonly FE_ID_PERSONBILER_HYBRID = 'fe_id-308'
  public static readonly FE_ID_L_1 = "fe_id-268"
  public static readonly FE_ID_L_2 = "fe_id-24"
  public static readonly FE_ID_L_3 = "fe_id-101"
  public static readonly FE_ID_L_4 = "fe_id-38"
  public static readonly FE_ID_L_5 = "fe_id-167"
  public static readonly FE_ID_L_6 = "fe_id-233"
  public static readonly FE_ID_L_7 = "fe_id-259"
  public static readonly FE_ID_L_8 = "fe_id-270"
  public static readonly FE_ID_L_9 = "fe_id-272"
  public static readonly FE_ID_L_10= "fe_id-276"
  public static readonly FE_ID_L_11 = "fe_id-280"
  public static readonly FE_ID_L_12 = "fe_id-5"



  public static readonly SYSID_PAGE_INDKOEB = '5dM1ay41jexpHPi0XlYbp7'
  public static readonly SYSID_PAGE_INDKOEB_MENU = 'procurementTabs'
  public static readonly SYSID_PAGE_TRANSPORT = '2Whp0fIGhAXWKQQnvPHPmd'
  public static readonly SYSID_PAGE_TRANSPORT_MENU = 'transportTabs'
  public static readonly SYSID_PAGE_AFFALD_MENU = 'trashAndRecyclingTabs'
  public static readonly SYSID_PAGE_AFFALD = '7zppZehIoM98pRHVGSxiNS'
  public static readonly SYSID_PAGE_SOLGTE_PRODUKTER_MENU = 'soldProductsTabs'
  public static readonly SYSID_PAGE_SOLGTE_PRODUKTER = '5R22xv7AarleuHTWxFATDD'

  public static readonly SYSID_TAB_ELECTRICITET = '3bXLkjEJAmUsZ6D3d0E321'
  public static readonly SYSID_TAB_VARME_OG_PROCESENERGI = '22D9viOMAZIgmRajcCAKNh'
  public static readonly SYSID_TAB_PROCESUDLEDNING = '6TXSJp0QEmaEscsyLk0QhJ'
  public static readonly SYSID_TAB_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER = '34lwfmHlu4ONELa34kjNxi'
  public static readonly SYSID_TAB_MATERIALER_PRIMÆRE = 'T6ML1uPKaa4tPtQQdFWLR'
  public static readonly SYSID_TAB_PRODUKTER_PRIMÆRE = '1GNzj43E61BPbQmIo4NblX'
  public static readonly SYSID_TAB_ANDET_PRIMÆRE = '4jojCh8YwfjXWWqNGXOicO'
  public static readonly SYSID_TAB_MATERIALER_SEKUNDÆRE = 'j6r7AMClJCQLe2xjXO5jB'
  public static readonly SYSID_TAB_PRODUKTER_SEKUNDÆRE = '3mFeOKzYLzyE60eroAfEfV'
  public static readonly SYSID_TAB_ANDET_SEKUNDÆRE = '6AzoUIvVfLQNNmTQvRQIJk'
  public static readonly SYSID_TAB_EGNE_OG_LEASEDE = '6FTLi2P0t7Tu6njl4xIGr0'
  public static readonly SYSID_TAB_MEDARBEJDERTRANSPORT = '0AZFSgMwo2wGNlu8B71CL'
  public static readonly SYSID_TAB_TRANSPORT_TIL_VIRKSOMHED = '58QDUzf2AQIYSK9QO5Q1l5'
  public static readonly SYSID_TAB_TRANSPORT_TIL_KUNDE = '7o3ZBXavg4N7i2EgRoooC6'
  public static readonly SYSID_TAB_ANDET_TRANSPORT = '3Wcvk16OLI93yNjcMbPRTW'
  public static readonly SYSID_TAB_AFFALD = '7BwwNlegCYQC8UQ3LgiyrD'
  public static readonly SYSID_TAB_ANDET_AFFALD = '5jlsFpdX83NweTizSKlG6v'
  public static readonly SYSID_TAB_FORARBEJDNING_OG_PROCESSERING = '4wMGcukquGfbWPyvMqvg3Z'
  public static readonly SYSID_TAB_BRUG_AF_PRODUKTER = 'zFHn4AdZStQ3iKrOtQFQp'
  public static readonly SYSID_TAB_END_OF_LIFE = '7yFCZUlsGQDbngW10K1apL'
  public static readonly SYSID_TAB_ANDET_SOLGTE_PRODUKTER = '7DdHWX1Bmz7BIhx7wAX4ly'

  public static readonly SYSID_CALC_FORBRUG_AF_ELEKTRICITET = '2kFYF3iFVkJdsd9TsUysTJ'
  public static readonly SYSID_CALC_FORBRUG_AF_ELEKTRICITET_MED_EGNE_EMISSIONSFAKTORER= '1MdGqgM8lhoyiyYd60PKDz'
  public static readonly SYSID_CALC_FJERNVARME= '4ehbmRbBADIZHf5pZOiLr1'
  public static readonly SYSID_CALC_BRÆNDSLER = 'D5JVkQN5X5A6g9bDTBVNq'
  public static readonly SYSID_CALC_VARME_OG_PROCES_MED_EGNE_EMSSIONSFAKTORER = '5yuvDKu1w51SW5TZusAMnW'
  public static readonly SYSID_CALC_GENERICCALCULTATOR = '7pQ3JKL5uhjTFBzLOim7Ui'
  public static readonly SYSID_CALC_PROCESDUDLEDNING = '3gEghqSHReKVwacKaLmc7v'
  public static readonly SYSID_CALC_PROCESDUDLEDNING_MED_EGNE_EMSSIONSFAKTORER = 'gatF2uvbmHlYgKSfXik8R'
  public static readonly SYSID_CALC_ANDET_UDLEDNING_VED_ENERGI_OG_PROCESSER = '5jtEY2eESVwSqVrbwQVVVq'
  public static readonly SYSID_CALC_INDKØB_MATERIALER_FYSISKE = '6Ta5kWoL2nxSRWvQ68cLX5'
  public static readonly SYSID_CALC_INDKØB_MATERIALER_MONETÆRE = 'NxMmkAoiuig0m73GCuH5T'
  public static readonly SYSID_CALC_INDKØB_FYSISKE_MED_EGNE_EMISSIONSFAKTORER = '3CWlDlat1PiLxOvFb0AZEb'
  public static readonly SYSID_CALC_INDKØB_MONETÆRE_MED_EGNE_EMISSIONSFAKTORER = '1eWCzLFClb9E25I6NoVz8L'
  public static readonly SYSID_CALC_INDKØB_PRODUKTER_FYSISKE = 'xAjHUv6xQPBvarrakKWQF'
  public static readonly SYSID_CALC_INDKØB_PRODUKTER_MONETÆRE = '5EmATCa4ugTbTspoQhvZ2C'
  public static readonly SYSID_CALC_TRANSPORT_EGNE_OG_LEASEDE_KØRETØJER = 'dt6sacAkiSt5NdhN2kZdj'
  public static readonly SYSID_CALC_TRANSPORT_FLY_OG_SKIBE = '3RfQ9T7WhimkNuz4L8umHQ'
  public static readonly SYSID_CALC_TRANSPORT_EGNE_OG_LEASEDE_MED_EGNE_EMISSIONSFAKTORER = '5GvpSK0uFneI2K1Bw9D2Oc'
  public static readonly SYSID_CALC_TRANSPORT_PENDLING = '3iTrgdf3ZjdlsZCRFlmxCH'
  public static readonly SYSID_CALC_TRANSPORT_REJSER_I_FORBINDELSE_MED_ARBEJDE_FYSISKE = '4jiHMtIEUVLXQM6kGa8w9X'
  public static readonly SYSID_CALC_TRANSPORT_REJSER_I_FORBINDELSE_MED_ARBEJDE_MONETÆRE = '6KWhq9Hg2Ou6VxtwKok87f'
  public static readonly SYSID_CALC_TRANSPORT_MEDARBEJDER_TRANSPORT_MED_EGNE_EMISSIONSFAKTORER = '3QLXfpy9BCaItNL4LY22hc'
  public static readonly SYSID_CALC_TRANSPORT_VARETRANSPORT_I_FYSISKE = '5nt6wbCUbnXCvkjcBOLO3k'
  public static readonly SYSID_CALC_TRANSPORT_VARETRANSPORT_I_MONETÆRE = 'rnwJZ30qax8lqX0XMX5RH'
  public static readonly SYSID_CALC_TRANSPORT_VARETRANSPORT_TIL_VIRKSOMHED_MED_EGNE_EMISSIONSFAKTORER = '60WmZIZoPA7kVeupwziDqG'
  public static readonly SYSID_CALC_TRANSPORT_VARETRANSPORT_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER = '1MRQmOy5jh1CmCjNO4QjO4'
  public static readonly SYSID_CALC_TRANSPORT_ANDET_UDLEDNING_VED_TRANSPORT = '6onFGTXWntZ3goyK3mromR'
  public static readonly SYSID_CALC_AFFALD_AFFALD = '2Ixf7P8AOq9kqNTTkP5QKM'
  public static readonly SYSID_CALC_AFFALD_ANDET_UDLEDNING_VED_AFFALD_MED_EGNE_EMISSIONSFAKTORER = '3x2e9a0VB97GFP7AluO9yf'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_FORARBEJDNING_OG_PROCESSERING = '1v3YnhxuP3zz1B42rDuDTB'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_ENERGIFORBRUG_VED_ANVENDELSE = '6w2riBzqohugguKn8YnRLh'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_ENERGIFORBRUG_VED_ANVENDELSE_MED_EGNE_EMISSIONSFAKTORER = '7h39u9jrfFOVfH3Q8VvGqb'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_END_OF_LIFE = '65D6yvVcH8axVrk1n9oBX4'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_END_OF_LIFE_MED_EGNE_EMISSIONSFAKTORER = 'BzWEnk3qlu8AiKeRpkpL1'
  public static readonly SYSID_CALC_SOLGTE_PRODUKTER_ANDET_UDLEDNING = '2OrOtj0xAkLl8r8lahURyS'
  public static readonly SYSID_MASTERDATA = 'XTw3RgY9sxWExqgTV2iYc'
  public static readonly SCOPE3_CATEGORY_1 = 'CATEGORY_1'
  public static readonly SCOPE3_CATEGORY_2 = 'CATEGORY_2'
  public static readonly SCOPE3_CATEGORY_3 = 'CATEGORY_3'
  public static readonly SCOPE3_CATEGORY_4 = 'CATEGORY_4'
  public static readonly SCOPE3_CATEGORY_5 = 'CATEGORY_5'
  public static readonly SCOPE3_CATEGORY_6 = 'CATEGORY_6'
  public static readonly SCOPE3_CATEGORY_7 = 'CATEGORY_7'
  public static readonly SCOPE3_CATEGORY_8 = 'CATEGORY_8'
  public static readonly SCOPE3_CATEGORY_9 = 'CATEGORY_9'
  public static readonly SCOPE3_CATEGORY_10 = 'CATEGORY_10'
  public static readonly SCOPE3_CATEGORY_11 = 'CATEGORY_11'
  public static readonly SCOPE3_CATEGORY_12 = 'CATEGORY_12'
  public static readonly SCOPE3_CATEGORY_13 = 'CATEGORY_13'
  public static readonly SCOPE3_CATEGORY_14 = 'CATEGORY_14'
  public static readonly SCOPE3_CATEGORY_15 = 'CATEGORY_15'

  public static readonly SCOPE3_CATEGORY_1_IDS = [708, 710, 712, 714, 716, 718, 720, 722, 724, 726, 728, 730, 732, 734, 736, 738, 740, 742, 744, 746]
  public static readonly SCOPE3_CATEGORY_2_IDS = [709, 711, 713, 715, 717, 719, 721, 723, 725, 727, 729, 731, 733, 735, 737, 739, 741, 743, 745, 747]
  public static readonly SCOPE3_CATEGORY_3_IDS = [700, 701, 702, 703, 704, 707, 750, 751, 752]
  public static readonly SCOPE3_CATEGORY_4_IDS = [758, 759, 760, 762, 764, 766, 767]
  public static readonly SCOPE3_CATEGORY_5_IDS = [748, 749, 780]
  public static readonly SCOPE3_CATEGORY_6_IDS = [754, 755, 757]
  public static readonly SCOPE3_CATEGORY_7_IDS = [753, 756]
  public static readonly SCOPE3_CATEGORY_8_IDS = [785, 786, 787, 788, 789, 791, 792, 793, 794, 800, 801, 819, 820, 821, 822, 843, 844]
  public static readonly SCOPE3_CATEGORY_9_IDS = [761, 763, 765, 769]
  public static readonly SCOPE3_CATEGORY_10_IDS = [770, 778, 781]
  public static readonly SCOPE3_CATEGORY_11_IDS = [771, 773, 777]
  public static readonly SCOPE3_CATEGORY_12_IDS = [775, 776, 779]
  public static readonly SCOPE3_CATEGORY_13_IDS = [772, 774, 782]
  public static readonly SCOPE3_CATEGORY_14_IDS: number[] = []
  public static readonly SCOPE3_CATEGORY_15_IDS: number[] = []

  public static readonly FIELD_ENUM_ID_PREFIX = 'fe_id-'

  public static readonly SCOPE3_ALL_CATEGORIES = [
    this.SCOPE3_CATEGORY_1,
    this.SCOPE3_CATEGORY_2,
    this.SCOPE3_CATEGORY_3,
    this.SCOPE3_CATEGORY_4,
    this.SCOPE3_CATEGORY_5,
    this.SCOPE3_CATEGORY_6,
    this.SCOPE3_CATEGORY_7,
    this.SCOPE3_CATEGORY_8,
    this.SCOPE3_CATEGORY_9,
    this.SCOPE3_CATEGORY_10,
    this.SCOPE3_CATEGORY_11,
    this.SCOPE3_CATEGORY_12,
    this.SCOPE3_CATEGORY_13,
    this.SCOPE3_CATEGORY_14,
    this.SCOPE3_CATEGORY_15
  ]

  public static readonly SCOPE3_ALL_UPSTREAM_CATEGORIES = [
    this.SCOPE3_CATEGORY_1,
    this.SCOPE3_CATEGORY_2,
    this.SCOPE3_CATEGORY_3,
    this.SCOPE3_CATEGORY_4,
    this.SCOPE3_CATEGORY_5,
    this.SCOPE3_CATEGORY_6,
    this.SCOPE3_CATEGORY_7,
    this.SCOPE3_CATEGORY_8,
  ]

  public static readonly SCOPE3_ALL_DOWNSTREAM_CATEGORIES = [
    this.SCOPE3_CATEGORY_9,
    this.SCOPE3_CATEGORY_10,
    this.SCOPE3_CATEGORY_11,
    this.SCOPE3_CATEGORY_12,
    this.SCOPE3_CATEGORY_13,
    this.SCOPE3_CATEGORY_14,
    this.SCOPE3_CATEGORY_15
  ]

  public static readonly missingContent = 'app.global.missing-content.text'

  public static readonly NO_CONTACT_PERSON_ADDED_TOAST_ID = 'NoContactPersonAddedToast'

  public static readonly FORECAST_FILTER_SCOPE1 = 0X01
  public static readonly FORECAST_FILTER_SCOPE2 = 0X02
  public static readonly FORECAST_FILTER_SCOPE3 = 0X04
  public static readonly FORECAST_FILTER_SCOPES = 0X08
  public static readonly FORECAST_FILTER_CAT_ENERGY = 0X010
  public static readonly FORECAST_FILTER_CAT_PROCUREMENT = 0X020
  public static readonly FORECAST_FILTER_CAT_TRANSPORT = 0X040
  public static readonly FORECAST_FILTER_CAT_TRASH = 0X080
  public static readonly FORECAST_FILTER_CAT_PRODUCTS = 0X0100
  public static readonly FORECAST_FILTER_CATEGORIES = 0X0200
  public static readonly FORECAST_FILTER_STATICSCOPE = 0X0400
  public static readonly FORECAST_FILTER_REDUCTION_TARGET = 0X0800

  public static readonly CALCULATOR_SLOT_BESKRIVELSE = 1
  public static readonly CALCULATOR_SLOT_FELT2 = 2
  public static readonly CALCULATOR_SLOT_FELT3 = 3
  public static readonly CALCULATOR_SLOT_FELT4 = 4
  public static readonly CALCULATOR_SLOT_FELT5 = 5
  public static readonly CALCULATOR_SLOT_SCOPE1 = 6
  public static readonly CALCULATOR_SLOT_SCOPE2 = 7
  public static readonly CALCULATOR_SLOT_SCOPE3 = 8
  public static readonly CALCULATOR_SLOT_SAMLET = 9 // Is not mapped in DB
  public static readonly CALCULATOR_SLOT_UDENFOR = 10
  public static readonly CALCULATOR_SLOT_BEMAERKNINGER = 11
  public static readonly CALCULATOR_SLOT_SCOPE3KATEGORI = 12

  public static readonly SCOPE1_COLOR_HEX = '#00441b'
  public static readonly SCOPE1_REDUCTION_COLOR_HEX = '#00441b'
  public static readonly SCOPE2_COLOR_HEX = '#238b45'
  public static readonly SCOPE2_REDUCTION_COLOR_HEX = '#238b45'
  public static readonly SCOPE3_COLOR_HEX = '#74c476'
  public static readonly SCOPE3_REDUCTION_COLOR_HEX = '#74c476'
  public static readonly OUTSIDE_SCOPES_COLOR_HEX = '#c7e9c0'
  public static readonly REDUCTION_COLOR_HEX = '#BC80BC'

  public static readonly RGBA_NEUTRAL = 'rgba(249,249,249, 0)' //Invisible
  public static readonly RGBA_IMPROVED = '#eeffe2'
  public static readonly RGBA_WORSENED = '#fde0e0'

  public static readonly  GROUPING1_COLOR_HEX = '#08306b'
  public static readonly  GROUPING2_COLOR_HEX = '#2171b5'
  public static readonly  GROUPING3_COLOR_HEX = '#6baed6'
  public static readonly  GROUPING4_COLOR_HEX = '#9ecae1'
  public static readonly  GROUPING5_COLOR_HEX = '#c6dbef'
  public static readonly  GROUPING6_COLOR_HEX = '#acceda'
  public static readonly  GROUPING7_COLOR_HEX = '#3a464a'
  public static readonly  GROUPING8_COLOR_HEX = '#5e8d9c'
  public static readonly  GROUPING9_COLOR_HEX = '#154c79'
  public static readonly  GROUPING10_COLOR_HEX = '#063970'
  public static readonly  GROUPING11_COLOR_HEX = '#abdbe3'
  public static readonly  GROUPING12_COLOR_HEX = '#873e23'
  public static readonly  GROUPING13_COLOR_HEX = '#76b5c5'
  public static readonly  GROUPING14_COLOR_HEX = '#eab676'
  public static readonly  GROUPING15_COLOR_HEX = '#e28743'
  public static readonly  COLOR_GRAY_HEX = '#d9d8d8'
  public static readonly  COLOR_PUPLE_HEX = '#BC80BC'
  public static readonly  COLOR_ROSE_HEX = '#fde0e0'

  public static readonly LEAST_ALLOWED_EC_VERSION: { [key: number]: number } = {
    2015: 5,
    2016: 5,
    2017: 5,
    2018: 5,
    2019: 9,
    2020: 7,
    2021: 2,
    2022: 3,
    2023: 3,
    2024: 3,
    2025: 3,
    2026: 3,
    2027: 3,
    2028: 3,
    2029: 3,
    2030: 3,
    2031: 3,
    2032: 3,
    2033: 3,
    2034: 3,
    2035: 3
  };

}
