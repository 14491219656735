<template>
  <CalculatorTabComponent
    >
    <template v-slot:calculator-primary-1="slotProps">
      <CommonTransportCalculator
        :subgroupingId="constants.GROUP_VARETRANSPORT_I_FYSISKE_ENHEDER_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
        :expanded="true"
        >
      </CommonTransportCalculator>
    </template>
    <template v-slot:calculator-primary-2="slotProps">
      <OwnEmcTransportCalculator
        :subgroupingId="constants.GROUP_VARETRANSPORT_I_MONETÆRE_ENHEDER_BELØB_I_DKK_II"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OwnEmcTransportCalculator>
    </template>
    <template v-slot:calculator-secondary-1="slotProps">
      <OwnEmcTransportCalculator
        :subgroupingId="constants.GROUP_VARETRANSPORT_FRA_VIRKSOMHED_TIL_KUNDE_MED_EGNE_EMISSIONSFAKTORER"
        :title="slotProps.title"
        :tooltip="slotProps.tooltip"
      >
      </OwnEmcTransportCalculator>
    </template>
  </CalculatorTabComponent>
</template>

<script lang="ts">
import { Options, Prop, Watch } from "vue-property-decorator"
import BaseComponent from "../base-components/BaseComponent"
import CalculatorTabComponent from "../CalculatorTabComponent.vue"
import CommonTransportCalculator from "@/components/calculator/transport/calculators/CommonTransportCalculator.vue"
import OwnEmcTransportCalculator from "@/components/calculator/transport/calculators/OwnEmcTransportCalculator.vue";

@Options({
  components: {OwnEmcTransportCalculator, CommonTransportCalculator, CalculatorTabComponent }
})
export default class CustomerTransportationView extends BaseComponent {

}
</script>

<style scoped>

</style>