<template>
  <div class="container px-0">
    <div
        v-if="formValidation.formErrors.size"
        aria-atomic="true"
        class="alert alert-error"
        ref="formErrorsAlert"
        role="alert"
    >
      <div class="alert-body">
        <p class="alert-heading">
          <SimpleText text-key="generic.input.error.title"/>
        </p>
        <ul class="alert-text">
          <li v-for="[key, value] of formValidation.formErrors" :key="key" :data-cy="'error_' + key">
            <a href="javascript:void(0)" @click.prevent="focusInput(key)" style="text-decoration: none">
              {{ value }}
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="py-3">
      <form @change="formValidation.clearValidField" @submit.prevent="submitForm"
            @invalid.capture.prevent="formValidation.handleFormValidity"
            ref="formGroup">
        <div class="py-4 row form-group">

          <div class="col-md-4 form-group">
            <label id="multiselect-comparison-choose-calculation-label" class="form-label "
                   for="multiselect-comparison-calculation-select">
              <SimpleText text-key="comparison.comparison-multiselect-dropdown.header"/>
            </label>
            <Tooltip :text="this.multiSelectTooltip" size="lg-h3"/>
            <div class="multiselect-wrapper" id="multiselect-comparison-calculation-wrapper">
              <Multiselect
                  id="multiselect-comparison-calculation-select"
                  v-model="this.selectedComparisons"
                  v-if="!editExistingComparison"
                  mode="tags"
                  :close-on-select="false"
                  :hideSelected="false"
                  :canClear="false"
                  :groups="true"
                  :group-hide-empty="true"
                  :options="[
                               {
                                label: this.selectRemoveLabel,
                                options:this.representativeCalculations
                               },
                            ]"
                  valueProp="categoryId"
                  label="calculationPresentationName"
                  ref="comparison-calculation-select"
                  class="multiselect-calculation form-group"
                  :aria="{'aria-labelledby':'multiselect-comparison-choose-calculation-label', 'aria-placeholder': null, 'aria-multiselectable': null}"
                  :placeholder="getContentfulString('comparison.multiselect.placeholder.text')"
                  @focusout="removeExtraCustomTags"
                  @focus="replaceWithCustomTag = false"
              >
                <template #tag v-if="replaceWithCustomTag">
                 <span class="custom-tag" v-show="replaceWithCustomTag">
                  {{ selectedComparisons.length }}  {{ customTagTitle }}
                 </span>
                </template>
              </Multiselect>
            </div>
          </div>
          <div class="col-md-4 form-group" style="margin-top: 0" id="comparison-name-div">
            <label class="form-label " for="input-comparison-name">
              <SimpleText text-key="comparison.create.input-field.title"/>
            </label>
            <div>
              <input
                  id="input-comparison-name"
                  v-model="comparisonName"
                  :data-error="comparisonNameError"
                  class="form-input"
                  name="input-comparison-name"
                  size="50"
                  maxlength="50"
                  minlength="2"
                  required
                  type="text"
              />
            </div>
          </div>
          <div class="col-md-4 form-group submit-wrapper">
            <label v-if="this.validateComparisonListLength" id="submit-button-label" class="form-label "
                   for="submit-chosen-calculations">
              <SimpleText text-key="comparison.create.button-hint"/>
            </label>
            <label v-else id="submit-button-label-alt" class="form-label hidden-label" for="submit-chosen-calculations">
              <SimpleText text-key="comparison.create.button-hint"/>
            </label>
            <button id="submit-chosen-calculations" class="button button-primary" type="submit"
                    :disabled="this.validateComparisonListLength">
              <SimpleText text-key="comparison.create-comparison.submit-button.title"/>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import {comparisonClient} from "@/shared/BackendService";
import {useRouter} from "vue-router"
import {Options} from "vue-class-component"
import {FormValidation} from "@/shared/FormValidation";
import {
  Calculation, CalculationInfo, CompareFilterObjectCompareTypeEnum, ComparisonObject, Versions,
} from "../../../openapi/generated-clients/climatecompass";
import {Prop, Watch} from "vue-property-decorator";
import DKFDS from "dkfds";
import BaseComponent from "@/components/calculator/base-components/BaseComponent";
import SimpleText from "@/components/SimpleText.vue";
import RichText from "@/components/RichText.vue";
import Tooltip from "@/components/Tooltip.vue";
import ContentfulContent from "@/shared/ContentfulContent";
import Multiselect from "@vueform/multiselect";
import ForecastColumnChart from "@/components/forecast/ForecastColumnChart.vue";
import {AxiosResponse} from "axios";


class CalculationsForSelect {
  categoryId: string
  calculationPresentationName: string

  constructor(categoryId: string, calculationPresentationName: string) {
    this.categoryId = categoryId
    this.calculationPresentationName = calculationPresentationName
  }
}

class CalculationsForSelectWithLabels {
  label: string
  options: CalculationsForSelect[]

  constructor(_label: string, _options: CalculationsForSelect[]) {
    this.label = _label
    this.options = _options
  }
}

@Options({
  emits: ["comparison-selected-calculations", "finished-loading-comparison"],
  components: {
    RichText,
    SimpleText,
    Tooltip,
    Multiselect,
    ForecastColumnChart
  }
})
export default class ComparisonCalculationMultiSelect extends BaseComponent {
  /** MODE: mode can be 'edit' or 'new' **/
  @Prop()
  mode?: string

  @Prop()
  calculationsToCompare?: Calculation[]

  @Prop()
  comparisonName2?: string

  @Watch('calculationsToCompare')
  dataUpdates() {
    this.setupRepresentativeDropdown()
  }


  /** GENERAL VARIABLES **/
  multiSelectTooltip = this.getContentfulString('comparison.multiselect.tooltip')
  router = useRouter()
  activeUserContactpersonRid = this.store.getters.getCurrentUser.profile['sub']
  comparisonId = 10
  editExistingComparison = false
  contentfulContent: ContentfulContent = this.store.getters.getContentfulContent
  contentMessage = ""
  versions = {} as Versions

  /** MULTISELECT VARIABLES **/
  selectRemoveLabel = this.getContentfulString('comparison.create.add-remove-label.title')
  customTagTitle = this.getContentfulString('comparison.create.custom-tag.title')
  replaceWithCustomTag = false
  selectedComparisons: CalculationInfo[] = []
  representativeCalculations = [] as CalculationsForSelect[]
  comparisonName = ''

  /** GRAPH RELATED VARIABLES **/
  graphDisplayCriteria: string | undefined

  /** FORM && ERROR MESSAGE VARIABLES **/
  formValidation = new FormValidation()
  comparisonNameError = this.getContentfulString('comparison.create.input-name.error.message')
  showSuccess = false

  /** REFERENCES **/
  $refs!: {
    categoriesMultiselect: Multiselect
    formGroup: HTMLFormElement
  }

  /** GETTERS **/
  get validateComparisonListLength() {
    return !(this.selectedComparisons.length === 2 || this.selectedComparisons.length === 3 || this.selectedComparisons.length === 4 || this.selectedComparisons.length === 5);

  }

  get comparisons() {
    return this.store.getters.getComparisons
  }

  /** LifeCycle Hooks **/
  beforeMount() {
    //console.log('comparisons = ', JSON.stringify(this.comparisons, null, 2))

  }

  mounted() {
    DKFDS.init()
    //console.log('Receiving the following prop data: ', JSON.stringify(this.calculationsToCompare, null, 2) )
  }

  setupRepresentativeDropdown() {
    const representativeDropdown: CalculationsForSelect[] = []

    if (this.calculationsToCompare) {
      this.calculationsToCompare.forEach((calc: Calculation) => {
        representativeDropdown.push(new CalculationsForSelect(String(calc.id), calc.calculationPurpose.name))
      });
      // console.log('representativeDropdownValue = ,', JSON.stringify(representativeDropdown, null, 2))
    }
    return this.representativeCalculations = representativeDropdown
  }

  updatedSelectedComparisonFilter(selectedComparisonCriteria: string) {
    this.graphDisplayCriteria = selectedComparisonCriteria
  }

  validComparisonName(name: string): boolean {
    if (!name) {
      return false
    }
    if (name.length < 2
        && name.length > 50) {
      return false
    }
    return true
  }

  removeExtraCustomTags() {
    const customTags = document.querySelectorAll('.custom-tag');
    for (let i = 1; i < customTags.length; i++) {
      customTags[i].remove(); // Remove all custom tags except the first one
    }
    this.replaceWithCustomTag = true
  }

  async submitForm(e: Event) {
    // console.log('formVal = ', JSON.stringify(this.formValidation.formErrors, null, 2))
    this.reformatSelectedCalculations()
    if (this.formValidation.formErrors.size === 0) {
      const element = e.target as HTMLFormElement
      if (element.reportValidity()) {
        document.querySelectorAll(".form-group").forEach(function (element) {
          element.classList.remove('form-error')
        })
      }
    }

    let createResult = {} as AxiosResponse<ComparisonObject>
    try {
      await comparisonClient.createComparison(
          {
            "id": 0,
            "vatNo": this.getContactPerson.vatNo,
            "thirdPartyVatNo": this.thirdpartyInfo.behalfOfVatNr,
            "name": this.comparisonName,
            "description": "",
            "compareFilters": [
              {
                "compareType": CompareFilterObjectCompareTypeEnum.SCOPE,
                "dropdownId": 1
              },
              {
                "compareType": CompareFilterObjectCompareTypeEnum.SCOPE,
                "dropdownId": 2
              },
              {
                "compareType": CompareFilterObjectCompareTypeEnum.SCOPE,
                "dropdownId": 3
              },
            ],
            "calculations": this.selectedComparisons
          }
      ).then((result: AxiosResponse<ComparisonObject>) => {
        createResult = result
      })
    } catch (err) {
      this.addToast('comparisonCreateErrorToast', 'error', 'Fejl', 'Der skete desværre en fejl ved oprettelse af sammenligningen', true, true, false)
      console.error(err)
    }
    this.addToast('comparisonCreateToast', 'success', 'Sammenligning gemt', `Sammenligning gemt med navnet: ${this.comparisonName}.`, true, true, false)
    this.eventHub.emit('comparison-selected-calculations', this.selectedComparisons)
    this.$emit('finished-loading-comparison', createResult)
    this.resetForm()
  }

  get thirdpartyInfo() {
    return this.store.getters.getThirdpartyInfo
  }

  get getContactPerson() {
    return this.store.getters.getContactPerson
  }

  resetForm() {
    if (this.$refs.formGroup) {
      this.$refs.formGroup.reset();
      this.selectedComparisons = []
      this.comparisonName = ''
    }
  }

  reformatSelectedCalculations() {
    const calcComparisonsInfo: CalculationInfo[] = this.selectedComparisons.map(calcId => {
      // Find the matching calculation from representativeCalculations (to get CalculationId)
      const matchingCalc = this.representativeCalculations.find(
          calc => calc.categoryId === calcId
      );

      // Extract the calculationId if a match is found
      const calculationName = matchingCalc ? matchingCalc.calculationPresentationName : undefined;

      // Return the new CalculationInfo object
      return {
        calculationId: calcId,
        calculationName: String(calculationName),
        isKladde: false,
        contactpersonRid: this.activeUserContactpersonRid
      } as CalculationInfo;
    });

    // Perform any additional operations if necessary
    // calcComparisonsInfo.forEach();
    console.log("selected comp", JSON.stringify(calcComparisonsInfo, null, 2))
    return this.selectedComparisons = calcComparisonsInfo;
  }

}
</script>


<style src="@vueform/multiselect/themes/default.css">
</style>

<style lang="scss">
.multiselect-calculation {
  --ms-font-size: 1.6rem;
  --ms-border-color: #747474;
  --ms-border-width: 1px;
  --ms-option-font-size: 1.6rem;
  --ms-option-line-height: 1.8rem;
  --ms-option-py: 7px;
  --ms-option-px: 34px;
  --ms-tag-font-size: 1.6rem;
  --ms-line-height: 2.4rem;
  --ms-tag-line-height: 2.4rem;
  --ms-px: 4px;
  --ms-py: 15px;
  --ms-tag-bg: black;
  --ms-tag-color: white;
  padding: 5px var(--ms-px) 5px var(--ms-px);

  --ms-max-height: 30rem;

  --ms-option-bg-selected-pointed: #F2F2F2;
  --ms-option-color-selected-pointed: black;
  --ms-option-bg-pointed: #F2F2F2;

  --ms-group-label-py: 7px;
  --ms-group-label-line-height: 1.8rem;
  --ms-group-label-bg: #D7D7D7;
  --ms-group-label-bg-pointed: #C7C7C7;
  --ms-group-label-bg-selected: #D7D7D7;
  --ms-group-label-color-selected: black;
  --ms-group-label-bg-selected-pointed: #C7C7C7;
  --ms-group-label-bg-selected-disabled: #D7D7D7;
}

#multiselect-comparison-choose-calculation-label {
  margin-bottom: 8px;
}

.forecast-column-chart-placeholder {
  display: flex;
  justify-content: center;
  height: 350px;
}

.multiselect-calculation.form-group .multiselect-placeholder {
  color: black !important;
}

#submit-chosen-calculations {
  margin-top: 8px;
}

.col-md-4 .form-group * {
  margin-top: 0;
}

.custom-tag {
  padding: 0.5rem;
  background-color: var(--ms-tag-bg);
  color: var(--ms-tag-color);
  //padding: var(--ms-py) var(--ms-px);
  border-radius: 3px;
  display: inline-block;
  margin-right: 5px;
}

.custom-tag:not(:first-child) {
  //Hacky trick to hide additional labels, as they are supposed to be represented by one single label (AK).
  visibility: hidden;
  display: none;
}

#submit-button-label {
  color: #5b7c69;
  font-style: italic;
}

.submit-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0 !important;
}

.hidden-label {
  visibility: hidden;
}
</style>
<style scoped>
.form-error * {
  border-left: 4px solid #CC0000 !important;
  padding-left: calc(16px - 4px);
}

.form-select .form-group .form-error * {
  border-left: 4px solid #CC0000 !important;
  padding-left: calc(16px - 4px);
}

</style>
